<script lang="ts">
    import { scrlTo, isMobile } from "../helper";
    var ready = false;
    var links;
    setTimeout(()=>{
        links = Array.from(document.querySelectorAll(".jumpable"))
        ready = true;
        setTimeout(checkForColChange)
    })

    var opened = false;
    window.addEventListener("scroll", checkForColChange)
    window.addEventListener("resize", (ev)=>{
        opened = false;
        setTimeout(checkForColChange);
    })
    window.addEventListener("mousemove", (ev)=>{
        if(!isMobile()){
            var br = document.getElementById("lefty").getBoundingClientRect()
            if (!opened) {
                if(ev.clientX < br.x + br.width){
                    opened = true;
                    setTimeout(checkForColChange);
                }else{return}
            }else{
                if(ev.clientX < br.x + br.width){return}
                else{
                    opened = false;
                    setTimeout(checkForColChange);
                }
            }
        }
    })

    function checkForColChange(){
        if(opened && isMobile()){
            for(var el of Array.from(document.getElementsByClassName("l_check")) as any){
                try {
                    el.style.borderColor = "white";
                    if(document.querySelector(".btn.l_check")==el){
                        el.style.backgroundColor = "white";
                    }
                    el.style.color = "white";
                } catch (error) {}
            }
            return
        }

        for(var el of Array.from(document.getElementsByClassName("l_check")) as any){
            try {
                var el_bb = el.getBoundingClientRect();
                var col = undefined;
                for(var bg of (Array.from(document.querySelectorAll(".bg, .content_wrap")) as any).filter(x=>x.style.background.includes('--blu')) ){
                    var bg_bb = bg.getBoundingClientRect();
                    if(bg_bb.top < el_bb.top + el_bb.height/2 && bg_bb.top + bg_bb.height > el_bb.top + el_bb.height/2){
                        col = "white"
                    }
                }

                if(col == undefined){
                    col = "var(--blu)";
                }

                el.style.color = col;
                if(document.querySelector(".btn.l_check")==el){
                    el.style.backgroundColor = col;
                }
                el.style.borderColor = col;
            } catch (error) {}
        }
    }

    checkForColChange();

    window.addEventListener("neek", ()=>{
        notneek = notneek;

        setTimeout(()=>{
            links = Array.from(document.querySelectorAll(".jumpable"))
            ready = true;
            setTimeout(checkForColChange)
        })
    })
    $: notneek = (window as any).neek;   
</script>

<div id="lefty" class="bar left" style="{opened?"z-index:100 !important;":""}">

    <div class="btn l_check" on:mousedown={(ev)=>{
            opened = !opened
            setTimeout((ev)=>{
                if(isMobile() && opened){
                    console.log(ev);
                    ev.target["style"].backgroundColor = "#00000000";
                    ev.target["style"].border = "none";
                }
            }, 2, ev)
            setTimeout(checkForColChange);
        }} 
        on:touchstart={(ev)=>{
            // opened = !opened
            // setTimeout((ev)=>{
            //     if(isMobile() && opened){
            //         console.log(ev);
            //         ev.target["style"].backgroundColor = "#00000000";
            //         ev.target["style"].border = "none";
            //     }
            // }, 2, ev)
            // setTimeout(checkForColChange);
        }}

        style="
            transform: translate({
                isMobile()?
                (opened?"0":"0"):
                (opened?"calc(-1*var(--bar-w) - 1rem)":"0")
            },
            0px);
        "></div>

    {#if ready}
        <!-- <div class="btn exit l_check" on:mousedown={()=>{opened = !opened}} 
            style="
                transform: translateX({!opened?"calc(-1*var(--bar-w) - 1rem)":"0"});
            "></div> -->
        {#each links as el}
            <p class="l_check"
                on:mousedown={()=>{
                    scrlTo(el.id);
                    opened = !opened;
                }}
                on:touchstart={()=>{
                    // scrlTo(el.id);
                    // opened = !opened;
                }}
                style="
                    transform: translateX({
                        isMobile()?
                        (!opened?"calc(-1*var(--bar-w) - 1rem)": "calc(50vw - 50% - 0.5rem)"):
                        (!opened?"calc(-1*var(--bar-w) - 1rem)":"0")
                    });
                ">{el.innerText}</p>
        {/each}
        <div id="lbg" style="width: 100vw; height: 200vh; left: -1rem; pointer-events: {opened?"all":"none"}; position: absolute; transition: background-color .5s ease-out; z-index: -1; background-color:{opened?"var(--blu)":"#00000000"}; touch-action: pan-y;">
        </div>
    {/if}
</div>

<style lang="scss">
    p{
        color: var(--blu);
        margin: 0;

        font-size: min(calc(28 * 0.125 * 1vmin), 1rem);
        text-align: left;
        transition: transform .5s ease-in-out;

        &:hover{
            font-weight: bold;
        }
    }

    .btn{
        width: 2rem;
        height: 2rem;
        display: flex;
        border-width: 1px;
        border-radius: 20rem;       
        border-style: solid;
        position:absolute;

        transition: transform .5s ease-in-out;

        &.exit{
            top: 1rem;
            right: 1rem;
            
            &::before{
                content: "";
                border-right-width: 1px;
                border-right-style: solid;
                border-color: inherit;

                transform: rotate(45deg) translate(1rem, -.5rem);
                height: 1.35rem;
            }

            &::after{
                content: "";
                border-right-width: 1px;
                border-right-style: solid;
                border-color: inherit;

                transform: rotate(-45deg) translate(.45rem,.95rem);
                height: 1.35rem;
            }
        }
    }

    .btn.l_check{
        // transition: transform .5s ease-in-out, background-color .5s ease-in-out;
    }
    .btn.l_check::before, .btn.l_check::after {
		/*Make two lines*/
		position: absolute;
		z-index: 1;
		content: " ";
		height: 1.7rem;
		width: 0px;
		background-color: inherit;
        border-color: inherit;
        border-width: 2px;
        border-style: solid;
	}
	
	/*Rotate each one in a differenet direction*/
	.btn.l_check::before{
		transform: translate(.9rem, 0rem) rotate(45deg);
	}
	
	.btn.l_check::after{
		transform: translate(.9rem, 0rem) rotate(-45deg);
	}

    @media only screen and (min-width: 800px) {
        #lbg{
            background-color: #00000000 !important;
            touch-action: none;
        }
    }

    @media only screen and (max-width: 800px) {
        p{
            text-align: center;
        }
        .btn.l_check{
            margin-bottom: 92vh;
        }
    }
</style>