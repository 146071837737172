<script lang="ts">
    import PerlinLine from "../elements/PerlinLine.svelte";
    import LeftMenu from "../elements/LeftMenu.svelte";
    import RightMenu from "../elements/RightMenu.svelte";

    import Editorial from "./Editorial.svelte";
    import Index from "./Index.svelte";

    import { pages } from "../pages";
    import Page from "./Page.svelte";
import { isMobile } from "../helper";
    
    //var is_first = true
    //$: src = is_first?"./resources/videos/video_p1.mp4":"./resources/videos/video_p2.mp4"
    //$: poster = is_first?"./resources/images/hand_vid_poster1.png":"./resources/images/hand_vid_poster2.png"

    window.addEventListener("neek", ()=>{
        notneek = notneek;
    })
    $: notneek = (window as any).neek;   

    const f = ()=>{
        document.querySelector(".wrapper")["style"].top = Math.max(innerHeight, window.scrollY) + "px";
        console.log(Math.max(innerHeight, window.scrollY))
        window.requestAnimationFrame(f);
    }
    //setTimeout(f)

</script>

<svelte:head>
    <title>SPREAD</title>
</svelte:head>

<main>
    <div class="bg" style="margin-bottom: -4px; min-height: 100vh; background: var(--blu);">
        <!-- svelte-ignore a11y-media-has-caption -->
        <!--             on:ended={()=>{is_first = !is_first}} -->
        <!-- <video
            loading="lazy"
            autoplay
            muted
            loop

            src="./resources/videos/video_h.mp4"
            poster="./resources/images/hand_vid_poster1.png">
            <track kind="Homepage video">
        </video> -->


        <div class="vimeo-wrapper">
            <iframe src="https://player.vimeo.com/video/720981556?background=1&autoplay=1&loop=1&byline=0&title=0" title="" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
         </div>

        <div style="position: absolute;">
            <PerlinLine text={"SPREAD"} y={
                isMobile()?-0.25:-.07}></PerlinLine>
        </div>
    
    </div>
    
    <div class="wrapper">
        <LeftMenu></LeftMenu>
        <div class="content_wrap" style="width: calc(100vw - 2 * var(--bar-w)); padding: 1rem;">
        </div>
        <RightMenu></RightMenu>
    </div>
    
    <div class="content">
        <Editorial></Editorial>
        <Index></Index>
        {#each pages[notneek.lang] as opt}
            <Page {opt}></Page>
        {/each}
    </div>
</main>

<style lang="scss">
    main{
        position: relative;
    }

    video{
        width: 100vw;
        height: 100vh;
        object-fit: cover;
        position: absolute;
        z-index: 0;
    }

    .vimeo-wrapper {
        position: absolute;
        width: 100vw;
        height: 100vh;
        z-index: 0;
        margin-top: -1px;
        pointer-events: none;
        overflow: hidden;
        &::after{
            // content: "Video: Excerpt from “Ultra Censored” short film by Kat Válastur";
            z-index: 100;
            position: absolute;
            top: calc(100% - 8px - 1rem * var(--sc));
            margin: auto;
            width: 100%;
            text-align: center;
            font-family: "Open Sans" !important;
            font-size: calc(1rem * var(--sc));
            color: var(--blu);
            font-weight: bold;
            font-size: 0.75rem;
        }
    }
    .vimeo-wrapper iframe {
        width: 100vw;
        height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
        min-height: 100vh;
        min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @media only screen and (max-width: 800px) {
        .vimeo-wrapper{
            height: 60vh;
        }

        .vimeo-wrapper iframe{
            min-height: 60vh;
        }
    }

    .wrapper{
        width: 100vw;
        height: 100vh;

        z-index:1000;

        // top:0;
        top: 0;
        bottom: 0;

        pointer-events: none;

        position: fixed;
        display: flex;
        flex-direction: row;

        transition: top .05s ease;

        & * {
            display: flex;
        }
    }
</style>