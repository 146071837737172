<script lang="ts">
    import { scrlTo } from "../helper";
    import { pages } from "../pages";

    window.addEventListener("neek", ()=>{
        notneek = notneek;
    })
    $: notneek = (window as any).neek;   
</script>

<main class="content_wrap" name="ALLOW_LANG_CHANGE_INDEX">
    <div class="perlin_grid">
        <div class="perlin_cont_bg perlin_grid_el">
            <!-- <PerlinLine text=" • " y={.5} x={0}></PerlinLine> -->
        </div>

        <div class="perlin_grid_el">
            <h1 id="index" class="jumpable">
                INDEX
            </h1>
            
            {#each pages[notneek.lang] as opt}
                <h2 on:touchstart={()=>{
                    //scrlTo(opt.id)
                }}
                on:mousedown={()=>{scrlTo(opt.id)}}>
                    {opt.title}
                </h2>
    
                {#if opt.author != undefined}
                    <!-- content here -->
                    <p>{opt.author}</p>
                {/if}
            {/each}
        </div>
    </div>
</main>

<style lang="scss">
    main{
        color: rgb(70,0,255);
        background-color: white;
    }

    h1{
        font-weight: 100;
        font-family: CanelaReg;
        font-size: calc(6rem * var(--sc));
        padding: calc(10rem * var(--sc)) 0 calc(3rem * var(--sc)) 0;
        margin: 0;
    }

    h2{
        font-family: 'Open Sans';
        font-size: calc(3.5rem * var(--sc));
        padding: calc(3.5rem * var(--sc)) 0 0 0;
        margin: 0;
        line-height: 120%;

        text-transform: uppercase;

        -webkit-text-stroke: 1px var(--blu);
        color: white;

        &:hover{
            color: var(--blu)
        }
    }

    p{
        font-family: CanelaReg;
        font-size: calc(1.5rem * var(--sc));
        padding: calc(1.5rem * var(--sc));
        margin: 0;
        line-height: 130%;
        font-weight: bold;
    }
</style>