<script lang="ts">
    import type { Page } from "../global";
    import PerlinLine from "../elements/PerlinLine.svelte";

    export let opt:Page;

    function HTMLifiy(text){
        var out = text;

        out = out.replaceAll("\n", "<br>");
        out = out.replaceAll("   ", "&nbsp;&nbsp;&nbsp;");
        out = out.replaceAll("  ", "&nbsp;&nbsp;");
        out = out.replaceAll("<#t>", "<div class='parsed tab'>");
        out = out.replaceAll("</#t>", "</div>");
        out = out.replaceAll("<#ts>", "<div class='parsed tab_s'>");
        out = out.replaceAll("</#ts>", "</div>");
        out = out.replaceAll("<#c>", "<div class='parsed center'>");
        out = out.replaceAll("</#c>", "</div>");
        out = out.replaceAll("<#r>", "<div class='parsed right'>");
        out = out.replaceAll("</#r>", "</div>");
        out = out.replaceAll("<#image>", "<img class='parsed' src='./resources/images/");
        out = out.replaceAll("</#image>", "'>");
        out = out.replaceAll("<#vim_video>", `<iframe src="https://player.vimeo.com/video/`);
        out = out.replaceAll("</#vim_video>", `" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen style='width: 100%; height: calc(9/16 *(100vw - 2*var(--pad)))'></iframe>`);
        out = out.replaceAll("<#audio>", "<audio controls><source src='./resources/audio/");
        out = out.replaceAll("</#audio>", "'></audio>");
        out = out.replaceAll("<#style>", "<style>");
        out = out.replaceAll("</#style>", "</style>");
        out = out.replaceAll("<#^>", "<sup style='line-height: 0;'><small>");
        out = out.replaceAll("</#^>", "</small></sup>");

        out = out.replaceAll("<#note>", `<div style="text-align: left; font-weight: inherit; display: inline-flex; width: 100%; margin: 0; line-height: 130%;">`);
        out = out.replaceAll("</#note>", "</div>");
        out = out.replaceAll("<#nn>", `<h5 style="margin: 0; margin-right: 1rem; font: inherit;">`);
        out = out.replaceAll("</#nn>", "</h5>");
        out = out.replaceAll("<#nt>", `<h5 style="margin: 0; font: inherit;">`);
        out = out.replaceAll("</#nt>", "</h5>");

        out = out.replaceAll("<#block>", `<div style="text-align: left; font-weight: inherit; display: inline-flex; width: 100%; margin: 0; line-height: 130%;">`);
        out = out.replaceAll("</#block>", "</div>");
        out = out.replaceAll("<#bn>", `<p style="margin: 0; margin-right: 1rem; font: inherit; min-width: 1rem;">`);
        out = out.replaceAll("</#bn>", "</p>");
        out = out.replaceAll("<#bt>", `<p style="margin: 0; font: inherit;">`);
        out = out.replaceAll("</#bt>", "</p>");

        while(out.includes("<#link>")){
            var hrf = out.split("<#link>")[1].split("</#link>")[0]
            out = out.replace("<#link>", "")
            out = out.replace("</#link>", "")
            out = out.replace(hrf, `<a href='${hrf}'>${hrf}</a>`)
            //console.log(out)
        }
        return out;
    }
</script>

<main class="content_wrap" name={opt.id} style={
    (()=>{
        if(opt.bg.type == "gradient" && opt.bg.start_col != undefined && opt.bg.end_col != undefined){
            return `z-index: 0; background: linear-gradient(180deg, ${opt.bg.start_col}, ${opt.bg.col} 100vh, ${opt.bg.col} calc(100% - 200vh), ${opt.bg.end_col});`
        }else{
            return `z-index: 0; background: ${opt.bg.col}`
        }
    })()
}>
    <div class="perlin_grid bg">
        {#if opt.bg.elements != undefined}
            {#each opt.bg.elements as el}
                {#if el.type == "perlin"}
                    <div class="perlin_cont_bg perlin_grid_el abs">
                        <PerlinLine {...el}></PerlinLine>
                    </div>
                {/if}
            {/each}
        {/if}

        <!-- <div class="perlin_grid_el" style={
            (()=>{
                if(opt.bg.type == "gradient" && opt.bg.col2 != undefined){
                    return `background: linear-gradient(180deg, ${opt.bg.col1} 0%, ${opt.bg.col2} 100%);`
                }else{
                    return `background: ${opt.bg.col1}`
                }
            })()
        }></div> -->
    </div>

    <div class="page_cont">
        <h1 id={opt.id} class="jumpable" style={"color: " + opt.bg?.heading_col}>
            {opt.title}
        </h1>
        
        {#if opt.author != undefined}
            <h2 style={"color: " + opt.bg?.heading_col}>
                {opt.author}
            </h2>
        {/if}

        {#if opt.content != undefined}
            {#each opt.content as paragraph}
                <p style={"color: " + opt.bg?.text_col}>
                    {@html HTMLifiy(paragraph)}
                </p>
            {/each}
        {/if}

        {#each opt.subpages as sub}
            {#if sub.title != undefined}
                {#if sub.title != ""}
                    <h2 class="title" style={"-webkit-text-stroke: 1px " + opt.bg?.heading_col}>
                        {@html HTMLifiy(sub.title)}
                    </h2>
                {/if}
                
                {#each sub.content as paragraph}
                    <p style={"color: " + opt.bg?.text_col}>
                        {@html HTMLifiy(paragraph)}
                    </p>
                {/each}
            {:else}
                {#if sub.element.type == "perlin"}
                    <div class="perlin_cont_bg perlin_grid_el">
                        <PerlinLine {...sub.element}></PerlinLine>
                    </div>
                {/if}
            {/if}
        {/each}

        <h4 style="display: none;">_</h4>
    </div>
</main>

<style lang="scss">
    main{
        color: rgb(70,0,255);

        //background-color: white;
    }

    .bg{
        position: sticky;
        top:0;
        height: 100vh;
        z-index: -1;
        width: calc(100% + 2*var(--pad));

        transform: translateX(calc(-1*var(--pad)));
    }

    .page_cont{
        position: relative;
        top: -100vh;
        margin-bottom: -100vh;
    }

    h1{
        font-family: CanelaReg;
        font-weight: 100;
        font-size: calc(5.5rem * var(--sc));
        padding: calc(7rem * var(--sc)) 0 calc(2.5rem * var(--sc)) 0;
        margin: 0;
    }

    h2{
        font-family: 'Open Sans';
        font-size: calc(3.5rem * var(--sc));
        padding: calc(3.5rem * var(--sc)) 0 calc(3.5rem * var(--sc)) 0;
        margin: 0;
        line-height: 120%;

        -webkit-text-stroke: 1px var(--blu);
        // text-shadow:
        //     -1px -1px 0 #000,     
        //     1px -1px 0 #000,
        //     -1px 1px 0 #000,
        //     1px 1px 0 #000;
        color: #00000000;

        &.title{
            -webkit-text-stroke: 1px black;
        }
    }

    p{
        font-family: CanelaReg;
        font-size: calc(1.5rem * var(--sc));
        padding: calc(1rem * var(--sc));
        color: black;
        margin: 0;
        line-height: 165%;
        text-align: left;
    }

    audio::-webkit-media-controls-enclosure{
        background-color: "yellow";
    }
    // audio::-webkit-media-controls-panel{

    // }
</style>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
