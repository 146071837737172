<script lang="ts">
    import { social_links, isMobile } from "../helper";
    import { pages } from "../pages";

    var ready = false;
    var links;
    setTimeout(()=>{
        links = Array.from(document.querySelectorAll(".jumpable"))
        ready = true;
        setTimeout(checkForColChange)
    })

    var opened = false;
    window.addEventListener("scroll", checkForColChange)
    window.addEventListener("resize", (ev)=>{
        opened = false;
        setTimeout(checkForColChange);
    })
    window.addEventListener("mousemove", (ev)=>{
        if(!isMobile()){
            var br = document.getElementById("righty").getBoundingClientRect()
            if (!opened) {
                if(!(ev.clientX < br.x)){
                    opened = true;
                    setTimeout(checkForColChange);
                }else{return}
            }else{
                if(!(ev.clientX < br.x)){return}
                else{
                    opened = false;
                    setTimeout(checkForColChange);
                }
            }
        }else{
        }
    })

    var langOpac = 1;

    const checkLang = ()=>{
        var el_bb = document.querySelector(".r_check.lang").getBoundingClientRect();

        langOpac = 0;
        for(var bg of (Array.from(document.querySelectorAll(".content_wrap")).slice(1) as any)){
            var bg_bb = bg.getBoundingClientRect();
            if(bg_bb.top < el_bb.top + el_bb.height/2 && bg_bb.top + bg_bb.height > el_bb.top + el_bb.height/2){
                var n = bg.getAttribute('name');
                var other_lang = notneek.lang=="en"?"de":"en";
                if(n.includes("ALLOW_LANG_CHANGE") || (pages[other_lang].map(x=>x.id).includes(n) && pages[other_lang].find(x=>x.id == n).lang != notneek.lang)){
                    langOpac = (langOpac + 1)/2
                }else{
                    langOpac = langOpac / 2;
                }
                return
            }
        }
    }
    function checkForColChange(){
        if(opened && isMobile()){
            for(var el of Array.from(document.querySelectorAll(".r_check, #righty")) as any){    
                try {
                    el.style.borderColor = "white";
                    if(document.querySelector(".btn.r_check")==el){
                        el.style.backgroundColor = "white";
                    }
                    el.style.color = "white";
                } catch (error) {}
            }
            return
        }
        
        checkLang();
        for(var el of Array.from(document.querySelectorAll(".r_check, #righty")) as any){
            try {
                var el_bb = el.getBoundingClientRect();
                var col = undefined;
                for(var bg of (Array.from(document.querySelectorAll(".bg, .content_wrap")) as any).filter(x=>x.style.background.includes('--blu')) ){
                    var bg_bb = bg.getBoundingClientRect();
                    if(bg_bb.top < el_bb.top + el_bb.height/2 && bg_bb.top + bg_bb.height > el_bb.top + el_bb.height/2){
                        col = "white"
                    }
                }

                if(col == undefined){
                    col = "var(--blu)";
                }

                el.style.color = col;
                if(document.querySelector(".btn.r_check")==el){
                    el.style.backgroundColor = col;
                }
                el.style.borderColor = col;
            } catch (error) {}
        }
    }

    setTimeout(checkForColChange);

    window.addEventListener("neek", ()=>{
        notneek = notneek;
    })
    $: notneek = (window as any).neek;    
</script>
    
    <div id="righty" class="bar right" style="{opened?"z-index:100 !important;":""}">
        <div style="opacity: {2*langOpac};{
            isMobile()?
            `right: .4rem;
            transform: translate(.8rem,calc(-50vh + 6rem))
            `:
            ``
        }" class="r_check lang" on:mousedown={()=>{
            var el_bb = document.querySelector(".r_check.lang").getBoundingClientRect();

            langOpac = 0;
            for(var bg of (Array.from(document.querySelectorAll(".content_wrap")).slice(1))){
                var bg_bb = bg.getBoundingClientRect();
                if(bg_bb.top < el_bb.top + el_bb.height/2 && bg_bb.top + bg_bb.height > el_bb.top + el_bb.height/2){
                    const n = bg.getAttribute('name');
                    setTimeout(()=>{
                        try {
                            document.getElementsByName(n)[0].scrollIntoView({
                            behavior: 'smooth'
                        });
                        } catch (error) {}
                    })
                }
            }

            notneek.toggleLang(); 
            setTimeout(checkLang)
        }}
        on:touchstart={()=>{
            // var el_bb = document.querySelector(".r_check.lang").getBoundingClientRect();

            // langOpac = 0;
            // for(var bg of (Array.from(document.querySelectorAll(".content_wrap")).slice(1))){
            //     var bg_bb = bg.getBoundingClientRect();
            //     if(bg_bb.top < el_bb.top + el_bb.height/2 && bg_bb.top + bg_bb.height > el_bb.top + el_bb.height/2){
            //         const n = bg.getAttribute('name');
            //         setTimeout(()=>{
            //             try {
            //                 document.getElementsByName(n)[0].scrollIntoView({
            //                 behavior: 'smooth'
            //             });
            //             } catch (error) {}
            //         })
            //     }
            // }

            // notneek.toggleLang(); 
            // setTimeout(checkLang)
        }}>{
            notneek.lang=="en"?"de":"en"}</div>

        <div class="btn r_check" on:mousedown={(ev)=>{
            opened = !opened
            setTimeout((ev)=>{
                if(isMobile() && opened){
                    console.log(ev);
                    ev.target["style"].backgroundColor = "#00000000";
                    ev.target["style"].border = "none";
                }
            }, 2, ev)
            setTimeout(checkForColChange);
            }} 
            on:touchstart={(ev)=>{
                // opened = !opened
                // setTimeout((ev)=>{
                //     if(isMobile() && opened){
                //         console.log(ev);
                //         ev.target["style"].backgroundColor = "#00000000";
                //         ev.target["style"].border = "none";
                //     }
                // }, 2, ev)
                // setTimeout(checkForColChange);
                }}
             style="
                transform: translate({
                        isMobile()?
                        (opened?"0":"0"):
                        (opened?"calc(var(--bar-w) + 1rem)":"0")
                    },
                    0px);
             "></div>
    
        {#if ready}
            <!-- <div class="btn exit r_check" on:mousedown={()=>{opened = !opened}} 
                style="
                    transform: translateX({!opened?"calc(var(--bar-w) + 1rem)":"0"});
                "></div> -->

            <p></p>
            {#each Object.keys(social_links) as soc}
                <a href={social_links[soc]} class="r_check fa fa-{soc}"
                    style="
                        transform: translateX({
                            isMobile()?
                            (!opened?"calc(var(--bar-w) + 1rem)": "calc(-50vw + 100% + 1rem)"):
                            (!opened?"calc(var(--bar-w) + 1rem)":"0")
                        });
                    "> </a>
            {/each}
            <p></p>    
        {/if}
        <div id="rbg" style="width: 100vw; height: 200vh; right: -1rem; pointer-events: {opened?"all":"none"}; position: absolute; transition: background-color .5s ease-out; z-index: -100; background-color:{opened?"var(--blu)":"#00000000"}; touch-action: pan-y;">
        </div>
    </div>

    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    
    <style lang="scss">
        p{
            color: var(--blu);
            margin: 0;
    
            transition: transform .5s ease-in-out;
        }

        a{
            color: var(--blu);
            margin: 0;

            border-width: 0px;
            border-style: none;
            border-radius: 2rem;
            text-align: center;
            text-decoration: none;
            width: 24px;
            height: 24px;
            margin-right: 1rem;
            padding: 0.75rem;
            font-size: 1.5rem;

            &.fa.fa-soundcloud{
                padding: 0.75rem .95rem 0.75rem .55rem !important;
                &:hover::after{
                    content: "";
                    width: 175%;
                    height: 175%;
                    position: relative;
                    border: white 1px solid;
                    border-color: inherit !important;
                    display: block;
                    transform: translate(-15%, -77%);
                    border-radius: 50%;
                }
            }

            &.fa.fa-envelope{
                font-size: 1.4rem !important;
            }

            transition: transform .5s ease-in-out;  

            &:hover::after{
                content: "";
                width: 175%;
                height: 175%;
                position: relative;
                border: white 1px solid;
                border-color: inherit !important;
                display: block;
                transform: translate(-22%, -77%);
                border-radius: 50%;
                // padding: 0.75 calc(0.75rem - 2px);
                // border-style: solid;
            }
        }
        a:focus-visible{
            outline: none;
            box-shadow: none;
        }
    
        .btn{
            width: 2rem;
            height: 2rem;
            display: flex;
            border-width: 1px;
            border-radius: 20rem;       
            border-style: solid;
            position:absolute;
    
            transition: transform .5s ease-in-out;
    
            &.exit{
                top: 1rem;
                right: 1rem;
                
                &::before{
                    content: "";
                    border-right-width: 1px;
                    border-right-style: solid;
                    border-color: inherit;

                    transform: rotate(45deg) translate(1rem, -.5rem);
                    height: 1.35rem;
                }

                &::after{
                    content: "";
                    border-right-width: 1px;
                    border-right-style: solid;
                    border-color: inherit;

                    transform: rotate(-45deg) translate(.45rem,.95rem);
                    height: 1.35rem;
                }

                &:hover{
                    border-style: solid;
                }
            }
        }

        .lang{
            position: absolute;
            right: 5rem;
            text-transform: uppercase;
            transform: translateY(-.05rem);
            font-family: Open Sans;
            font-weight: 600;

            text-align: center;
            font-size: 1.25rem;

            width: 50px;
            height: 27px;
            padding: 0.75rem 0;
            &:hover::after{
                content: "";
                width: 95%;
                height: 175%;
                position: relative;
                border: white 1px solid;
                border-color: inherit !important;
                display: block;
                transform: translate(1%, -77%);
                border-radius: 50%;
            }
        }

        .btn.r_check{
            // transition: transform .5s ease-in-out, background-color .5s ease-in-out;
        }
        .btn.r_check::before, .btn.r_check::after {
			/*Make two lines*/
			position: absolute;
			z-index: 1;
			content: " ";
			height: 1.7rem;
			width: 0px;
			background-color: inherit;
            border-color: inherit;
            border-width: 2px;
            border-style: solid;
		}
		
		/*Rotate each one in a differenet direction*/
		.btn.r_check::before{
			transform: translate(.9rem, 0rem) rotate(45deg);
		}
		
		.btn.r_check::after{
			transform: translate(.9rem, 0rem) rotate(-45deg);
		}

        @media only screen and (min-width: 800px) {
            #rbg{
                background-color: #00000000 !important;
                touch-action: none;
            }
        }

        @media only screen and (max-width: 800px) {
            .btn.r_check{
                margin-bottom: 92vh;
            }
        }
    </style>