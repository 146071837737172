import { social_links } from "./helper";
const planetary_feminism = {
    title: "Attempt for a Planetary Feminism: Metabolism of the Social in Ecological Ruins",
    lang: "en",
    id: "planetary_feminism",
    author: "Margarita Tsomou",
    bg: {
        start_col: "white",
        col: "#cda56e80",
        end_col: "white",
        type: "gradient",
        elements: [
            {
                type: "perlin",
                text: "                                                          •                                                          ",
                s: 1,
                h: 2,
                x: 0.45,
                y: -0.45,
                x_skew: -1
            }
        ]
    },
    content: [
        `The last time feminist futures convinced me was when I sat in front of my computer for two days to attend the MarxFem International Online Conference. Representatives of Feminismo Communitario and indigenous positions from Latin America and Africa, feminist municipalism from Spain, and the crème de la crème of transnational feminism spoke about practices that provide concrete responses to the multiple crises of colonial-patriarchal-extractivist capitalism on a daily basis. Examples included spaces of political self-constitution, social eco-commoning, popular sovereignty, planetary cooperation for energy and resource sufficiency, solidarity-based urban development, and a democratic approach to organizing infrastructures for social reproduction to create "transformative and reparative communities" (Athanasiou). In comparison, our Western liberal feminism strikes me as – if I may say so – rather poor.  `,
        `While feminists in the Global North think about intersectionality by adding up types of discrimination and risk being appeased with offers of a "more just neoliberalism," movements, like those mentioned above, address the crises of colonial-patriarchal-extractivist capitalism through practices of resistance against land grabbing, sexism, and austerity. Here, colonial dispossession, patriarchal violence, as well as relentless ecocide are not understood as unfortunate coincidences independent from each other. They are understood as historically mutually causal mechanisms of the ongoing progressivist and productivist paradigm of our imperial way of life. Accordingly, indigenous feminists address the epistemological and planetary crisis in which we find ourselves and work toward a paradigm shift that must also change how we imagine feminist emancipation in traditions of western feminism. `,
        `Similar to decolonial feminisms, the notion of the human of the Enlightenment is critiqued, since it was conceived as male, white, and heterosexual from the start. In contrast, those who have been "othered" – the racialized, colonized, women, sexual dissidents, and also natural cultures and non-human organisms – have been objectified in the "civilizing project" of modernity and thus made available for exploitation and domination. In this regard, Lorena Cabnal from Guatemala speaks of epistemic violence, building a cosmovision in collectively managed communities in which feminist categories, such as the social reproduction of bodies, are related to questions of ecological regeneration of planetary forces, in a cyclical dynamic of interaction (cf. Cabnal 2010). `,
        `I am fascinated by these positions because they show the necessity for an epistemic break in feminism by connecting ecological questions with gender, and in that process manage to design new forms of the political. This epistemic break is needed because, as postcolonial historian Dipesh Chakrabarty explains, ever since the entanglement of human and planetary history was established in the "racial capitaloscene" (cf. Vergès 2020), any theory of the political and the social, any philosophical or economic axiomatic, would have to embed itself in the planetary structure, rethink the dependent location of humans in the web of life, and connect with the concerns of the planet (cf. Chakrabarty 2019: 1-31).`,
        `<#image>4_1.jpg</#image>
<h5><#c>Image: Kat Válastur and Ixchel Mendoza Hernández</#c></h5>`,
        `For too long, feminism has subscribed to "human exceptionalism." But pandering to the modernist-progressivist paradigm to gain power in an eroding ecosystem seems anachronistic today. So here I want to share my obsession with you. Starting from the practices of a feminist eco-commoning, I dig out the eco-feminism of the 80s, combine it with aspects of New Materialism, and try to make the combination bear fruit for a notion of "planetary feminisms." `,
        `I understand the planetary alongside Gayatri Chakravorty Spivak as an anti-colonial concept of alterity, against the homogenizing global – a system that is radically "other" and at the same time ourselves (cf. Spivak 2012). Furthermore, I use concepts of the planetary, following Earth System Sciences and the dynamic co-dependence of non-humans and humans with their social and economic organization. Like the "Gaia hypothesis" developed by the natural scientist James Lovelock and the microbiologist Lynn Margulis in the mid-1970s, this Earth system, with its living and inanimate matter, constantly recreates the conditions for its preservation in a self-regulating manner in that the totality of all organisms relate symbiotically to one another in forms that are described in ecological philosophy through principles of "interrelatedness" or as "entanglements/interconnections." `,
        `Thus, planetary thinking in current New Materialist philosophies understands the planet as an assemblage of inter- and symbiotic relations that is self-sustaining through the principle of regeneration. In formulating a possible “planetary feminist” analysis, this work on the cyclical regeneration of life is thought together with the question of social reproduction, in an attempt to follow the politics of eco-communal feminisms and de-naturalize and politicize such "re-productivity" (Bauhardt 2021).`,
        `<#image>4_2.jpg</#image>
<h5><#c>Image: Kat Válastur and Ixchel Mendoza Hernández</#c></h5>`
    ],
    subpages: [
        {
            title: "Ecofeminism reloaded",
            content: [
                `Attempts to epistemically reinvent feminist categories in relation to planetarian connections, are, of course, not new. The barely-received traditions of ecofeminist movements or of Feminist Political Ecology have followed a methodological trail that extends to the feminisms of posthumanism or New Materialism. Since the 1980s, ecofeminists, such as Vandana Shiva, have argued that becoming involved in the toxic economy of fossil capitalism is not an advance of the feminist cause (cf. Shiva/Mies 2014). This is not only because feminized people are more affected by climate disasters or because they are at the forefront of movements against land grabbing. It’s also because there is a link between social reproduction and our social relation to nature. It is a structural tie between the extractivist logic by which the regenerating productivity of natural cultures has been exploited since the beginning of colonial capitalism and the patriarchal logic by which female reproductive and care work is appropriated. The core of any ecofeminist argument is that in colonial-capitalist patriarchy, gender and nature relations are mediated for the purpose of exploiting the "costless resources" of reproductive and regenerative labor. `,
                `From my point of view, an engagement with ecofeminist positions today is fruitful for the paradigm shift that feminism sorely needs. In the practices of indigenous and communitarian feminisms, feminism is not encompassed by problems of equality or discrimination vis-à-vis man-humans. Rather, it can be understood as a methodological project that thinks of gender as a relation mediated by capitalist-colonialist relations of nature and focuses on the intertwined conditions of its construction in the planetary. In this sense, the feminist project cannot be formulated without the ecological one – and vice versa – since both point to the same extractivist relationship with the planetary, which devalues and violently appropriates both reproductive and regenerative relational labor. The imperative to think planetarily is not another ethical task of feminism, but is intrinsic to its foundational problems, which involve the issues of the naturalized female subject, our gendered relation to nature, and the division of labor in social reproduction.`
            ]
        }, {
            title: "Planetary feminisms: social reproduction and ecological regeneration",
            content: [
                `We have arrived at the frontier of ‘cheap nature,’ where the web of life has gone from cost-cutting productivity to cost-maximizing, and extractivist violence against humans and non-humans is only intensifying. Ecofeminist municipalists from Barcelona emphasize how the "biophysical limits of the planet" fundamentally condition and exacerbate the "great socio-reproductive crisis" (cf. Fernández/Campos 2021: 1). Based on a self-understanding of "inter- and ecodependence" of bodies, they build collaborative economic and ecological networks that integrate care work as a vehicle for the feminist and socio-ecological transformation of their neighborhoods. `,
                `In the wake of such approaches, a planetary feminist approach would recognize that the last instance of politics is decided at the question of the exhaustion of bodies and planetary forces, and that organizing and politicizing infrastructures of reproduction, reparation, care, and regeneration work should be at the core of feminist work. If we understand the planetary as an assemblage of relations, the central relation that interests a planetary feminism would be the relation to the sphere of social reproduction and planetary reproduction, or in the words of feminist political ecologist, Christine Bauhardt, "ReProductivity" (see Bauhardt 2021). With this term, Bauhardt tries to express that the activities assigned to the "sphere of reproduction," such as physical care, affective care work, the maintenance of living things, solidarity or assistance work, and work on the metabolism of the social are not secondary. They produce values, ecotopias, and socialities that manifest themselves concretely as materializations of a production of a world, indeed in a "wordling." It is the re-productivity – the labor of the ever-repeating, cyclical regeneration of life – that is shared by planetary forces and feminized subjects alike; they share re-production to sustain life but also the experience of precarization and the violent extraction of the activities, bodies, and capacities that ensue. `,
                `From an ecofeminist perspective, this fundamental form of re-productivity must be valorized and taken seriously as a category of political economy. The approach of the Bielefeld ecofeminists from the 80s, for example, demands the replacement of the linear cumulative logic of abstract (monetary) values by the cyclical logic of a subsistence economy. In this context, market relations are overwritten by care relations for self-preservation, which – as Maria Puig de la Bellacasa demands, against the backdrop of endangered ecologies – would have to be extended to "more-than-humans" (cf. Bellacasa 2017). Value relations would be oriented at "reparative justice" (cf. Bellacasa/Papadopoulos 2021) for natural cultures, in more-than-social networks. This might sound like a difficult intellectual operation but has long been practiced by the poorest parts of the female, mostly racialized proletariat. In African ecofeminist projects, for example, where biodiverse seed banks or reforestation endeavors are taking place in a network of eco-commoning and where, during this participatory care work, new social contracts and economic-political rights for the mutual co-production of people and ecologies become conceivable (cf. Witterich 2015: 93). `,
                `Thus, a planetary feminism sets reciprocal-cyclical regeneration as the primacy of the political, not only in the sense of recognizing reproductive activities as economically value-producing, but also as a vehicle of planetary democracy, justice, and sovereignty. The more-than-human networks of regenerative care cycles would have to be placed in a juridical framework and thus understood as bearing sovereignty, rights, or citizenship. Thus, ecofeminist municipalists demand sovereign democratic infrastructures around access to water, energy, land, or food, proposing a new mode of urban governance. Feminismo Communitario from Latin America speaks of "popular sovereignty" practiced in the political self-management of territories and resources for the subsistence of local communities. Such feminisms inspire planetary thinking; they translate the question of gender justice into alternative designs of reproductive and ecological inter-relations, thereby sharpening feminism into a political proposal. `,
                `In a world that must prepare to live in the ruins of planetary catastrophes, care work will be the main medium of subsistence for the subaltern. Thus, it will be imperative to detach feminism from neoliberal participation in ecocide and instead practice it experimentally as a planetary method that relies on the experience of such regenerative relationalities to care for and assist the self-preservation processes of "Gaia."`,
                `
<h4 style="text-align: left; font-weight: inherit; margin: 0;">REFERENCES</h4><#note><#nn>1.</#nn><#nt>Bauhardt, Christine (2012): Feministische Ökonomie, Ökofeminismus und Queer Ecologies – feministisch-materialistische Perspektiven auf gesellschaftliche Naturverhältnisse, <#link>https://www.fu-berlin.de/sites/gpo/pol_theorie/Zeitgenoessische_ansaetze/Bauhardtfemoekonomie/index.html</#link>, zuletzt 11.10.2021</#nt></#note>
<#note><#nn>2.</#nn><#nt>Bellacasa, Maria Puig de la (2017): Matters of Care, Speculative Ethics in More Than Human Worlds, University of Minnesota Press</#nt></#note>
<#note><#nn>3.</#nn><#nt>Ballacasa, Puig de la/ Papadopoulos, Dimitris (2012): Ecologies of Reparation, <#link>https://www.youtube.com/channel/UCLPR23DQu5W4W6EARdDCT2Q</#link></#nt></#note>
<#note><#nn>4.</#nn><#nt>Cabnal, Lorena (2010): Feminismos diversos: el feminismo comunitario, ACSUR-Las Segovias</#nt></#note>
<#note><#nn>5.</#nn><#nt>Chakrabarty, Dipesh (2019): The Planet: An Emergent Humanist Category, in: Critical Inquiry, Autumn, S. 1-31. </#nt></#note>
<#note><#nn>6.</#nn><#nt>Federici, Silvia (2020): Die Welt wieder verzaubern, Mandelbaum Verlag</#nt></#note>
<#note><#nn>7.</#nn><#nt>Fernández, Blanca Bayas/ i Campos, Joana Bregolat (2020): Ecofeminist Proposals for reimagining the city, Observatori del Deute en la Globalitzación</#nt></#note>
<#note><#nn>8.</#nn><#nt>Gaard, Greta (2017): Critical Ecofeminism, Lexington Books</#nt></#note>
<#note><#nn>9.</#nn><#nt>Grosz, Elisabeth (1994): Volatile Bodies, Routledge Books</#nt></#note>
<#note><#nn>10.</#nn><#nt>Harcourt, Wendy/ Nelson L Ingrid (Hs) (2015): Practising Feminist Political Ecologies: Moving Beyond the 'Green Economy', Zed Books </#nt></#note>
<#note><#nn>11.</#nn><#nt>Hark, Sabine (2021): Gemeinschaft der Ungewählten, Suhrkamp </#nt></#note>
<#note><#nn>12.</#nn><#nt>Lovelock, James (2021): Das Gaia Prinzip, die Biographie unseres Planeten, München</#nt></#note>
<#note><#nn>13.</#nn><#nt>Lynn, Margulis (2018): Der symbiotische Planet oder wie die Evolution wirklich verlief, Frankfurt am Main</#nt></#note>
<#note><#nn>14.</#nn><#nt>MacGregor, Sherilyn, 2006: Beyond Mothering Earth: Ecological Citizenship and the Politics of Care. Vancouver. </#nt></#note>
<#note><#nn>15.</#nn><#nt>Mies, Maria/Shiva, Vandana(2015): Ecofeminism, Zed Books</#nt></#note>
<#note><#nn>16.</#nn><#nt>Mies, Maria (2015): Patriarchat und Kapital, bge-Verlag</#nt></#note>
<#note><#nn>17.</#nn><#nt>Moore, Jason (2019): Kapitalismus im Lebensnetz, Matthes und Seitz</#nt></#note>
<#note><#nn>18.</#nn><#nt>Spivak, Gayatri Chakravorty (2012): An aesthetic education in the era of globalization, Harvard University Press</#nt></#note>
<#note><#nn>19.</#nn><#nt>Stacy Alaimo/Susan Hekman (2008): Material Feminisms, Indiana University Press</#nt></#note>
<#note><#nn>20.</#nn><#nt>Witterich, Christa (2015): Contesting Green Growth, Connecting Care, Commons and Enough, in: Harcourt, Wendy/ Nelson L Ingrid (Hs) (2015): Practising Feminist Political Ecologies: Moving Beyond the 'Green Economy', Zed Books, S. 67-100 </#nt></#note>
<#note><#nn>21.</#nn><#nt>Vergès, Francoise (2019): Dekolonialer Feminismus, Passagen </#nt></#note>
`
            ]
        }
    ]
};
const ashes_of_anita_berber = {
    title: "The Ashes of Anita Berber and the Confession of the Dust",
    lang: "en",
    id: "ashes_of_anita_berber",
    author: "Kat Válastur",
    bg: {
        start_col: "white",
        col: "#7de6eb80",
        end_col: "white",
        type: "gradient",
        elements: [
            {
                type: "perlin",
                text: "   •   ",
                s: 1,
                h: 2,
                x: -.35,
                y: .25,
                x_skew: -1
            }
        ]
    },
    content: [
        `On the  8th of May 2021, the ruins of the Chinese rocket Long March 5B fell into the Indian Ocean. Lucky it didn’t fall on our heads, we sighed in relief. But the ruins fell somewhere. The news informed us that the ruins fell into the Indian Ocean, but was it close to Asia, Africa, or Australia? I am imagining the ruins of the rocket like the ashes inside a cremation box that a carrier throws in the waters: ashes thrown in the Indian Ocean by the huge hand of technology, a cremated body unknown. The ashes are soft and thin. Like dust, they are organic matter. The ruins fall and penetrate the ocean like invaders in a new land. Whether the citizens of the underwater world were hurt or if there were any victims is unknown. We do know that they will have to deal with the metallic invaders in their land and live with them forever.`,
        `I get out of the water to catch my breath…`,
        `It’s getting dark and I am still swimming in the waters of the Indian Ocean, watching the reflections of the mangrove forest along the shore, as the moon appears. The heat rises… I can now see the plankton, a glittering microorganism floating around me. If you have ever seen how plankton looks in a microscope, it looks like the ruins of a machine. `,
        `As I stand there in the water I feel like a giant looking at the ruins of a timeless mechanism, looking so small because I am so big. It’s all a  matter of perception. What would I look like if you saw me from above? Would you see a body swimming in plankton? Or a body sliding along the avenues of a discarded circuit board? I do not ask for help; I made these ruins. I am their creator, maybe not me per se but my species. I see every grain of fluorescent plankton moving around me, as if it were a grain from my body, my future body, my past, my present, the ashes of the bodies of my ancestors, leftovers of human progress. In the plakton I see the discovery of the wheel, electricity, the cell phone, microchips, like parts thrown in the trash somewhere in China, and inside this garbage I also see everything that has not yet been discovered until the day it will, only to become garbage in turn. I come out of the water slowly and gently, like a lover who does not want to wake up her lover. We separate.`,
        `We come from stardust and return to it, some scientists say. In space there are as many stars as there are grains of sand on earth. Is this also true for people? The grains of sand, the ashes of the dead – it makes me think of the metaphysical matter that is Anita Berber's ashes. Her particles gain will and are drawn to each other, creating a body of moving matter with brains and consciousness, navigating itself to the theater. These unified particles of ashes arrive and settle there, in obscure places like under the seats, in the corners, under the soles of your shoes. These newly shaped particles with the anthropomorphic characteristics that my imagination gave them scream or smile with pleasure at everything they see happening around them.`,
        `<#image>6_1.jpg</#image>
<h5><#c>Image: Prof. Gordon T. Taylor, Stony Brook University - corp2365, NOAA Corps Collection</#c></h5>`,
        `I arrive at the empty theater soaked in water from the ocean. I am still holding the towel from the beach. It has a high-pigmentation color cartoon rendition of Anita printed on it. I never noticed before. I wipe myself off and grains of sand fall to the floor. They move quickly to mix with the  ashes like relatives who haven’t seen each other for years.`
    ],
    subpages: [
        {
            title: "The confessions of ashes and dust<br>(in the theater)",
            content: [
                `What happened? We do not know how long this has been going on for. Maybe this situation has existed for hours, or even years. We are not able to fully understand. Some of us managed to see what was happening up there because a gust of wind spread us around the space and a few of us landed inside the eye of someone who lives on the upper floor. But that was the end of them, for they did not return. We never knew if they died or if they found a place with the particles up there. We were waiting for a teardrop to fall and carry one of us with it, dead or alive, but it never happened. We wonder if there is innocence left, and we are not nostalgic in the traditional sense. We down here (the particles) continue to believe in the age of dance that has not yet come. It is true that people from the ancient times and mostly during the middle ages enjoyed watching the fire burn – it was a most significant spectacle – but no one stayed to see the ashes. Fire was always the spectacle, never the ashes. From my perspective as a grain, I can confirm that in reality only the ashes are able to speak of fire. `,
                `“I" the grain want to ask:`,
                `Who can talk best about corners, if not the dust? We hope and believe that one day there will be room for the ashes and the dust to speak. Fire is exciting, it is energy and destruction and it is spectacular, and these are qualities that are essential and appealing to humanity. But the fire creates the ashes and only from the ashes can the Phoenix rise. They are the ultimate new beginning. A beginning that starts from the end is not preserved for consumption. We the specks, the grains, we like the idea of a fire that burns and turns everything into ashes and dust – the more of us the better. Maybe one day, from the floors, the corners, from places where you wouldn’t expect, birds will be born.`,
                `Luckily, the wreckage of Long March 5B fell on other heads and not ours ironically speaking. Well I say, let's think about those heads too. Also be careful where you step when you enter the theater, because there is life down there, under your feet, not yet in its full shape, but in process. And it will be a new life, not yet experienced. So be careful where you step, we don’t want to get stuck under your shoes, because “I” the speck and the rest of us are not ready to move out. We like the theater.`
            ]
        }
    ]
};
const clothes_manifesto = {
    title: "Manifesto on Clothes",
    lang: "en",
    id: "clothes_manifesto",
    author: "Lena Klink",
    bg: {
        start_col: "white",
        col: "#b487d780",
        end_col: "white",
        type: "gradient"
    },
    content: [
        `<#c><b>clothes shouldn’t limit you in your experience
if a movement takes you somewhere - follow
so shirts might get holes, shoes dirty and scratched
follow wisely

in spring wear black to absorb all warmth
choose wisely

in case you wear clothes while dancing - acknowledge them as your closest partner
the way they meet your body
touch you here and there and again there
while you move
large clothes
that build up oppositions
to yourself
mingle around you
in such ease and ability to fold and fall in itself
consistently
feel wisely

if you can, choose a job in which you can wear anything 
you want to
if it was not possible for you yet, play with the limits
with authenticity and charm
charm wisely

in the end wear anything you want to wear 
wear it with pride and resonance
with history
resonate wisely

and also don’t wear them
take them off
and if there is a struggle remind yourself on the softness of a shirt, a blanket, a scarf or a really good fitting sock
on you
you wisely</b></#c>`,
        `<#c>western privileged and damaged, apr 22
lena klink</#c>`,
        `<#image>9_1.jpg</#image>
<h5><#c>Image: Kat Válastur</#c></h5>`
    ],
    subpages: [
    // { 
    //     element: {
    //         type: "perlin",
    //         text: "                                        ••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••                                        ",
    //         s:1,
    //         h:2,
    //         x:0,
    //         y:0,
    //         x_skew: -1,
    //     }
    // }
    ]
};
const berlin_istanbul = {
    title: "Berlin-Istanbul: Interview with Leyla Postalcıoğlu",
    lang: "en",
    id: "berlin_istanbul",
    bg: {
        start_col: "white",
        col: "#ff6e2d80",
        end_col: "white",
        type: "gradient",
        elements: [
            {
                type: "perlin",
                text: "   •   ",
                s: 1,
                h: 2,
                x: 0.35,
                y: 0,
                x_skew: -1
            }
        ]
    },
    author: "by Kat Válastur",
    content: [
        `<b>I met Leyla Postalcıoğlu in 2014, when I was looking for performers for my creation “Ah! Oh! A contemporary ritual.” Leyla is full of warmth and enthusiasm that springs out of her in the most gentle and compassionate way. She has this calm internal strength that balances the whole room when you are with her. This is what I felt when I met her and this is how she was the whole time we spent together during the adventures of “Ah! Oh! A contemporary ritual.” When she left Berlin we lost track simply because life is full of continuities disguised as discontinuities. We recently found each other again and talked about where we are in our lives, and how her life is growing back in Istanbul.  </b>`,
        `<b>When did you arrive in Germany and what was the reason?</b> 

When I was a teenager living in Istanbul, I dreamed of becoming a dancer. One day I watched works of Pina Bausch on a VHS tape and was deeply moved by what I saw. Following her inspiration led me to Germany. I arrived in Essen in 2000 to study dance at Folkwang University of the Arts.

<b>We met and worked together while you were in Berlin. How many years have you lived in Berlin and how was your life those years? </b>

I came to Berlin in 2010 after working as a dancer at State Theater Kassel for many years. I started living as an independent dancer and choreographer. In my first years, I was focused on creating my own work without knowing much about conceptualizing ideas. Complexity in receiving funding had been discouraging and soon enough I continued working mostly as a dancer. Working with Meg Stuart/Damaged Goods in several projects and later working with you made my life in Berlin very beautiful and meaningful. I was touring the pieces, rehearsing, taking classes, workshops, watching performances, and going to movies. Berlin was a place where I could dive into research for creations without getting distracted. As a side job, I took care of a little boy. Our friendship has been very precious. I was also lucky to have my oldest friend from Essen as my roommate. I loved biking. I spent a lot of my free time in my room on video calls, maintaining my long-distance relationship. I very much enjoyed the sky and open spaces in the city, but the feeling of loneliness was always there.

<b>You moved back to Turkey when exactly? Why did you decide to return? </b>

There isn’t an exact time, it all happened gradually. From 2017 on, the place I called home became İstanbul, even if I am still officially a resident of Berlin. I have always traveled between Germany and Turkey to visit my partner in life. From 2015 on, while I was on tour with “Until Our Hearts Stop” by Meg Stuart, traumatic events were happening one after another, especially in Turkey. I could no longer bear to witness them from afar. The most important thing was to be close to loved ones during difficult times, as if there was no time to miss. There was always the fear of not being able to get there if something happened. This fear became very real with the pandemic. Luckily, I was already in Istanbul.

<b>What is return for you? (Is exile something that you experienced while in Germany?)</b>

The word in Turkish for turn and return is the same: dönüş. It makes me think that I’m turning, so that things could go in any direction again, which is a thought that makes me feel better. Return, at least for now, is to be able to use my mother tongue in daily life and to be able to be more spontaneous while speaking, as well as while rehearsing and teaching. Return feels like going back to the place where I first started dreaming, also where I got hurt for the first time physically and emotionally. I am facing some old wounds associated with this place. When I was mostly based in Germany, it felt like I was living parallel lives, one in studios creating work, dancing and dreaming with my inspiring colleagues and friends, and the other obsessively following news from Turkey and worrying about losing loved ones. Even though people I love are again left behind, I can define return as the place where my dreams, thoughts, and worries can be in the same place most of the time. I have two grandmothers, aged 92 and 102, and living close to them after being apart for almost 20 years is a sign of return. 

(Exile sounds more like an enforced displacement, which was not my experience)

<b>How is your life now?</b>

I never imagined that I would be teaching this much, but I am. I teach mostly improvisation for dancers and actors at universities. It feels like an organic expansion of being a dancer and a wonderful way to combine experiences from Germany with new encounters in Turkey. I am creating works with local artists and students, and I keep performing. Most recently, I worked in collaboration with Francisco Camacho/EIRA and the collective Çıplak Ayaklar Kumpanyası. I am struggling with constantly shifting conditions, restrictions, different states of emergencies – not just financially and not just because of the pandemic. I can’t ride a bike anymore, but there are lots of stray cats and dogs I meet while walking. These encounters make my day. I’m also living with cats at home for the first time in my life, and this has been one of the best things that has happened to me in recent years. I share my life with my partner, as I wished for so long, and I try to take care of the elderly in the family as much as possible. When the distance from traveling doesn’t happen as much as it did before, life feels different and challenging. Not leaving is something I have to learn.`,
        `<#image>10_1.jpg</#image>
<h5><#c>Image: Video still from “KALAN-What Remains” by Cynthia Madansky</#c></h5>`,
        `<b>What kind of impact do the socio-political conditions in Turkey have on you and your work?</b>

I feel that every move or action requires much more energy than before. In one of the many places where violence is so much on the rise, where justice is constantly ignored, where the economic crisis deepens day by day, and where there is a state of collective depression inflicted by governmental policies, I find it extremely difficult to hold space for hope and optimism, especially while teaching younger people. I try hard not to lose hope and not to get used to hopelessness. All the work I have been involved in in the dance field here is an expression of the sociopolitical condition and a response to it.

<b>Is there a collective dynamic in Istanbul regarding dance and the arts? How is it expressed? </b>

I see a collective dynamic in which all commit to making the art form visible, within a context where dance is not solicited, does not matter or count, and is not part of the economy. Dance exists only because there are dancers who are making it. The future of dance only exists through our own initiative. Dancers hardly earn enough to make a living yet dance is existential for cultivating beauty and resistance against dominant forces – for living in this country. There is an intentional approach to professionalism and a spontaneity in initiating projects. In such conditions, solidarity could become real. In terms of aesthetics though, there are islands. 

<b>Are there ways of resistance in the context of womanhood and how it is expressed politically or artistically? </b>

It would not be an exaggeration to say that women+ constitute one of the strongest oppositions against the deepening authoritarian trajectory. Women+ come together in every field and raise their voices against femicide, masculine domination, and harassment, demanding wages and equal pay, and most importantly, to insist on the implementation of the Istanbul Convention “on preventing and combating violence against women and domestic violence.” In the artistic field, I became aware of some initiatives during the pandemic where women+ from the field of cinema and theater came together as #SusmaBitsin (me too movement) or as “women in the performing arts.” In these meetings, experiences are shared, disclosures are made, seminars on basic concepts are held, academic research and resources are shared, surveys are created and announced, economic solidarity networks are formed, and litigation follow-up and social media solidarity are ensured. When it comes to the dance field, I look around and see that the important representatives are women+, and ninety percent of my students in the classes as well. This does not necessarily emphasize the context of womanhood, but in the way I’ve experienced it in the past years here, when women+ come together in the studios to create work, it goes beyond art production. 

<b>What is your personal mode of resistance? </b>

Keep dancing, caring, listening, keep loving, slowing down, hesitating, and still not having Facebook or Instagram. I have been too afraid to join protests lately because of getting hurt. Really hoping to overcome this fear one day. 
You can watch Cynthia Madansky’s dance film KALAN- What remains in following link <a href='http://www.madansky.com/filmography/kalan' style="color: var(--blu)">http://www.madansky.com/filmography/kalan</a>
`
    ],
    subpages: []
};
const performance_art_gal = {
    title: "Excavating the Forever Interred – a dig into Season 1 of The Club for Performance Art Gallery",
    lang: "en",
    id: "performance_art_gal",
    author: "Louise Trueheart",
    bg: {
        start_col: "white",
        col: "#f0a0af80",
        end_col: "white",
        type: "gradient"
    },
    content: [
        `<#image>11_1.jpg</#image>
<h5><#c>Image: Sharon Mercado Nogales & Eli Wewentxu @ Club for Performance Art Gallery </#c></h5>`,
        `One of the participants, Inky Lee, wrote that the name was “like a flexible collage of some art-related words.” In their text published in May 2021, shortly after The Club was born, Inky rearranged the words as one would vegetables on a plate of crudités. “Performance Gallery for Art Club. Gallery Art for Performance Club. Art Club Gallery for Performance. Performance Art Club for Gallery. Art for Gallery Performance Club.” Regardless of the variation, the overall meaning of the title doesn’t change much, less for the meaninglessness of “art-related words,” and more because ‘accuracy’ doesn’t matter here. 

What actually happened? From my vantage point as someone who is writing about this curatorial program 18 months after the fact and attended none of it, the process feels a lot like excavating a thing whose existence in the first place is somewhat dubious – or at least, whose occurrence mainly exists in my imagination.  

The format was as follows: Camila Malenchini collects the names of artists she knows in Berlin, and invites them to join the club. Each artist is asked to give a one-on-one performance to a club member, and to watch the performance of another one. Camila makes the pairs and organizes the rendezvous. There is no funding. The context is Berlin in February 2021, mid-Covid lockdown. Everyone is social distancing, and the theaters are closed.

The invitation Camilla sends out reads: “the Club for Performance Art Gallery is a virtual space that is not on the internet.” Pause. A non-digital, virtual space? Of course, the primary meaning of ‘virtual’ is, according to encyclopedia.com, “almost or nearly as described, but not completely or according to strict definition.” In the digital landscape, the word virtual is used because it connotes a simulation, rather than a “real.” So how can a space (on earth, where people meet and talk with their bodies) be virtual? 

To name this space as virtual is funny, in context. It’s virtually a club, except that it’s a club without a club house or group get-togethers. It’s virtually a gallery, in the sense that there are many artists whose names get lined up in a big beautiful paragraph, displaying the breadth of the group and the resilience of community during Corona, but we never see them (or their works) standing side by side like we would in a “gallery.” In the architectural sense of the word, “gallery” is used to describe a hallway or colonnade with one side facing out and the other side flush with the building, whose roof-supporting pillars make what look like a series of frames. To me, there is something about variations on a theme that a gallery connotes. It’s a place to see many things of the same type, laid out in some sort of arrangement. 
`,
        `<#image>11_2.jpg</#image>
<h5><#c>Image: Maciek Sado @ Club for Performance Art Gallery</#c></h5>`,
        `Their names: Alex Viteri, Alice Chauchat, Ana Laura Lozza, Caitlin Fisher, Cajsa Godée, Cathy Walsh, Denise Lim, Duncan Routh, Eddy Levin, Emeka Ene, etaïnn zwerr, Inky Lee, Jee Chan, Juan Felipe Amaya Gonzalez, Juan Pablo Camara, Judith Förster, Julian Weber, Kiana Rezvani, Liina Magnea & Juliana Schreyer, Maciek Sado, Marga Alfeirao, Maria Francesca Scaroni, Meagan O’Shea, Ronald Berger, Roni Katz, Sharon Mercado & Eli Wewentxu, Stefan Pente. 

A one-on-one is a format performed by a single artist for an audience of one. A classic example is Adrian Howells, who gave you a bath and then held your tender bathrobe-clad body in his arms. One-on-ones often deal with the problematics of intimacy and/or touch, with an eye to showing how social norms and constructs keep us from being close to one another – breaking boundaries in the sense of moving closer. Many makers of this form, I among them, have preconceived notions about the integrity of the one-on-one and it seemed that some of the club-members did as well. Duncan Routh, worried whether he had somehow falsified the “sacred nature of the one-for-one performance.” Maciek Sado, who performed in a supermarket, made the excellent call to have a safe word or way out of the score, and also experienced the thrilling event of an audience member usurping the plan and changing the experience completely. Agency, consent, intimacy: you had to be there. 

In a New Yorker article on the iterations of excavating Pompeii, author Rebecca Mead writes that a third of the city remains interred in hopes that future archaeologists will make use of their more advanced technologies. While no technology I can imagine will ever be able to “unearth” what goes on between two people in the context of an intimate performance, the example of Pompeii is exciting because of the decisions taken to leave some things buried. This documentation of the Club for Performance Art Gallery takes that approach. Mead writes that “in Pompeii, discovery often goes hand in hand with destruction.” What is deemed worth unearthing changes over time. At the beginning of the excavation in Pompeii, it was common to break through a wall to see if there was treasure on the other side, destroying priceless frescoes and paintings in the process.

While this performance program certainly addressed intimacy, it could not reasonably explore proximity. Most people held their performances outside, in graveyards, abandoned areas, places where there is graffiti on the walls. And yet, perhaps because of the magic of one person performing for another, and perhaps because of the isolation of lockdown, intimacy couldn’t not be on the table. In Marga Alfeirao’s piece, Dirty Non-Dancing, she remembers that the day she performed, it was very cold, but she still wore a sleeveless overall. Inky writes that parts of Marga’s body, like her nipples, were (to Inky’s delight) very much present. Upon hearing that Marga was too cold “to get into the more dirty parts,” Inky exclaimed, “I missed out!!”

One of the many ideas behind this curation was an intent to “set in motion processes that are or were blocked, paused, stopped, etc. because of lockdown and work with the conditions that are available to us today.” To un-block something is to set it in motion – but you can’t know in which direction. None of the club members I spoke to seemed to have made physical distance from the audience a central focus of their work. If anything, the work created an opportunity to see people you didn’t know well from the scene and discuss things. For example, Cajsa Godée recounts that, after she performed, she and her “audience-member Stefan were having an openhearted conversation about the fear of showing work while hiding in a trench between the rails. We were both wearing very colorful clothes, neon hats, so the fear of getting caught by someone patrolling the rails was real. In my body: the adrenaline mixed with a shot of vodka and the pleasure of a sincere meeting with another person.” 

As a performer, it matters who are you visible to, when, and where. When the answer to where you are visible is not on a stage (or gallery, or club), we enter into interesting terrain. These performances brought the audience into unusual spaces in the city, like the trenches behind Cajsa’s train tracks, or potentially dangerous situations, like having your breasts out underneath a bridge. And although it is an audience for one, the “public” as in the people populating the “public space” remained a factor (whether they are visible or not). Ronald Berger said, “even though the streets were empty and it was rainy and dark, I felt surveilled.” Ronald’s performance took place, in part, in front of a surveillance tower, or “wachtturm.” 

Sharon and Eli’s piece was on Tempelhoferfeld, and involved drawing large insignia on the ground. Inky reports the mixed reactions of passersby as they were forced to walk over the big signs. One shouts, “Entschuldigung, kann ich vorbei?!” while another mumbles, “sorry, sorry!” Someone else thinks their show is going to turn into a rally. A dog jumps up and licks Eli’s face. A police van drives by and its occupants observe everything closely, but stop they do not. A child climbs a tree nearby and watches.
`,
        `<#image>11_3.jpg</#image>
<h5><#c>Image: Mapped to the closes address @ Club for Performance Art Gallery</#c></h5>`,
        `Proximity is (always) and was (very much at the time) a special kind of danger. Cathy Walsh attended Eddy Levin’s performance, which was located in their bedroom. Cathy’s piece, “Spoon Talks,” requires lying front to back, in the shape known as “spooning.” How did they manage to work with corona safety protocols? Masks, tests, and hope I suppose. But my sense from speaking to many is that the club members were enthusiastic about meeting each other. I gather an all-but-innocuous togetherness perhaps best explained by Duncan Routh as “a rather comforting air of insularity, artist supporting artist.” 

In so many ways, the event truly was virtual. It did happen, but because it was so diffuse, no one was there to witness it. We have all the proof that it happened, but we won’t know the feeling, the context, the states of mind, and never can. Inky writes, “same situation, two different people, two different experiences.” I like to imagine how life in Pompeii might have been – fish and snail soup, people bathing in the public fountain on a hot day. Traces are heavier for the space they make available for our imaginations. It’s not just that it happened, it’s that it happened together, even though no ‘one’ knows what happened. Time to not break through any further. 


`,
        `<h4>POST-SCRIPT</h4>`,
        `It should be noted that further seasons of the Club for Performance Art Gallery took place, but with variations on the format. The T.E.N.T festival hosted the Club for Performance Art Gallery in November of 2021, opening the one-on-ones to a public beyond the club members. This 12th and 13th of August 2022, the club will exercise itself once more, also with T.E.N.T. 

All quotations and recollections about what transpired during Season 1 were gathered by the author via email or voice message between May 5th and 25th 2022. 
`,
        `<#image>11_4.jpg</#image>
<h5><#c>Image: Judith Förster @ Club for Performance Art Gallery</#c></h5>`
    ],
    subpages: []
};
const contact = {
    title: "CONTACT",
    lang: "en",
    id: "contact",
    bg: {
        col: "white",
        type: "solid"
    },
    subpages: [
        {
            title: "",
            content: [
                `<style>.contact.fa.fa-soundcloud{padding: 0.75rem .95rem 0.75rem .55rem; !important}</style>`,
                //`<#c>${Object.keys(social_links).map(x=>`<a class='contact fa fa-${x}' href='${social_links[x]}'></a>`).join("      ")}</#c> <#style>a.contact.fa{color: var(--blu);margin: 0;border-width: 1px;border-style: none;border-radius: 2rem;text-align: center;text-decoration: none;width: 24px;height: 24px;padding: 0.75rem;}a.contact.fa:hover{border-style: solid;}</#style>`,
                `<#c><a style="color: var(--blu)" href='${social_links["envelope"]}'>${social_links["envelope"].replace('mailto:', "")}<a>
                </#c>`
            ]
        }, {
            element: {
                type: "perlin",
                text: "SPREAD",
                s: 1,
                h: 1,
                x: 0,
                y: -.2
            }
        }
    ]
};
const imprint = {
    title: "IMPRINT",
    lang: "en",
    id: "imprint",
    bg: {
        col: "white",
        type: "solid",
        heading_col: "var(--blu)"
    },
    content: [`
<#c><div style="color: var(--blu)">
SPREAD
Online magazine on dance & performance

<a href='https://spread-magazine.com/' style="color: var(--blu);">www.spread-magazine.com</a>

<a style="color: var(--blu)" href='${social_links["envelope"]}'>${social_links["envelope"].replace('mailto:', "")}</a>


All rights reserved. No unauthorized use, copying or reproduction of the images and/or text, whether for personal or commercial purposes, without the written permission of the artist (see details above).


Supported by DIEHL+RITTER/TANZPAKT RECONNECT, which is funded by the German Federal Government Commissioner for Culture and the Media as part of the NEUSTART KULTUR initiative 

<#c><img style='width: 50vw' src='./resources/images/pp.png' /></#c>
</div></#c>    
`],
    subpages: [
        {
            title: "Privacy Policy",
            content: [`<div style="color:var(--blu); font-family: Open Sans;font-weight: 500;font-size: calc(.85rem * var(--sc));line-height: 110%;margin-top: 4rem;">
We are very delighted that you have shown interest in our enterprise. Data protection is of a particularly high priority for the management of the Kat Válastur. The use of the Internet pages of the Kat Válastur is possible without any indication of personal data; however, if a data subject wants to use special enterprise services via our website, processing of personal data could become necessary. If the processing of personal data is necessary and there is no statutory basis for such processing, we generally obtain consent from the data subject.

The processing of personal data, such as the name, address, e-mail address, or telephone number of a data subject shall always be in line with the General Data Protection Regulation (GDPR), and in accordance with the country-specific data protection regulations applicable to the Kat Válastur. By means of this data protection declaration, our enterprise would like to inform the general public of the nature, scope, and purpose of the personal data we collect, use and process. Furthermore, data subjects are informed, by means of this data protection declaration, of the rights to which they are entitled.

As the controller, the Kat Válastur has implemented numerous technical and organizational measures to ensure the most complete protection of personal data processed through this website. However, Internet-based data transmissions may in principle have security gaps, so absolute protection may not be guaranteed. For this reason, every data subject is free to transfer personal data to us via alternative means, e.g. by telephone.        


<b>1. DEFINITIONS</b>

The data protection declaration of the Kat Válastur is based on the terms used by the European legislator for the adoption of the General Data Protection Regulation (GDPR). Our data protection declaration should be legible and understandable for the general public, as well as our customers and business partners. To ensure this, we would like to first explain the terminology used.

In this data protection declaration, we use, inter alia, the following terms:

<#block>
<#bn>A)</#bn>
<#bt>PERSONAL DATA

Personal data means any information relating to an identified or identifiable natural person (“data subject”). An identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.</#bt>
</#block>

<#block>
<#bn>B)</#bn>
<#bt>DATA SUBJECT

Data subject is any identified or identifiable natural person, whose personal data is processed by the controller responsible for the processing.</#bt>
</#block>

<#block>
<#bn>C)</#bn>
<#bt>PROCESSING

Processing is any operation or set of operations which is performed on personal data or on sets of personal data, whether or not by automated means, such as collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction. </#bt>
</#block>

<#block>
<#bn>D)</#bn>
<#bt>RESTRICTION OF PROCESSING

Restriction of processing is the marking of stored personal data with the aim of limiting their processing in the future. </#bt>
</#block>

<#block>
<#bn>E)</#bn>
<#bt>PROFILING

Profiling means any form of automated processing of personal data consisting of the use of personal data to evaluate certain personal aspects relating to a natural person, in particular to analyse or predict aspects concerning that natural person's performance at work, economic situation, health, personal preferences, interests, reliability, behaviour, location or movements. </#bt>
</#block>

<#block>
<#bn>F)</#bn>
<#bt>PSEUDONYMISATION

Pseudonymisation is the processing of personal data in such a manner that the personal data can no longer be attributed to a specific data subject without the use of additional information, provided that such additional information is kept separately and is subject to technical and organisational measures to ensure that the personal data are not attributed to an identified or identifiable natural person. </#bt>
</#block>

<#block>
<#bn>G)</#bn>
<#bt>CONTROLLER OR CONTROLLER RESPONSIBLE FOR THE PROCESSING

Controller or controller responsible for the processing is the natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of personal data; where the purposes and means of such processing are determined by Union or Member State law, the controller or the specific criteria for its nomination may be provided for by Union or Member State law. </#bt>
</#block>

<#block>
<#bn>H)</#bn>
<#bt>PROCESSOR

Processor is a natural or legal person, public authority, agency or other body which processes personal data on behalf of the controller. </#bt>
</#block>

<#block>
<#bn>I)</#bn>
<#bt>RECIPIENT

Recipient is a natural or legal person, public authority, agency or another body, to which the personal data are disclosed, whether a third party or not. However, public authorities which may receive personal data in the framework of a particular inquiry in accordance with Union or Member State law shall not be regarded as recipients; the processing of those data by those public authorities shall be in compliance with the applicable data protection rules according to the purposes of the processing.</#bt>
</#block>

<#block>
<#bn>J)</#bn>
<#bt>THIRD PARTY

Third party is a natural or legal person, public authority, agency or body other than the data subject, controller, processor and persons who, under the direct authority of the controller or processor, are authorised to process personal data.</#bt>
</#block>

<#block>
<#bn>K)</#bn>
<#bt>CONSENT

Consent of the data subject is any freely given, specific, informed and unambiguous indication of the data subject's wishes by which he or she, by a statement or by a clear affirmative action, signifies agreement to the processing of personal data relating to him or her.</#bt>
</#block>


<b>2. DEFINITIONS</b>

Controller for the purposes of the General Data Protection Regulation (GDPR), other data protection laws applicable in Member states of the European Union and other provisions related to data protection is:

Kat Válastur
Herrfurthplatz 11
12049 Berlin
Germany
Phone: +4917623217049
Email: to.adlib@gmail.com
Website: <#link>www.katvalastur.com</#link>


<b>3. COLLECTION OF GENERAL DATA AND INFORMATION</b>

The website of the Kat Válastur collects a series of general data and information when a data subject or automated system calls up the website. This general data and information are stored in the server log files. Collected may be (1) the browser types and versions used, (2) the operating system used by the accessing system, (3) the website from which an accessing system reaches our website (so-called referrers), (4) the sub-websites, (5) the date and time of access to the Internet site, (6) an Internet protocol address (IP address), (7) the Internet service provider of the accessing system, and (8) any other similar data and information that may be used in the event of attacks on our information technology systems.

When using these general data and information, the Kat Válastur does not draw any conclusions about the data subject. Rather, this information is needed to (1) deliver the content of our website correctly, (2) optimize the content of our website as well as its advertisement, (3) ensure the long-term viability of our information technology systems and website technology, and (4) provide law enforcement authorities with the information necessary for criminal prosecution in case of a cyber-attack. Therefore, the Kat Válastur analyzes anonymously collected data and information statistically, with the aim of increasing the data protection and data security of our enterprise, and to ensure an optimal level of protection for the personal data we process. The anonymous data of the server log files are stored separately from all personal data provided by a data subject.


<b>4. ROUTINE ERASURE AND BLOCKING OF PERSONAL DATA</b>

The data controller shall process and store the personal data of the data subject only for the period necessary to achieve the purpose of storage, or as far as this is granted by the European legislator or other legislators in laws or regulations to which the controller is subject to.

If the storage purpose is not applicable, or if a storage period prescribed by the European legislator or another competent legislator expires, the personal data are routinely blocked or erased in accordance with legal requirements.


<b>5. RIGHTS OF THE DATA SUBJECT</b>

<#block>
<#bn>A)</#bn>
<#bt>RIGHT OF CONFIRMATION

Each data subject shall have the right granted by the European legislator to obtain from the controller the confirmation as to whether or not personal data concerning him or her are being processed. If a data subject wishes to avail himself of this right of confirmation, he or she may, at any time, contact any employee of the controller.</#bt>
</#block>

<#block>
<#bn>B)</#bn>
<#bt>RIGHT OF ACCESS

Each data subject shall have the right granted by the European legislator to obtain from the controller free information about his or her personal data stored at any time and a copy of this information. Furthermore, the European directives and regulations grant the data subject access to the following information:</#bt>
</#block>
<#ts><#block><#bn>•</#bn><#bt>the purposes of the processing;</#bt></#block>
<#block><#bn>•</#bn><#bt>the categories of personal data concerned;</#bt></#block>
<#block><#bn>•</#bn><#bt>the recipients or categories of recipients to whom the personal data have been or will be disclosed, in particular recipients in third countries or international organisations;</#bt></#block>
<#block><#bn>•</#bn><#bt>where possible, the envisaged period for which the personal data will be stored, or, if not possible, the criteria used to determine that period;</#bt></#block>
<#block><#bn>•</#bn><#bt>the existence of the right to request from the controller rectification or erasure of personal data, or restriction of processing of personal data concerning the data subject, or to object to such processing;</#bt></#block>
<#block><#bn>•</#bn><#bt>the existence of the right to lodge a complaint with a supervisory authority;</#bt></#block>
<#block><#bn>•</#bn><#bt>where the personal data are not collected from the data subject, any available information as to their source;</#bt></#block>
<#block><#bn>•</#bn><#bt>the existence of automated decision-making, including profiling, referred to in Article 22(1) and (4) of the GDPR and, at least in those cases, meaningful information about the logic involved, as well as the significance and envisaged consequences of such processing for the data subject.</#bt></#block>
<#block><#bn>•</#bn><#bt>Furthermore, the data subject shall have a right to obtain information as to whether personal data are transferred to a third country or to an international organisation. Where this is the case, the data subject shall have the right to be informed of the appropriate safeguards relating to the transfer.
If a data subject wishes to avail himself of this right of access, he or she may, at any time, contact any employee of the controller.</#bt></#block>
</#ts>

<#block>
<#bn>C)</#bn>
<#bt>RIGHT OF RECTIFICATION

Each data subject shall have the right granted by the European legislator to obtain from the controller without undue delay the rectification of inaccurate personal data concerning him or her. Taking into account the purposes of the processing, the data subject shall have the right to have incomplete personal data completed, including by means of providing a supplementary statement.
If a data subject wishes to exercise this right to rectification, he or she may, at any time, contact any employee of the controller.</#bt>
</#block>

<#block>
<#bn>D)</#bn>
<#bt>RIGHT TO ERASURE (RIGHT TO BE FORGOTTEN)

Each data subject shall have the right granted by the European legislator to obtain from the controller the erasure of personal data concerning him or her without undue delay, and the controller shall have the obligation to erase personal data without undue delay where one of the following grounds applies, as long as the processing is not necessary:</#bt>
</#block>
<#ts><#block><#bn>•</#bn><#bt>The personal data are no longer necessary in relation to the purposes for which they were collected or otherwise processed.</#bt></#block>
<#block><#bn>•</#bn><#bt>The data subject withdraws consent to which the processing is based according to point (a) of Article 6(1) of the GDPR, or point (a) of Article 9(2) of the GDPR, and where there is no other legal ground for the processing.</#bt></#block>
<#block><#bn>•</#bn><#bt>The data subject objects to the processing pursuant to Article 21(1) of the GDPR and there are no overriding legitimate grounds for the processing, or the data subject objects to the processing pursuant to Article 21(2) of the GDPR.</#bt></#block>
<#block><#bn>•</#bn><#bt>The personal data have been unlawfully processed.</#bt></#block>
<#block><#bn>•</#bn><#bt>The personal data must be erased for compliance with a legal obligation in Union or Member State law to which the controller is subject.</#bt></#block>
<#block><#bn>•</#bn><#bt>The personal data have been collected in relation to the offer of information society services referred to in Article 8(1) of the GDPR.</#bt></#block>
<#block><#bn>•</#bn><#bt>If one of the aforementioned reasons applies, and a data subject wishes to request the erasure of personal data stored by the Kat Válastur, he or she may, at any time, contact any employee of the controller. An employee of Kat Válastur shall promptly ensure that the erasure request is complied with immediately.
Where the controller has made personal data public and is obliged pursuant to Article 17(1) to erase the personal data, the controller, taking account of available technology and the cost of implementation, shall take reasonable steps, including technical measures, to inform other controllers processing the personal data that the data subject has requested erasure by such controllers of any links to, or copy or replication of, those personal data, as far as processing is not required. An employees of the Kat Válastur will arrange the necessary measures in individual cases.</#bt></#block>
</#ts>

<#block>
<#bn>E)</#bn>
<#bt>RIGHT OF RESTRICTION OF PROCESSING

Each data subject shall have the right granted by the European legislator to obtain from the controller restriction of processing where one of the following applies:</#bt>
</#block>
<#ts><#block><#bn>•</#bn><#bt>The accuracy of the personal data is contested by the data subject, for a period enabling the controller to verify the accuracy of the personal data.</#bt></#block>
<#block><#bn>•</#bn><#bt>The processing is unlawful and the data subject opposes the erasure of the personal data and requests instead the restriction of their use instead.</#bt></#block>
<#block><#bn>•</#bn><#bt>The controller no longer needs the personal data for the purposes of the processing, but they are required by the data subject for the establishment, exercise or defence of legal claims.</#bt></#block>
<#block><#bn>•</#bn><#bt>The data subject has objected to processing pursuant to Article 21(1) of the GDPR pending the verification whether the legitimate grounds of the controller override those of the data subject.</#bt></#block>
<#block><#bn>•</#bn><#bt>If one of the aforementioned conditions is met, and a data subject wishes to request the restriction of the processing of personal data stored by the Kat Válastur, he or she may at any time contact any employee of the controller. The employee of the Kat Válastur will arrange the restriction of the processing.</#bt></#block>
</#ts>

<#block>
<#bn>F)</#bn>
<#bt>RIGHT TO DATA PORTABILITY

Each data subject shall have the right granted by the European legislator, to receive the personal data concerning him or her, which was provided to a controller, in a structured, commonly used and machine-readable format. He or she shall have the right to transmit those data to another controller without hindrance from the controller to which the personal data have been provided, as long as the processing is based on consent pursuant to point (a) of Article 6(1) of the GDPR or point (a) of Article 9(2) of the GDPR, or on a contract pursuant to point (b) of Article 6(1) of the GDPR, and the processing is carried out by automated means, as long as the processing is not necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller.
Furthermore, in exercising his or her right to data portability pursuant to Article 20(1) of the GDPR, the data subject shall have the right to have personal data transmitted directly from one controller to another, where technically feasible and when doing so does not adversely affect the rights and freedoms of others.
In order to assert the right to data portability, the data subject may at any time contact any employee of the Kat Válastur.</#bt>
</#block>

<#block>
<#bn>G)</#bn>
<#bt>AUTOMATED INDIVIDUAL DECISION-MAKING, INCLUDING PROFILING

Each data subject shall have the right granted by the European legislator to object, on grounds relating to his or her particular situation, at any time, to processing of personal data concerning him or her, which is based on point (e) or (f) of Article 6(1) of the GDPR. This also applies to profiling based on these provisions.
The Kat Válastur shall no longer process the personal data in the event of the objection, unless we can demonstrate compelling legitimate grounds for the processing which override the interests, rights and freedoms of the data subject, or for the establishment, exercise or defence of legal claims.
If the Kat Válastur processes personal data for direct marketing purposes, the data subject shall have the right to object at any time to processing of personal data concerning him or her for such marketing. This applies to profiling to the extent that it is related to such direct marketing. If the data subject objects to the Kat Válastur to the processing for direct marketing purposes, the Kat Válastur will no longer process the personal data for these purposes.
In addition, the data subject has the right, on grounds relating to his or her particular situation, to object to processing of personal data concerning him or her by the Kat Válastur for scientific or historical research purposes, or for statistical purposes pursuant to Article 89(1) of the GDPR, unless the processing is necessary for the performance of a task carried out for reasons of public interest.
In order to exercise the right to object, the data subject may contact any employee of the Kat Válastur. In addition, the data subject is free in the context of the use of information society services, and notwithstanding Directive 2002/58/EC, to use his or her right to object by automated means using technical specifications.</#bt>
</#block>

<#block>
<#bn>H)</#bn>
<#bt>RIGHT TO WITHDRAW DATA PROTECTION CONSENT

Each data subject shall have the right granted by the European legislator to withdraw his or her consent to processing of his or her personal data at any time. 
If the data subject wishes to exercise the right to withdraw the consent, he or she may, at any time, contact any employee of the Kat Válastur.</#bt>
</#block>


<b>6. DATA PROTECTION PROVISIONS ABOUT THE APPLICATION AND USE OF FACEBOOK</b>

On this website, the controller has integrated components of the enterprise Facebook. Facebook is a social network.

A social network is a place for social meetings on the Internet, an online community, which usually allows users to communicate with each other and interact in a virtual space. A social network may serve as a platform for the exchange of opinions and experiences, or enable the Internet community to provide personal or business-related information. Facebook allows social network users to include the creation of private profiles, upload photos, and network through friend requests.

The operating company of Facebook is Facebook, Inc., 1 Hacker Way, Menlo Park, CA 94025, United States. If a person lives outside of the United States or Canada, the controller is the Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland.

With each call-up to one of the individual pages of this Internet website, which is operated by the controller and into which a Facebook component (Facebook plug-ins) was integrated, the web browser on the information technology system of the data subject is automatically prompted to download display of the corresponding Facebook component from Facebook through the Facebook component. An overview of all the Facebook Plug-ins may be accessed under <#link>https://developers.facebook.com/docs/plugins/</#link>. During the course of this technical procedure, Facebook is made aware of what specific sub-site of our website was visited by the data subject.

If the data subject is logged in at the same time on Facebook, Facebook detects with every call-up to our website by the data subject—and for the entire duration of their stay on our Internet site—which specific sub-site of our Internet page was visited by the data subject. This information is collected through the Facebook component and associated with the respective Facebook account of the data subject. If the data subject clicks on one of the Facebook buttons integrated into our website, e.g. the "Like" button, or if the data subject submits a comment, then Facebook matches this information with the personal Facebook user account of the data subject and stores the personal data.

Facebook always receives, through the Facebook component, information about a visit to our website by the data subject, whenever the data subject is logged in at the same time on Facebook during the time of the call-up to our website. This occurs regardless of whether the data subject clicks on the Facebook component or not. If such a transmission of information to Facebook is not desirable for the data subject, then he or she may prevent this by logging off from their Facebook account before a call-up to our website is made.

The data protection guideline published by Facebook, which is available at <#link>https://facebook.com/about/privacy/</#link>, provides information about the collection, processing and use of personal data by Facebook. In addition, it is explained there what setting options Facebook offers to protect the privacy of the data subject. In addition, different configuration options are made available to allow the elimination of data transmission to Facebook. These applications may be used by the data subject to eliminate a data transmission to Facebook.


<b>7. DATA PROTECTION PROVISIONS ABOUT THE APPLICATION AND USE OF INSTAGRAM</b>

On this website, the controller has integrated components of the service Instagram. Instagram is a service that may be qualified as an audiovisual platform, which allows users to share photos and videos, as well as disseminate such data in other social networks.

The operating company of the services offered by Instagram is Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2 Ireland.

With each call-up to one of the individual pages of this Internet site, which is operated by the controller and on which an Instagram component (Insta button) was integrated, the Internet browser on the information technology system of the data subject is automatically prompted to the download of a display of the corresponding Instagram component of Instagram. During the course of this technical procedure, Instagram becomes aware of what specific sub-page of our website was visited by the data subject.

If the data subject is logged in at the same time on Instagram, Instagram detects with every call-up to our website by the data subject—and for the entire duration of their stay on our Internet site—which specific sub-page of our Internet page was visited by the data subject. This information is collected through the Instagram component and is associated with the respective Instagram account of the data subject. If the data subject clicks on one of the Instagram buttons integrated on our website, then Instagram matches this information with the personal Instagram user account of the data subject and stores the personal data.

Instagram receives information via the Instagram component that the data subject has visited our website provided that the data subject is logged in at Instagram at the time of the call to our website. This occurs regardless of whether the person clicks on the Instagram button or not. If such a transmission of information to Instagram is not desirable for the data subject, then he or she can prevent this by logging off from their Instagram account before a call-up to our website is made.

Further information and the applicable data protection provisions of Instagram may be retrieved under <#link>https://help.instagram.com/155833707900388</#link> and <#link>https://www.instagram.com/about/legal/privacy/</#link>.


<b>8. LEGAL BASIS FOR THE PROCESSING</b>

Art. 6(1) lit. a GDPR serves as the legal basis for processing operations for which we obtain consent for a specific processing purpose. If the processing of personal data is necessary for the performance of a contract to which the data subject is party, as is the case, for example, when processing operations are necessary for the supply of goods or to provide any other service, the processing is based on Article 6(1) lit. b GDPR. The same applies to such processing operations which are necessary for carrying out pre-contractual measures, for example in the case of inquiries concerning our products or services. Is our company subject to a legal obligation by which processing of personal data is required, such as for the fulfillment of tax obligations, the processing is based on Art. 6(1) lit. c GDPR. In rare cases, the processing of personal data may be necessary to protect the vital interests of the data subject or of another natural person. This would be the case, for example, if a visitor were injured in our company and his name, age, health insurance data or other vital information would have to be passed on to a doctor, hospital or other third party. Then the processing would be based on Art. 6(1) lit. d GDPR. Finally, processing operations could be based on Article 6(1) lit. f GDPR. This legal basis is used for processing operations which are not covered by any of the abovementioned legal grounds, if processing is necessary for the purposes of the legitimate interests pursued by our company or by a third party, except where such interests are overridden by the interests or fundamental rights and freedoms of the data subject which require protection of personal data. Such processing operations are particularly permissible because they have been specifically mentioned by the European legislator. He considered that a legitimate interest could be assumed if the data subject is a client of the controller (Recital 47 Sentence 2 GDPR).


<b>9. THE LEGITIMATE INTERESTS PURSUED BY THE CONTROLLER OR BY A THIRD PARTY</b>

Where the processing of personal data is based on Article 6(1) lit. f GDPR our legitimate interest is to carry out our business in favor of the well-being of all our employees and the shareholders.


<b>10. PERIOD FOR WHICH THE PERSONAL DATA WILL BE STORED</b>

The criteria used to determine the period of storage of personal data is the respective statutory retention period. After expiration of that period, the corresponding data is routinely deleted, as long as it is no longer necessary for the fulfillment of the contract or the initiation of a contract.


<b>11. PROVISION OF PERSONAL DATA AS STATUTORY OR CONTRACTUAL REQUIREMENT; REQUIREMENT NECESSARY TO ENTER INTO A CONTRACT; OBLIGATION OF THE DATA SUBJECT TO PROVIDE THE PERSONAL DATA; POSSIBLE CONSEQUENCES OF FAILURE TO PROVIDE SUCH DATA</b>

We clarify that the provision of personal data is partly required by law (e.g. tax regulations) or can also result from contractual provisions (e.g. information on the contractual partner). Sometimes it may be necessary to conclude a contract that the data subject provides us with personal data, which must subsequently be processed by us. The data subject is, for example, obliged to provide us with personal data when our company signs a contract with him or her. The non-provision of the personal data would have the consequence that the contract with the data subject could not be concluded. Before personal data is provided by the data subject, the data subject must contact any employee. The employee clarifies to the data subject whether the provision of the personal data is required by law or contract or is necessary for the conclusion of the contract, whether there is an obligation to provide the personal data and the consequences of non-provision of the personal data.


<b>12. EXISTENCE OF AUTOMATED DECISION-MAKING</b>

As a responsible company, we do not use automatic decision-making or profiling.
Developed by the specialists for LegalTech at Willing & Able that also developed the system for data impact assessment. The legal texts contained in our privacy policy generator have been provided and published by Prof. Dr. h.c. Heiko Jonny Maniero from the German Association for Data Protection and Christian Solmecke from WBS law.
</div>`]
        }
    ]
};
export const pages = {
    "en": [
        {
            title: "On Bearing and Carrying, or Five Attempts at Unlearning the Killer Story",
            lang: "en",
            id: "killer_story",
            author: "Sibylle Schmidt",
            bg: {
                start_col: "white",
                col: "#006e6980",
                end_col: "white",
                type: "gradient",
                elements: [
                    {
                        type: "perlin",
                        text: "••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••",
                        s: 0.3,
                        h: 2,
                        x: 0.3,
                        y: 0.3
                    }
                ]
            },
            subpages: [
                {
                    title: "1. Gravity",
                    content: [
                        `
As I write, I am carrying billions of microorganisms in and on my body. Bacteria, fungi, and viruses populate my hands, skin, oral cavity, and the inside of my intestines. They say the microbiome in a human body can weigh up to two whole kilos. 

Inside of me, I carry another living body, which presses its hands and feet against the inside of my abdominal wall. I think about the German word trächtig, meaning pregnant, which comes from the verb <i>tragen</i>, to carry. It sounds so repulsive and is only used for animals. The technical term “gravid” sounds a little better. It also refers to a state of carrying, as it resonates <i>gravitas</i>, the force of gravity.

In a non-mechanical sense, rather figuratively or metonymically, I am a bearer of knowledge and information, blind spots and prejudices, ideas – some of them clear and neat like freshly folded sheets, others vaguer and more tangled, floating like loose threads on the edge of my consciousness. I carry memories of my childhood, impressions of important events, but also of trivial details that I have put to memory for whatever reason, like the plush, pastel-green toilet seat cover at a party I attended in my early twenties. I carry physical traces of past events: On my skin I bear scars – the small, jagged semi-circle on my knee, a horizontal line across my lower abdomen. Much of what I carry I am not aware of, but it shapes my movements, my thinking, my feelings.

I bear responsibility and care for people, animals, and plants that live with me or whose lives are entwined with mine. And they must bear me too. We carry people we love with us in our arms, in our thoughts. Carrying is caring – it’s an expression of connection, support, and love, even beyond life. Think of how we carry the deceased to the grave.

Physics says that weight, unlike the mass of a body, depends on the where it is located. Weight indicates how strongly a body pushes on a support or pulls on a suspension. To put it otherwise, something has weight for whomever carries it. Something is of weight for me.

I carry all these things with me as I write, but why do I think they have no bearing on my writing?

`,
                        `<#image>3_1.jpg</#image>
<h5><#c>Image: Kat Válastur</#c></h5>`
                    ]
                }, {
                    title: "2. Canon",
                    content: [
                        `
In 1979, in Woman’s Creation: Sexual Evolution and the Shaping of Society, the anthropologist Elizabeth Fisher laid out a feminist theory of the evolution of humanity.<#^>1</#^> Her thesis: the first tool that humanity used was not, for example, a sharpened weapon – a knife or spear or the like – but a receptacle that enabled people to bring food home from where it was found. 

The idea of the carrier bag as a central cultural device excited the science-fiction author and essayist Ursula Le Guin. In her 1986 piece <i>The Carrier Bag Theory of Fiction</i><#^>2</#^>, she wrote that if, in order to be considered a human being, it was necessary to make a weapon and kill with it, then she was “either extremely defective as a human being, or not human at all.”<#^>3</#^> But if being human meant putting something useful, edible, or beautiful in a bag and carrying it home, where it could be stored or shared or put in a medicine bundle, then she was a human being “fully, freely, gladly, for the first time.”<#^>4</#^>

Le Guin argues that we need to learn to let go of the killer story, which has reached its end and threatens to pull us into the abyss along with it. This is why Le Guin seeks, “with a certain feeling of urgency […] the nature, subject, words of the other story, the untold one, the life story.”<#^>5</#^>

In 2019, philosopher and historian of science Donna Haraway pulled out the Carrier Bag Theory of Fiction and reframed it. In the age of climate change and declining biodiversity, Haraway likewise seeks out counter-stories that free us from the myth of the human being as hunter, hero, and killer. For Haraway, storytelling is more than fiction. It means fighting the thoughtlessness with which we enact a script that other people have invented for us. “It matters what thoughts think thoughts. It matters what knowledges know knowledges. It matters what relations relate relations. It matters what worlds world worlds. It matters what stories tell stories.”<#^>6</#^>

Fisher – Le Guin – Haraway: three nodes in a network of women thinkers, three authors who carry each other’s ideas in their bags (or, in this case, their books) are smuggling them across the ages, pulling them out as found objects that were almost forgotten, sharing them, and connecting them. The carrier bag becomes a tool for the writing woman, a feminist epistemic practice, and a trick used by underdogs, outsiders, and subalterns to be assertive in the face of the repressive effects of the canon. Donna Haraway emphasizes how important it is to think in networks when it comes to salvaging and preserving the ideas of women authors:   

<#t>Women have repeatedly understood that not being disappeared by the powerful apparatus of masculinist thinking, of masculinist practices both in institutions and at the level of individual people – that our thinking is disappeared fast. And I think one of the feminist practices is deliberately and carefully to be very precise about the history of ideas and the particular creativity and originality and importance of other women’s thinking.<#^>7</#^>
</#t>
On the etymology of the word <i>Kanon</i> (canon), the digital dictionary of the German language (Digitales Wörterbuch der deutschen Sprache) has the following entry:

“<i>Kanon</i>, from Greek kanṓn (κανών), meaning ‘rule, stipulation, list, catalog, inventory of classical writers,’ but also ‘straight rod, straightedge, ruler.’”<#^>8</#^>

Is it surprising that we once again find ourselves faced with the staff, the phallic tool, Haraway’s “prick tales”?
`
                    ]
                }, {
                    title: "3. Division of Labor",
                    content: [
                        `
Humans are not the only creatures who use bags. Rodents stuff their cheeks with nuts to transport them to their dens, male seahorses store the fertilized eggs of their partners in a pocket on their stomachs, and marsupials carry their young in their own pouches. Plants invented the principle of the pouch by developing the most ingenious ones, which allowed their seeds to be carried as far as possible by the wind, animals, and humans. Before humans discovered the carrier bag as a tool, it was first and foremost an organ of reproduction. 

When Ursula Le Guin straps the baby Oo Oo to her back in a bundle in 1986, in order to free up both hands to pick oats, and passes the little Oom a basket to gather forage, she does something that expresses a relation with other, non-human earthly life-forms: she ensures that life goes on, she carries out reproductive labour, “and then next day [she] probably [does] much the same again.”<#^>9</#^>

This calm and frugality with which Le Guin moves across the field, carrying her bag, making herself into a carrier bag, triggers a deep sense of unease in me. Is this not the old-fashioned cliché of the woman as mother, consoler, provider of nourishment – as vessel? Would this woman not prefer to go hunting (an activity she might be avoiding purely because her children are still young, and she has not yet found anybody to look after them)? Would she not also occasionally like to free herself of the burden of having to be the provider, to spend some time alone at her desk, in a room of her own, as Virginia Woolf demanded? And would it not be understandable if she – at least in a small, secret niche of her consciousness – were to feel regret that she does not get to play the role of the hero in a story? 

The Western tradition of thought teaches us that reproductive labor and art are mutually exclusive – that in order to be able to produce and appreciate art, one needs to be free of the daily drudgery of procuring nourishment, of collecting, carrying, and caring. According to this viewpoint, the prerequisite of the aesthetic realm is “being relieved of urgent practical tasks, abandoning (turning one’s attention away from) <i>ananké</i> (necessity).”<#^>10</#^> This conception runs from the birth of aesthetics and into the present day like a mantra, from Kant’s concept of the “disinterestedness” of aesthetic judgment to Schiller’s “free play” that makes humans human. “The last word of aesthetics is human freedom,”<#^>11</#^> claims Christoph Menke. The last thing you would want to be disturbed by is something utterly profane, like a screaming child. 

Though productive labor relies on reproductive labor – after all, somebody has to tend to upkeep, nourishment, education, consolation – the responsibilities of the latter are borne. The work of bearing and raising children is carried out invisibly and silently in the private sphere. Hannah Arendt, who paid significant attention to reproductive labor in her book <i>The Human Condition</i>, leaves no doubt that this realm is strictly separated from the higher spheres of human activity, particularly from art. She says “moreover, the proper intercourse with a work of art is certainly not ‘using’ it [...] it must be removed from the exigencies and wants of daily life, with which it has less contact than any other thing.”<#^>12</#^> No contact – as if art would be contaminated and sullied were it to encounter bearing, carrying, foraging, or nourishing. 

Arendt was entirely aware of the social implications of this strict division. “Because men were subjected to the necessities of life, they could win their freedom only through the subjugation of those whom they forced to bear life’s necessities in their stead.”<#^>13</#^> The rigid separation of production and reproduction makes it necessary to invent corresponding categories for people as well. The classical idea of art and philosophy being activities of free people made it “necessary to possess slaves because of the ‘slavish’ nature of all occupations that served the needs for the maintenance of life.”<#^>14</#^> The flip side of culture is colonialism. 

“The trouble is, we’ve all let ourselves become part of the killer story, and so we may get finished along with it.”<#^>15</#^>

It makes me think of all the delivery people who carry clothes, food, and other stuff through the streets for us – and all the other beings who bear burdens for us, who are invisible and inaudible in such ghostly ways. Perhaps the search for the other, untold story, the life story, means asking what would be needed for these persons to be able to tell their story? 
`,
                        `<#image>3_2.jpg</#image>
<h5><#c>Image: Kat Válastur</#c></h5>`
                    ]
                }, {
                    title: "4. Heroines",
                    content: [
                        `
“I take the first knife and stab in between the fingers of my left hand as fast as possible. Every time I cut myself, I change the knife. When I’ve used all of the knives (all of the rhythms), I rewind the tape recorder. I listen to the tape recording of the first part of the performance. I concentrate. I repeat the first part of the performance. I take the knives in the same order, follow the same order, follow the same rhythm, and cut myself in the same places.”
<#r>Marina Abramović<#^>16</#^></#r>

“I really do believe that by cutting back, the roots grow stronger.” 
<#r>Tracey Emin<#^>17</#^></#r>


I find myself wondering, if we want to tell other stories, do we not also need to learn to tell other stories about storytelling? About the woman artist, scholar, or thinker? 

In art, ecological themes are being reflected upon more than ever. The texts of authors such as Haraway and Le Guin are all the rage among artists and cultural producers. At the same time, the way we imagine artists and intellectuals is still strongly shaped by the myth of the hero that Haraway and Le Guin critique: the lonely genius who is utterly dedicated to one thing, pursuing his project with focus, making it his utmost priority. He is reminiscent of the hunter in Le Guin’s story, he who focuses and slays the mammoth, the hero, the killer. Women, especially those with care duties, are still more the exception than the norm when it comes to creative, intellectual, and artistically productive people. Beyond being a cliché, this is also manifested in the economic structures of the art world. Funding programs demand a high level of flexibility and availability. Residencies are intended for individuals. Care work is viewed as an individual problem, and the responsibility for solving this problem (or not solving it, as it were), is borne primarily by mothers. This is why children continue to be viewed as “art-career killers.”<#^>18</#^> As Tracey Emin once put it, “there are good artists that have children. Of course there are. They are called men.”<#^>19</#^>

“I had experienced absolute freedom,” recalled Marina Abramović about her 1973 performance <i>Rhythm 10</i>, which she fittingly calls “Knife Play.” “I had felt that my body was without boundaries, limitless; that pain didn’t matter, that nothing mattered at all – and it intoxicated me.”<#^>20</#^>

In the six-hour-long <i>Rhythm 0</i>, performed a year later, Abramović becomes an object to the audience. In total passivity, she allows the visitors to carry her around like a doll, to paint her, position her in obscene poses, poke her with needles, and score her skin with knives, until finally a man presses a loaded pistol to her neck and places his finger on the trigger. 
Abramović embodies the figure of the lonely, ingenious heroine like perhaps no other woman artist. And yet, entangled in the hero’s story, which was invented for men and by men, the heroine finds herself on the wrong side of the blade: a martyr, victim, staring death in the face. 

What might it mean to tell a different story about ourselves, in which we do not have to break through walls? A story in which our existence is not a series of obstacles to be overcome? The body is not limitless, and we must learn to make do with finite resources. Pain matters, as do the things we carry with us. They shift one’s focus, one’s movement. What would it mean to not see this not as a burden or deficit, rather as a resource for art and thinking? 

In an interview with the group Cultural ReProducers, the dancer and choreographer Faye Lim remarks on her artistic work with her son that: 

<#t>I experience dance differently now, after becoming a parent. Time and space and energy-wise, certain restrictions closing in on me. But moving around with him, improvising with him helped me to…play, I suppose, with that feeling of time and space closing in. I’ve been getting really a lot more curious and paying a lot more attention to how children and different caregivers move and work with their bodies. <#^>21</#^>
</#t>
In her work, British artist Lenka Clayton also addresses questions of connection and motherhood. In her video series <i>The Distance I Can Be From My Son</i>, she measures the longest distance that she can bear to be from her son in different settings.<#^>22</#^> The “objective” number in yards and meters is delivered with a dose of irony, but what stands out in these videos is how this existential dimension of human connection has scarcely been measured and mapped out in the field of art. For a long time, motherhood in art meant the image of the Madonna, depicted from the perspective of men.<#^>23</#^>

Clayton’s work was produced during a residency named “Artist Residency in Motherhood” (ARiM), another one of her art projects that was sparked by her dissatisfaction with her own situation as an artist and mother:

<#t>I realised that many of the structures that are central to my profession – such as artist residencies – were suddenly no longer available to me as the primary care-giver of two small children. That was the moment I decided that I would just integrate all of the usual support of a residency into my life at home. That would allow me to see this early phase of parenthood as a productive, creative time, instead of as a situation that impedes my work.<#^>24</#^>
</#t>
Clayton wrote a manifesto, made a website to publish her work, and applied for research grants to fund childcare for two days a week. She engaged friends as mentors. She worked during the brief spans of time in which her children were sleeping. She produced 32 works over a three-year period. 

Clayton seems to have hit a nerve; she received a huge number of enquiries from artists with children who wanted to apply for an ARiM. It led Clayton to launch the website <#link>www.artistresidencyinmotherhood.com</#link><#^>25</#^>. Anybody interested in engaging in such an artist residency can find a DIY “residency kit” with a manifesto you fill out like a form, plan-making tools, and other information. There is no application, participation is free, and you have the feeling that you are part of a community of supportive people. A map shows the number of current and past “artists in residence in motherhood,” of which there are over 1200 across 74 different countries.<#^>26</#^>

“All of us have to learn how to invent our lives, make them up, imagine them. We need to be taught these skills; we need guides to show us how. If we don’t, our lives get made up for us by other people,”<#^>27</#^> writes Le Guin. But how do we become authors of our own lives? Perhaps the trick is that it has to be a shared process; we can only lend each other the ability to tell a different story together. Like playing string figures, which in Donna Haraway’s work functions as a symbol for collective thinking and becoming, “passing patterns back and forth, giving and receiving, patterning, holding the unasked-for pattern in one’s hands, response-ability.”<#^>28</#^>

Clayton provides just such a model for all those who want to reconcile parenthood and artistic practice. Her "Artist's Statement" is both a personal testimony and a reproducible document to be copied and shared:

<i>“Artist’s Statement
[…] Despite a legacy of public artist/parents it still seems to be a commonly held belief that being an engaged mother and serious artist are mutually exclusive endeavors. I don’t believe or want to perpetrate this. I like to imagine the two roles not as competing directions but to view them, force them gently if necessary, to inform one another. 
I will undergo this self-imposed artist residency in order to fully experience and explore the fragmented focus, nap-length studio time, limited movement and resources and general upheaval that parenthood brings and allow it to shape the direction of my work, rather than try to work ‘despite’ it.”<#^>29</#^></i>
`
                    ]
                }, {
                    title: "5. Gleaning",
                    content: [
                        `In her film <i>The Gleaners & I</i> (Les glaneurs et la glaneuse)<#^>30</#^>, Agnes Varda points her camera at people who operate on the periphery of the action: after the harvest, on the side of the road at night, at the fruit and vegetable market before or after opening hours. Hunched over, carrying all manner of bags and boxes, they keep watch for leftover things that are still edible, usable, or beautiful. 

Collecting the fruit left behind after the harvest used to be common – the famous painting by Jean-François Millet, <i>The Gleaners</i><#^>31</#^> depicts it. Today’s gleaners operate in a legal gray zone. While collecting left-over fruit and vegetables is tolerated by some property owners, ‘dumpster diving’ behind supermarkets or taking bulk refuse left on the street is forbidden in Germany and can be punishable by fines.<#^>32</#^>

The “Gleaners”<#^>33</#^> are often marginalized – homeless, vagrant bricoleurs who rummage through the leftovers of our single-use society. However, Varda is not filming a report on social issues. The protagonists whose stories she tells (rather, to whom she gives the opportunity to speak), are not collecting purely out of need. They also exude an indignant joy at the pleasure of collecting, repairing, recycling. “There’s plenty,” says a man named Salomon, while presenting disposed-of fish filets, cheese, and prawns to the camera. “Do you need something?”<#^>34</#^> The viewer marvels at the skill with which old fridges are gotten back up and running, and at artworks made from the found materials. Amongst the derelict and the dilapidated, Varda manages to unveil a kind of beauty, a luster. For example, the camera accompanies a man who eats leftovers from the fruit and vegetable market, sells street newspapers by day, and by night gives literacy courses as a volunteer at a home for refugees.

The <i>glaneuse</i> of the French title is the filmmaker herself. Tracking down the gleaners, with her handheld digital camera, she rummages through images and stories, fascinated by the aesthetics of remains, while maintaining an awareness of her response-ability. “I like filming rot, leftovers, waste, mold and trash. But I never forget those who do their shopping in the leftovers and trash when the market is over.”<#^>35</#^>

The film is also a form of reflection on her own artistic practice. Understanding herself as a gleaner, Varda resists the narrative of the artist as a creator and producer of the new who satisfies the audience’s <i>neugier</i> (the German word for “curiosity” translates literally as a <i>greed</i> or <i>desire</i> for the <i>new</i>), and in so doing refuses to mimic the production cycles of commercial enterprise. Instead, she directs her gaze toward the refuse produced by industry,  its excess, and those who live off of it – those spat out by the system and pushed into the margins, or those deliberately withdrawn. 

Is an ecological aesthetic become tangible? This kind of art, and film, joins the activities of collecting, carrying, and repairing. It has little in common with the mainstream understanding of art as essential creativity, the production of something brand new or utopic. Creativity carries characteristics of destruction. The genius artist who designs a new world according to his ideas despises the world that is already there. However, Varda's film demonstrates that art can be a key to achieving a keen perception and appreciation of the existing world. One scene in the film expresses this particularly clearly: Varda, camera in hand, walks across the field with a carrier bag and collects heart-shaped potatoes. The art is that of the gatherer, motivated to look around, to rummage, to perceive and preserve what is already there – like the loose thread of an untold story. 
`,
                        `<#image>3_3.jpg</#image>
<h5><#c>Image: Video still from “The Gleaners and I” by Agnès Varda</#c></h5>`,
                        `<h4 style="text-align: left; font-weight: inherit; margin: 0;">NOTES</h4><#note><#nn>1.</#nn><#nt>Fisher, Elizabeth (1979):  <i>Woman’s </i> <i>Creation: </i> <i>Sexual </i> <i>Evolution </i> <i>and </i> <i>the </i> <i>Shaping </i> <i>of </i> <i>Society </i>(New York: Mc Graw-Hill).</#nt></#note>
<#note><#nn>2.</#nn><#nt> Le Guin, Ursula (1986):  <i>The </i> <i>Carrier </i> <i>Bag </i> <i>Theory </i> <i>of </i>Fiction, available at: <#link>https://theanarchistlibrary.org/library/ursula-k-le-guin-the-carrier-bag-theory-of-fiction</#link>, last accessed: 25/3/2022.</#nt></#note>
<#note><#nn>3.</#nn><#nt> Ibid.</#nt></#note>
<#note><#nn>4.</#nn><#nt> Ibid.</#nt></#note>
<#note><#nn>5.</#nn><#nt> Ibid.</#nt></#note>
<#note><#nn>6.</#nn><#nt> Haraway, Donna (2016):  <i>Staying </i> <i>with </i> <i>the </i> <i>Trouble: </i> <i>Making </i> <i>Kin </i> <i>in </i> <i>the </i>Chthulucene (Durham: Duke University Press), p. 35.</#nt></#note>
<#note><#nn>7.</#nn><#nt>Terranova, Fabrizio (2016):  <i>Donna </i> <i>Haraway: </i> <i>Storytelling </i> <i>for </i> <i>Earthly </i>Survival (Brussels: Atelier Graphoui et al.), 00:34:15-00:35:10.</#nt></#note>
<#note><#nn>8.</#nn><#nt> From: <#link>https://www.dwds.de/wb/Kanon</#link>, last accessed: 25/3/2022.</#nt></#note>
<#note><#nn>9.</#nn><#nt> Le Guin,  <i>The </i> <i>Carrier </i> <i>Bag </i> <i>Theory </i> <i>of </i>Fiction.</#nt></#note>
<#note><#nn>10.</#nn><#nt>Thomä, Dieter (2012), “Ästhetische Freieheit zwischen Kreativität und Ekstase: Überlegungen zum Spannungsverhältnis zwischen Ästhetik und Ökonomik,” in <i> </i> <i>Kreation </i> <i>und </i> <i>Depression: </i> <i>Freiheit </i> <i>im </i> <i>gegenwärtigen </i>Kapitalismus, eds. Christoph Menke and Juliane Rebentisch (Berlin: Kadmos), pp. 149–171, here p. 151.</#nt></#note>
<#note><#nn>11.</#nn><#nt> Menke, Christoph (2008),  <i>Kraft: </i> <i>Ein </i> <i>Grundbegriff </i> <i>der </i> <i>ästhetischen </i> <i>Anthropologie </i>(Franfurt a.M.: Suhrkamp).</#nt></#note>
<#note><#nn>12.</#nn><#nt> Arendt, Hannah (1998):  <i>The </i> <i>Human </i>Condition. 2nd edition (Chicago: University of Chicago Press), p. 167.</#nt></#note>
<#note><#nn>13.</#nn><#nt> Arendt,  <i>Human </i>Condition, pp. 83–84.</#nt></#note>
<#note><#nn>14.</#nn><#nt> Arendt,  <i>Human </i>Condition, p. 83.</#nt></#note>
<#note><#nn>15.</#nn><#nt> Le Guin,  <i>The </i> <i>Carrier </i> <i>Bag </i> <i>Theory </i> <i>of </i>Fiction, p. 33.</#nt></#note>
<#note><#nn>16.</#nn><#nt> Abramović, Marina (2010):  <i>Marina </i> <i>Abramović: </i> <i>The </i> <i>Artist </i> <i>is </i>Present (New York: The Museum of Modern Art), p. 60.</#nt></#note>
<#note><#nn>17.</#nn><#nt> Emin, Tracey (27.5.2005):  <i>My </i> <i>Life </i> <i>as </i> <i>a </i>Column, in: The Independent <#link>https://www.independent.co.uk/voices/columnists/tracey-emin/tracey-emin-my-life-as-a-column-5345508.html</#link>, last accessed: 27/06/22</#nt></#note>
<#note><#nn>18.</#nn><#nt> <i> </i> <i>Monopol </i>Magazin, no. 2 (2019), p. 43.</#nt></#note>
<#note><#nn>19.</#nn><#nt> Interview in  <i>The </i>Independent on 3/10/2014. </#nt></#note>
<#note><#nn>20.</#nn><#nt> Abramović, Marina, Kaplan, James (2016),  <i>Durch </i> <i>Mauern </i>Gehen. (München: Luchterhand), translated by Charlotte Breuer and Norbert Möllemann, p. 84. Retranslation by Sibylle Schmidt.</#nt></#note>
<#note><#nn>21.</#nn><#nt> <#link>https://www.culturalreproducers.org/2022/01/interview-faye-lim.html</#link>, last accessed: 25/3/2022.</#nt></#note>
<#note><#nn>22.</#nn><#nt> <#link>http://www.lenkaclayton.com/work#/the-distance-i-can-be-from-my-son/</#link>, last accessed: 25/3/2022.</#nt></#note>
<#note><#nn>23.</#nn><#nt> On this, see the exhibition Mutter! at Kunsthalle Mannheim (1/10/21–6/2/2022), whose press material tried to woo audiences with the fact that “works by artists such as Egon Schiele, Pablo Picasso, Edvard Munch, René Magritte and Otto Dix [were] on display.” Only after this was reference made to works by women artists.</#nt></#note>
<#note><#nn>24.</#nn><#nt>Wichmann, Natalie (18/01/2022):  <i>Mutterschaft </i> <i>und </i> <i>Kunst: </i> <i>Wie </i> <i>lässt </i> <i>sich </i> <i>das </i>vereinbaren? Interview in Schrin Mag,, available at: https://www.schirn.de/magazin/interviews/2022_interview/mutterschaft_und_kunst_wie_laesst_sich_das_vereinbaren/, last accessed: 25/3/2022.</#nt></#note>
<#note><#nn>25.</#nn><#nt>We could criticize the title of the project for the fact that the problem does not just affect mothers but all parents and people with care duties. However, studies show that women (and among women, mothers in particular) are disadvantaged in the art business. “Though more than half the students at art schools are women, more women have solo exhibitions or are represented in group exhibitions and in galleries, the number of successful men in the arts is significantly higher and the income of women artists continues to be significantly lower,” said Sabine Weier, in “Schieflage im Kunstbetrieb” published in  <i>taz </i>–  <i>die </i>tageszeitung, 12/9/2021. There was also a great deal of criticism, for example, when in 2020 Kunstfonds Bonn awarded the “Grant for Visual Artists with Children under Seven” to more men than women. Applications from women had made up 60 percent of the total submissions, 45 percent of positive responses were awarded to women (see Brigitte Werneburg, “Kunst und Care Arbeit”, in <i> </i> <i>taz </i> <i>– </i> <i>die </i> <i>Tageszeitung, </i>9/11/2020). As such, the most convincing argument for providing more support explicitly directed at mothers in the arts is the fact that for women with care duties, two disadvantageous factors compound each other: the burden of care work and the influence of misogyny.</#nt></#note> 
<#note><#nn>26.</#nn><#nt> <#link>http://www.artistresidencyinmotherhood.com/</#link> last accessed: 08/6/2022.</#nt></#note>
<#note><#nn>27.</#nn><#nt> Le Guin, Ursula (2004): “Operating Instructions”, in  <i>The </i> <i>Wave </i> <i>in </i> <i>the </i> <i>Mind: </i> <i>Talks </i> <i>and </i> <i>Essays </i> <i>on </i> <i>the </i> <i>Writer, </i> <i>the </i> <i>Reader, </i> <i>and </i> <i>the </i>Imagination (Boston: Shambala), p. 208.</#nt></#note>
<#note><#nn>28.</#nn><#nt> Haraway,  <i>Staying </i> <i>with </i> <i>the </i>Trouble, p. 12.</#nt></#note>
<#note><#nn>29.</#nn><#nt> The “Artist in Residency in Motherhood Manifesto” can be downloaded here: <#link>https://www.artistresidencyinmotherhood.com/kit</#link>, last accessed: 27/6/2022.</#nt></#note>
<#note><#nn>30.</#nn><#nt> Varda, Agnès (2000):  <i>Les </i> <i>Glaneurs </i> <i>et </i> <i>la </i>glaneuse (Paris: Ciné-Tamaris).</#nt></#note>
<#note><#nn>31.</#nn><#nt> Millet, Jean-Francois (1857):  <i>The </i> <i>Gleaners. </i>Musée d’Orsay, Paris.</#nt></#note>
<#note><#nn>32.</#nn><#nt> In Germany, containerising or attempting to fish food out of containers is considered theft under section 242(1) of the Criminal Code (StGb) or trespass under section 123(1) of the StGb and is punishable by imprisonment or a fine. In 2020, the Federal Constitutional Court confirmed the legality of this punishment, cf. "Containern ist strafbar", FAZ of 18.08.2020, https://www.faz.net/aktuell/wirtschaft/bundesverfassungsgericht-containern-ist-strafbar-16910090.html. (Last accessed: 27.06.2022)</#nt></#note>
<#note><#nn>33.</#nn><#nt> See original film title.</#nt></#note>
<#note><#nn>34.</#nn><#nt> Varda, 2000, 1:02:15-1:02:18.</#nt></#note>
<#note><#nn>35.</#nn><#nt> Varda, 2000, 1:13:20-1:13:43.</#nt></#note>
`
                    ]
                },
            ]
        },
        planetary_feminism,
        {
            title: "Staged Talk. An exchange between dance practitioners in Berlin",
            lang: "en",
            id: "staged_talk",
            author: "",
            bg: {
                start_col: "white",
                col: "#ff556980",
                end_col: "white",
                type: "gradient"
            },
            content: [
                `On January 25th 2022, twelve professionals in the Berlin dance scene were invited to a staged exchange in which reality and fiction could coexist. Based on an imaginative approach that conceives of the Berlin dance scene as an ecosystem, in which every small interaction between living beings and their environment has a ripple effect that spreads across the planet, the invited choreographers debated important topics and politics of the Berlin dance scene. Before the talk, the artists created a handmade mask of the animal spirit they wanted to embody, in a ritualized process. The talk was moderated by Margarita Tsomou and Olympia Bukkakis and the mask-making process was a collaboration between costume designer Caroline Schogs and the invited choreographers.

*An ecosystem, a community of interacting organisms and their environments, where different species, living in the same location, work together to remain alive and healthy…`,
                `<i>Dear…..
 
Imagine a dance community in Berlin as an ecosystem in which every small interaction between living beings and their environments has a ripple effect and spreads across the planet in one way or another. For any ecosystem to work properly, all the different elements that compose it need to work together in complex intertwined relationships.
 
We are shaped in relationships, and as we are different, we have different needs. We would like to hear your opinions, and how these opinions relate, support, and differ from others about topics that concern us all.
 
“Difference must be not merely tolerated, but seen as a fund of necessary polarities between which our creativity can spark like a dialectic. Only then the necessity of interdependence becomes unthreatening. Only within that interdependence of different strengths, acknowledged as equal, can the power to seek new ways of being in the world be generated, as well as the courage and sustenance to act without charter.
 
With the interdependence of mutual (nondominant) differences lies the security that enables us to descend into the chaos of knowledge and return with true visions of our future, along with the concomitant power to effect changes that can bring that future into being. “Difference is that raw and powerful connection from which personal power is forged,” said Audre Lorde. (The master's tool will never dismantle the master's house.)
 
We invite you to become a semi-fictional character: an in-between species in an in-between situation, where reality and fiction mix. We invite you to an exchange and discussion behind theriomorphic masks that will reveal other sides of your identity and establish a voice through which to speak freely and freshly about your opinions and needs. We also would like to raise questions and to imagine our future together. 
 
Shortly before the exchange, we invite you to a ritualized process that will allow you to find and create your animal spirit. We invite you to wear a handmade mask of your animal. Masks offer a possibility to transform the many phases of the human and can possess a healing force. We will use them to stimulate prosperity and ensure abundance in our ecosystem. You can decide how deep you connect and engage with it, and meet different animal spirits. We have invited professionals of the dance scene in Berlin, like you, to this encounter. Margarita Tsomou and  Olympia Bukkakis will host, guiding and facilitating the conversation. 
 
Together we want to create a space for honesty and playfulness, with a spark of  fiction while looking and imagining the future together. We can discuss and debate important topics/politics of the Berlin contemporary dance scene, including past, present, and future situations and needs.
 
The topics we would like to discuss together are the following: FUNDING - SPACES - WORKING CONDITIONS - COMMUNITY - AUDIENCES - SUSTAINABILITY - NETWORKING - FUTURE OF THE SCENE. We are curious about your thoughts on them. Any further topics and ideas you would like to bring are very welcome.

DATE: January 25th 2022      
PLACE: Betahaus, Rudi-Dutschke-Straße 23 10969 Berlin <#link>https://goo.gl/maps/BSzzZsL26Cu1rQGTA</#link>
Close to U-Bahn Kochstrasse 
TIME: 11-17h
 
We are looking forward to the exchange!</i>`,
                `<#c><#vim_video>722510993</#vim_video></#c>`,
                `<#c><#vim_video>722512041</#vim_video></#c>`,
                `<h4><#c>CHOREOGRAPHERS</#c></h4><#c>Angela Alves, Antonia Baehr, Elpida Orfanidou, Helena Waldmann, Joy Alpuerto Ritter, Jule Flierl, Kareth Schaffer, Layton Lachman, Ligia Lewis, Lee Méir,  Magda Korsinsky, and Yvonne Semebene</#c>
<h4><#c>MODERATORS</#c></h4><#c>Margarita Tsomou and Olympia Bukkakis</#c>
<h4><#c>MASKS AND MASK PROCESS</#c></h4><#c>Caroline Schogs</#c>
<h4><#c>CAMERA AND EDITION</#c></h4><#c>Ethan Folk and Andrea Schmidt</#c>`
            ],
            subpages: []
        },
        ashes_of_anita_berber,
        {
            title: "Anita Berber: We Are at War",
            lang: "en",
            id: "we_are_at_war",
            author: "Lea Marie Uría",
            bg: {
                start_col: "white",
                col: "#55555580",
                end_col: "white",
                type: "gradient",
                elements: [
                // {
                //     type: "perlin",
                //     text: "•••••••••••••••••••••••••••••••••••••                                                                                                                                            ",
                //     s:1,
                //     h:2,
                //     x:0,
                //     y:0
                // }
                ]
            },
            content: [
                `<#image>7_4.jpg</#image>
<h5><#c>Image: Alexander Binder</#c></h5>`,
                `Is there such a thing as a dance of war? How does a body that is drenched in violence dance? If there were a dance of war, could this dance be a way of avoiding pain? Would it be a kind of ‘healthy purging’ of rage? Or, like waging war against yourself, would it be an act of self-harm, a sort of self-sacrifice? When does a war against yourself become a war against others? 

The setting: Berlin in the early 1920s. Anita Berber is dancing in a cabaret. It is a tour de force, in every sense of the word. The audience, mostly men, insult her incessantly. She reacts not only with words and dance but, at one point, with her fists. When she eventually can’t take it any more, she stops dancing and smashes a bottle over a guy’s head who had been slagging her off. What you see – I assume, because I can only read about it or watch Berber’s brief solos on the internet – is a sort of punk concert, but with some crucial differences. 
`,
                `<#image>7_3.jpg</#image>
<h5><#c>Image: Alexander Binder and Leon Eixenberger</#c></h5>`,
                `Instead of a rock band on stage, there’s a woman in her twenties receiving insults. Instead of there being men on stage, they are only to be found before it, but they are still the majority. It’s one woman against many men. Suddenly it’s not (just) a pioneering punk performance. It’s an inquisition. 

Maybe everyone likes to see someone in pain, someone in an apparent war against themselves. But then they get caught in an unexpected dynamic: the men hate themselves for enjoying the supposed (self-)destruction or self-flagellation of a human being. Or they don’t, and they just enjoy it. In a situation in which, if the martyr had been a man, they would have founded a religion, but since it’s a woman, they burn the witch.

Because they ask themselves what if this person (this woman, this dancer, Anita Berber) is faking her own collapse? What if this ‘little game’ can shake the foundations of society? Well, there you go. You wanted to have fun and now you have to face your demons, just like the artist wanted. Like Anita wanted. The men are attracted and repelled at the same time, and they hate her for it. And she enjoys being hated just as much as she hates them. The attackers don’t realise it, but they are being exposed. Anita reveals that, in the end, they are only humiliating (or destroying) themselves. 

I am against genealogies of violence. I am against finding an earlier event and claiming that the darkest, most terrible and cruel tragedy in human history actually started much earlier than we thought. Because then we get close to saying that this specific violence is actually the same thing that has always happened. History certainly has continuity, but it also has upheavals and ruptures, which ought to be recognised as such. That’s why it’s good to connect the upheavals with each other instead of relativising them. 

There is something striking that connects Germany and Japan: a ‘revolution dictated from above.’ Does such a thing exist? Or should we rather speak of a wave of heightened repression? What Marx didn’t see coming was that industrialization got activated by a medieval empire. Peasants became factory workers and then became soldiers, at the will of the emperor, overnight. Oppression, displacement, violence in and on the body, capital accumulation, and destruction grew to colossal extent.

What Marx did see coming was that the people would have to act like machines, without thinking of feeling. How does this invasion feel in the body? How can bodies free themselves from this violence? What happens if the solution dictated from above – war – doesn’t work? It is a completely stupid solution, to say the least, because it leads to escalation, it becomes endless, it leads to death. 

The question I really want to ask, because it has been silenced, is this: what exactly is the effect of all this on women’s bodies? The peasant woman becomes a worker and a mother of many, because this is what is wanted. She must renounce her body and ‘produce’ more bodies, who in turn will bear and sustain the oppression of this brutalising system. 

But then, all of a sudden, a body dances. A body dares to dance in this capital, Berlin, where some other bodies are trying to get out. This body finds liberation, to be sure, but it is marginalised. The weight is too much for one body. The punishment is too great. 

She is a symbol of the decadence of the Weimar Republic – that failed interwar attempt to establish a republican system in Germany. She is a kind of expressionist character from a Chaplinesque comedy in the Roaring Twenties. A woman who embodies a historical failure. What an exaggerated, cruel portrayal! Anita would flee from that patronising harmlessness that guaranteed her a place in heaven. Her body had to wage an uneven battle and was punished, but not defeated. What kind of decadence are we talking about?

When I was a child in Lomas de Zamora, a city in the province of Buenos Aires, I was friends with a girl from my block, a neighbour, who went to the German school. One time, she invited me to her birthday party. Her classmates were there too. They had totally different games than the ones at my school, a '<i>conventional</i> (said with all the irony I can manage) Catholic' school. A boys’ school, with me, a trans woman. But that’s another story. Or not. It isn’t.`,
                `<#image>7_2.jpg</#image>
<h5><#c>Image: Ixchel Mendoza Hernández and Leon Eixenberger</#c></h5>`,
                `One of those unfamiliar games was jumping rope. They tried to include me in the game, I tried to jump, but failed miserably at the first attempt. But I accepted my failure without shame, and I kept trying by myself... trying and failing. The last attempt was just a few months ago and I confirmed what I had expected: I still can’t jump rope. It doesn’t matter, I’ll keep trying until I can. Or until I fail again. Back then, however, I enjoyed this new and unfamiliar spectacle. 

There were different ways of exerting force on the rope while one of your friends was jumping. One of these ways was called <i>picante</i> (or ‘spicy’), which meant ‘really strong.’ The kids counted out a sequence of descending numbers, if I remember correctly. The countdown was repeated as the movement of the rope intensified, until the moment came when the person could no longer jump. Then the jumper had to move away and was excluded for a round or more, as long as it took them to recover from the effort. 

Exclusion was considered a punishment, so to speak. The excluded players had to go “to Berlin.” They would say this explicitly. Every excluded child had to go “to Berlin” at a certain point. As a child growing up in the suburbs of Buenos Aires, that was certainly the first time, or one of the first times, that I heard the word Berlin. What I didn’t realise at the time was that I had (also) been in Berlin from the beginning. Since I could not participate in the game, I was already punished, excluded, “in Berlin.”
 
As I said, I did not go to the German school. I learned German as an adult and have 'always' been in Berlin. In some way, I migrated to Berlin back when I was a little girl, on a parallel level of reality, on the exact day when my neighbour celebrated her birthday. That is, many years before my actual migration in 2012. Does this mean that Berlin is a mother to those who don’t fit in, those who were excluded or feel excluded? I suspect that this is sometimes the case, even the rents in this city is getting more expensive every day. Is Anita Berber the mother of the excluded? Must I come here, as part of a ritual, in order to become a mother myself? Has the city become more hostile, making it worthwhile to talk more and more about Anita? Or does the implicit agreement between Berlin and the marginalised carry on? 

The first dance piece I ever saw was called “La Estrategia de la Pecera” (The Strategy of the Fishbowl). I had been invited to see it by a musician friend of mine, who played live in the piece. The theme was “We Are at War.” The group was called La Brizna and was led by the Argentinian butoh dancer Rhea Volij. The group’s dancers (one of whom became my first butoh dance teacher, Andrea Chama) walked through the dark corridors of IMPA, an aluminium factory rescued from bankruptcy by its workers, who had partly transformed it into a cultural centre. 

The dancers moved among the pieces of aluminium, among the machinery, defying a state of affairs aimed at forcing them to look at reality as if they were fish in a bowl. They were 'at war' with this apparatus. Sometimes war is felt when there is apparent peace, and sometimes – or, almost always – dance is the best way to act against war, to defend oneself so that the first attack never arrives. 

The images of these fish-women, these mermaids, accompanied in their dance by walking musicians, in the midst of this post-industrial apocalypse of the 1990s in Argentina, shortly before the great crisis exploded in 2001, had a very decisive impact on me. As I would later realise – the apocalypse would become our new nature, rather, an irreparably failed and broken operating system. 

Anita Berber is buried in a park in Berlin that is named after her. It used to be a cemetery, St. Thomas Cemetery, but the last burial took place in 1978. There is almost no sign of it anymore. It is impossible to find her grave. The exact spot where she was buried is unclear. It is also unclear whether there was a gravestone. Anita died on the 10th of November 1928. She was 29 years old. She was ill with tuberculosis, and an addiction to alcohol and drugs had left their mark on her body. 

I enter the park and see that some locals have gathered by the entrance to drink a beer. They block from my view the sign that explains who Anita was and why the park owes its name to her. That is to say, the only trace that alludes to her in this place is partially obscured by the people who gather here. In any case, I give a friendly wave and make my way to the sign. As I’m standing next to it taking pictures, a guy – balding, sixty-something with an earring – stands next to me and winks.  

Although I quickly get away, I think about what I’ve just learned from the sign: not only are there people in the park who seem to exist outside of the space of the city and seem to belong nowhere else but in this very park, but also 20 different species of birds visit this place. Perhaps this is another trace of Anita’s presence in this park of <i>rara avis</i> in which I also – strangely or not so strangely – feel at home.
 
Different eras mirror each other in a deformed way. Marx said that history repeats itself as tragedy or farce. There are eras in some countries that have complex and strange points of connection with those in other countries. And they probably go unnoticed at first glance, which is why I find it worthwhile to examine them in relation to each other. As if hyperinflation in Argentina – which reached peaks of almost 800% per month and eroded the goal of “eating, healing, and educating with democracy” set by Raúl Alfonsín, the first president after the bloodiest dictatorship in the country’s history – had been a little bit ‘Weimar.’

As if the image of my first butoh teacher, Andrea, an impromptu first responder among the rubble in the immediate aftermath of the bombing of the Jewish community centre AMIA, which left 85 dead and 100 wounded in the Once neighbourhood of Buenos Aires in the mid ’90s had also been in some way a little bit Weimar.

When I was seven years old, I had an operation for peritonitis. After more than two months in bed, a doctor asked me to try to walk again. I took the first step and fell flat on my face. I couldn’t walk, I had lost my muscle tone, I had to learn to walk again. But I soon forgot about the whole thing.

This scene only returned to me in the late ’90s, early 2000s. At that time, I was part of a punk band, and we rehearsed in the southern part of Greater Buenos Aires. We would record the rehearsals and, when we took a break, we would listen to the music we had recorded. And then we would dance to that music, almost without realising it. That unplanned and spontaneous dance opened up a new space of bodily awareness.
`,
                `<#image>7_1.jpg</#image>
<h5><#c>Image: Ixchel Mendoza Hernández and Alexander Binder</#c></h5>`,
                `It was then that I could start looking back: I realised that peritonitis was a kind of confirmation of the damage that the apparatus had done to my body. Not only had I lost my original capacity to walk, it was also clear that I was going to have to repress my femininity. I was going to have to keep quiet about the fact that I was a woman (back then a girl) in order to continue living. At least for a while...

In the era in which Anita was performing, there were female dancers who performed naked to emulate Greco-Roman statues. But Anita stripped her nudity of this meaning. It was deeply disturbing to see a vulva “for no reason” (ha, “for no reason,” just like that?!) in a public place. At least this was the argument of some of the (male) spectators who responded with outrage. One of whom wrote a <a style="color:var(--blu)" href='https://feiern-rausch.public-history.net/anita-berber-die-suendige-ikone-berlins/'>letter</a> to the police in 1926 denouncing Anita, stating that “the dancer undoubtedly endangers morals and violates our sense of modesty in an unheard of way.”

And to think that nowadays it’s not a big shock if women see a penis on the street or on our mobile phones. It has become naturalised. Another digression: I can’t help mentioning the performer who offers passengers on the Berlin metro badges featuring the “unsolicited dick pics” she has received over the years. 

Should we just get used to it and stop complaining? Are we really being asked to do this? Meanwhile, showing the vulva as part of an artistic performance in open defiance of public order seems (still?) unforgivable. 

I go from the rehearsals of my punk band to looking back on the hospital to taking dance classes in order to integrate (or recover) a little of what seemed to have been lost. I read in the newspaper about Esquizoonautas, Laura Veiga and Fabio Rizzo’s dance duo, and how they had recovered from their respective illnesses through dance. I tracked Fabio down and he invited me to the Angolan capoeira classes he was giving with Laura at the Rojas Cultural Centre in Buenos Aires. I had no idea what Angolan capoeira was. 

I attended anyway and became familiar with what Fabio himself (or mestre Cayó) called the capoeira of falling. If I fall, I get up with dignity, he would say, throwing himself on the floor and raising himself upright by passing one leg over his head without using his arms. Because we are always going to fall. What we have to do is learn to fall and get up. 

Another occasional element of Ezquizoonautas’ capoeira classes was to analyse and stage commonplaces in language, and come up with alternatives to deconstruct them. For example, does turning your back on someone mean turning around and leaving offended, or turning your back on someone else so they can, say, give you a massage? 

For me it is quite an effort to reconstruct what happened in those classes, and not only because they happened a long time ago, but also because Fabio fell ill again and did not recover, which makes me very sad even today (sometimes I tear up when I write), and because all this belongs to that moment before the 2001 crisis in Argentina. Once again, the dysfunctional apparatus scarred, sickened, and disciplined bodies. A process of dragging, of great loss, that reaches its breaking point and erupts. But these bodies had already experienced the joy of freeing themselves, if only for a moment, from the apparatus. 

As if the “plane of consistency” that artist Silvio Lang proposes when talking about the directing of a stage piece were applicable to our own life (which it is), to the staging of our own personal sphere. We no longer have any choice but to function as parts of a “sensitive war machine,” Lang says, like partisans in a civil war, “like the witch they couldn’t burn,” like architects of “an anti-fascist agenda.” To paraphrase Walter Benjamin: we counter the fascist aestheticization of war with the politicisation of art. Or, more precisely, with the politicisation of our bodies. It is Anita’s unbridled, desperate joy, using dance and words to defy the male audience and militaristic and patriarchal exploitation. It is Fabio standing up despite the chemo. It is that unstoppable joy I felt when I was finally able to begin my transition. Just like that, because if I fall, I get up with dignity.

In Buenos Aires – are we still in the late ’90s or are we already in the 2000s? – I went to see a play by Ko Murobushi, a butoh master from Japan. The theatre was a long way from my flat, but I still walked home alone. I was so shaken that I didn’t even say goodbye to people who were very dear to me, some of them fellow students from the IMPA butoh classes. Why would I talk? Was the performance supposed to represent something? Or was Ko just being on stage? And if he was just being, there is a residue or a sediment that cannot be expressed in words.

When I watch Anita dance, I partly feel the helplessness, the impossibility of speaking about it, that I experienced with butoh. What if these obscured feelings – untranslatable in words – are only an indication of the presence of a future dance? What if her dance was never actually interrupted? What if what really happened is that the energy was transformed into something else, but the dance continues? Could it be that the dance continues in things until its vibration is transferred to our bodies and makes them dance again? Could it be that we must be able to dance the dance of war again in order to be at peace? 

The song “Some Velvet Morning” comes to mind. <i>Flowers are the things we know, secrets are the things we grow. Learn from us very much, look at us but do not touch.</i>`
            ],
            subpages: []
        }, {
            title: "Dance stories",
            lang: "en",
            id: "dance_stories",
            bg: {
                start_col: "white",
                col: "#ffff6480",
                end_col: "white",
                type: "gradient",
                elements: [
                    {
                        type: "perlin",
                        text: "                                                          •                                                          ",
                        s: 1,
                        h: 2,
                        x: -0.4,
                        y: -0.4,
                        x_skew: 0.2
                    },
                    {
                        type: "perlin",
                        text: "                                                          •                                                          ",
                        s: 1,
                        h: 2,
                        x: -0.4,
                        y: -0.4,
                        x_skew: 0.2
                    },
                    {
                        type: "perlin",
                        text: "                                                          •                                                          ",
                        s: 1,
                        h: 2,
                        x: -0.4,
                        y: -0.4,
                        x_skew: 0.2
                    }
                ]
            },
            author: "",
            content: [
                `These are three audio-interviews with people who share their special relationship with dance. They give insight into the role of dance in the interviewees’ past, present and imagined future.`,
                `(Music: Hyewon Suk)


`
            ],
            subpages: [
                {
                    title: "Sabine",
                    content: [
                        `After the death of her husband and at the beginning of her retirement, Sabine became involved in a dance group for the elderly. In this talk, she shares how she found this space and what it means to her. In her words we hear the importance of dance as a social and physical practice before she became involved in this group, and how it is still relevant at this stage of her life.`,
                        `<#c><#audio>./dance/1_dance-stories-sabine.wav</#audio>
<h5><#c>*Audio only in German</#c></h5></#c>`
                    ]
                }, {
                    title: "Gisela",
                    content: [
                        `<#image>8_1.jpg</#image>
<h5><#c>Image: Jamin İhraç</#c></h5>`,
                        `Gisela has been living in the same flat in Berlin-Neukölln since 1962 with her husband, where they also raised their son. The couple used to go dancing at Ballhaus Rixdorf, then known as “Rixi,” on Kottbusser Damm on the weekends. In our talk, Gisela reflects on how nowadays dance plays less of a role than it used to.`,
                        `<#c><#audio>./dance/2_dance-stories-gisela.wav</#audio>
<h5><#c>*Audio only in German</#c></h5></#c>`
                    ]
                }, {
                    title: "Tabea Antonacci, Julie Peters, Kata Kwiatkowska and Nikola Allaire",
                    content: [
                        `Tabi, Julie, Kata, and Nikola are four young women in their twenties. In this talk they share about the encounter with dance playing out throughout their lives, notably at the Dance Intensive Program at Tanzfabrik Berlin. They talk about their personal relationship to dance, the changes they have experienced while engaging with it in a group, as well their thoughts about making it a profession – it’s a perfect insight for people who are less involved in the contemporary dance scene.`,
                        `<#c><#audio>./dance/3_dance-stories-tabea-julie-kata-nikola.mp3</#audio>
<h5><#c>*Audio only in English</#c></h5></#c>`
                    ]
                },
            ]
        },
        clothes_manifesto,
        berlin_istanbul,
        performance_art_gal,
        {
            title: "Public Encounters",
            lang: "en",
            id: "public_encounters",
            author: "",
            bg: {
                start_col: "white",
                col: "#a0c34b80",
                end_col: "white",
                type: "gradient",
                elements: [
                    {
                        type: "perlin",
                        text: "                      •                      ",
                        s: .75,
                        h: 2,
                        y: -0.45,
                        x: -0.45,
                        x_skew: 1.5
                    }
                ]
            },
            content: [
                `Wishing to open a conversation about Berlin’s contemporary dance scene, we decided to conduct audio interviews with dance audiences, as well as passersby in the street. We wanted to activate an encounter with people related to the contemporary dance scene and people who have nothing to do with professional dance alike, and listen to their opinions, feelings, and thoughts about dance.

`,
                `<#c><#audio>./encounters/1 - Alessandro Maggioni (Media Designer, 44, male).mp3</#audio>                <#audio>./encounters/2 - Petra Röder (Teacher, 52, no gender data).mp3</#audio></#c>
<#c><#audio>./encounters/3 - Tomaz Aquino Gonzalves_ Designer, 36, male.mp3</#audio>                <#audio>./encounters/4 - Amit Baz_ Developer, 27, male.mp3</#audio></#c>
<#c><#audio>./encounters/5 - Hendrik Hebben, Dancer, male, 27 + Julian Bender Employee Musical, male, 25.mp3</#audio>                <#audio>./encounters/6 - Julia Studentin.mp3</#audio></#c>
<#c><#audio>./encounters/7 - Moritz_ Photographer, 35, male (ONLY FIRST NAME).mp3</#audio>                <#audio>./encounters/8 - Janine_ Product Designer, 36, female (ONLY FIRST NAME).mp3</#audio></#c>
<#c><#audio>./encounters/9 - Bastian_ Designer, 38, male (ONLY FIRST NAME).mp3</#audio>                <#audio>./encounters/10 - Mira_ Art Scientist, 30, female (ONLY FIRST NAME).mp3</#audio></#c>`,
                `<#image>12_1.jpg</#image>
<h5><#c>Image: Kat Válastur and Ixchel Mendoza Hernández</#c></h5>`,
                `<#c><#audio>./encounters/11 - Claudia_ Rentnerin, 65, female (ONLY FIRST NAME).mp3</#audio>                <#audio>./encounters/12 - ANONYMOUS_ Pensioner, 65, female (Brigitte).mp3</#audio></#c>
<#c><#audio>./encounters/13 - ANONYMOUS_ German Teacher, 41, female (Ana).mp3</#audio>                <#audio>./encounters/14 - ANONYMOUS + NO INFO (Florentine).mp3</#audio></#c>
<#c><#audio>./encounters/15 - ANONYMOUS_ Choreographer, 30, female (Kiana).mp3</#audio>                <#audio>./encounters/16 - ANONYMOUS_ PROGRAMMER 51, male (Georg).mp3</#audio></#c>
<#c><#audio>./encounters/17 - ANONYMOUS_ CURATOR, 53, female (Daniela).mp3</#audio>                <#audio>./encounters/18 - ANONYMOUS_ Physiotherapist 53, f (Roseli).mp3</#audio></#c>
<#c><#audio>./encounters/19 - ANONYMOUS_2Students,40,f(OngeongKim+SanghwaNam).mp3</#audio>                <#audio>./encounters/20 - ANONYMOUS_Actress,55,female+Archivist,59,female(Anke+Kerstin).mp3</#audio></#c>
<#image>12_2.jpg</#image>
<h5><#c>Image: Ixchel Mendoza Hernández and Kat Válastur</#c></h5>`
            ],
            subpages: []
        }, {
            title: "CREDITS",
            lang: "en",
            id: "credits",
            bg: {
                col: "var(--blu)",
                type: "solid",
                text_col: "white",
                heading_col: "white"
            },
            content: [
                `<h4><#c>ARTISTIC TEAM</#c></h4> <#c>Ixchel Mendoza Hernández, Jasmin İhraç, Sina Kießling, Lena Klink, Ana Laura Lozza (until autumn 2021), Camila Malenchini, Kat Válastur</#c>
<h4><#c>WEBDESIGN</#c></h4><#c>Dora Đurkesac</#c>
<h4><#c>WEB DEVELOPMENT</#c></h4><#c><a style='color: inherit;' href='https://rudytak.github.io/portfolio/'>Ondřej Sedláček</a></#c>
<h4><#c>EDITING ENGLISH</#c></h4><#c>Louise Trueheart</#c>
<h4><#c>EDITING GERMAN</#c></h4><#c>Lara Spielmann</#c>
<h4><#c>TRANSLATIONS</#c></h4><#c>Gegensatz Translation Collective Berlin</#c>
<h4><#c>COORDINATION EDITING & TRANSLATION</#c></h4><#c>Jasmin İhraç</#c>
<h4><#c>AUTHORS</#c></h4><#c>Lena Klink, Sybille Schmidt, Louise Trueheart, Margarita Tsomou, Lea Marie Uría, Kat Válastur</#c>
<h4><#c>SOUND EDITING PUBLIC ENCOUNTERS</#c></h4><#c>Vagelis Tsatsis</#c>
<h4><#c>SOUND EDITING DANCE STORIES</#c></h4><#c>Ixchel Mendoza Hernández</#c>
<h4><#c>MUSIC</#c></h4><#c>Hyewon Suk</#c>
<h4><#c>PRODUCTIONS-ASSISTANCE</#c></h4><#c>Camila Malenchini, Lena Klink</#c>
<h4><#c>PRODUCTION</#c></h4><#c>Sina Kießling</#c>
<h4><#c>COVER VIDEO</#c></h4><#c>Excerpt from “Ultra Censored” short film by Kat Válastur</#c>
`
            ],
            subpages: []
        },
        contact,
        imprint
    ],
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    "de": [
        {
            title: "Vom Tragen, oder: Fünf Versuche, die Killergeschichte zu verlernen",
            lang: "de",
            id: "killer_story",
            author: "Sibylle Schmidt",
            bg: {
                start_col: "white",
                col: "#006e6980",
                end_col: "white",
                type: "gradient",
                elements: [
                    {
                        type: "perlin",
                        text: "••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••",
                        s: 0.3,
                        h: 2,
                        x: 0.3,
                        y: 0.3
                    }
                ]
            },
            subpages: [
                {
                    title: "1. Schwerkraft",
                    content: [
                        `In dem Augenblick, in dem ich das schreibe, trage ich Billionen von Mikroorganismen in und auf meinem Körper. Bakterien, Pilze und Viren bevölkern meine Hände, Haut, Mundhöhle und die Innenwände meines Darms. Bis zu zwei Kilogramm, sagt man, wiegt das Mikrobiom im Körper eines Menschen.
Ich trage in mir einen anderen lebendigen Körper, der mit seinen Händen und Füßen von innen gegen meine Bauchdecke drückt, und denke über das Wort <i>„trächtig“</i> nach, das so abstoßend klingt, und das man im Deutschen nur für Tiere gebraucht, nicht aber für Menschen. Der Ausdruck „gravid“ klingt schon besser. Hier schwingt auch gravitas mit, die Schwerkraft.

Im übertragenen Sinn, also nicht mechanisch, sondern figurativ oder metonymisch, bin ich Trägerin von Wissen, Daten, von blinden Flecken und Vorurteilen, von Gedanken – einige davon klar und geordnet wie frisch zusammengelegte Bettlaken, andere eher dunkel und verworren, an den Rändern meines Bewusstseins herschwimmend wie lose Fäden. Ich trage Erinnerungen: an meine Kindheit, an wichtige Ereignisse, aber auch an triviale Details, die ich mir aus irgendwelchen Gründen gemerkt habe, wie der mit lindgrünem Plüsch bezogene Klodeckel auf einer Party, auf der ich mal mit Anfang Zwanzig war. 

Auf meiner Haut trage ich Narben: einen kleinen, zackigen Halbkreis am Knie, eine waagrechte Linie zwischen den Leisten. 
Vieles von dem, was ich trage, ist mir nicht bewusst, aber es prägt meine Bewegungen, mein Denken, mein Fühlen.


Ich trage Verantwortung und Sorge für Menschen, Tiere, Pflanzen, die mit mir zusammenleben oder deren Leben auf andere Weise mit meinem verwoben sind. Und sie tragen und ertragen auch mich. Wir tragen die Menschen, die wir lieben, in unseren Armen, in unseren Gedanken mit uns. Tragen ist Fürsorge: Es ist ein Ausdruck von Verbundenheit, Unterstützung und Liebe, auch über das Leben hinaus. Sagt man nicht auch, dass man Verstorbene „zu Grabe trägt‘?
Die Physik sagt: Im Gegensatz zur Masse eines Körpers hängt seine Gewichtskraft vom Ort ab, an dem er sich befindet. Das Gewicht gibt an, wie stark ein Körper auf eine Unterlage drückt oder an einer Aufhängung zieht. Anders formuliert: Etwas hat Gewicht für denjenigen, der es trägt. Etwas hat Gewicht <i>für mich</i>.All diese Dinge, die ich trage, während ich schreibe. Warum denke ich, sie seien für mein Schreiben nicht von Gewicht?`,
                        `<#image>3_1.jpg</#image>
<h5><#c>Bild: Kat Válastur</#c></h5>`
                    ]
                }, {
                    title: "2. Kanon",
                    content: [
                        `
Im Jahr 1979 legt die Anthropologin Elizabeth Fisher mit <i>Woman's Creation. Sexual Evolution and the Shaping of Society</i><#^>1</#^> eine feministische Evolutionstheorie der Menschheit vor. Ihre These: Das erste Werkzeug, das der Mensch benutzte, sei nicht etwa eine spitze Waffe, ein Messer, Speer oder dergleichen gewesen, sondern ein Behälter, mit dessen Hilfe Nahrung von ihrem Fundort nach Hause gebracht werden konnte. 

Die Idee von der Tragetasche als Kulturtechnik begeistert die Science-Fiction-Autorin und Essayistin Ursula Le Guin. 1986 schreibt sie in „Carrier Bag Theory of Fiction“<#^>2</#^>: Wenn man, um als menschlich zu gelten, Waffen herstellen und damit töten müsse, dann sei sie „entweder ein völlig mangelhafter Menschenwesen oder aber überhaupt kein Mensch.“<#^>3</#^> Wenn aber menschlich zu sein bedeutete, etwas, das nützlich, essbar oder schön ist, in einen Beutel zu tun und es Heim zutragen, wo man es aufbewahren oder mit anderen teilen oder in ein Medizinbündel stecken könne – wenn es das sei, was Menschsein ausmache, dann gehöre sie wohl auch dazu: „Zum allerersten Mal vollends menschlich und dabei frei und froh.“<#^>4</#^>
Wir müssten lernen, uns von der „Killergeschichte“ zu lösen, die an ihr Ende gekommen sei und drohe, uns mit in den Abgrund zu reißen. Deshalb suche sie, Le Guin, „mit einer gewissen Dringlichkeit nach der Natur, nach dem Motiv, nach den Worten jener anderen Geschichte, der unerzählten Geschichte, der Lebensgeschichte.“<#^>5</#^>

Im Jahr 2019 zieht Donna Haraway, Wissenschaftshistorikerin und Philosophin, die “Carrier Bag Theory of Fiction” aus der Tasche, ediert sie neu. In Zeiten von Klimawandel und Artensterben sucht Haraway ebenfalls nach Gegengeschichten, um sich vom alten Mythos des Menschen als Jäger, als Held und als Killer zu emanzipieren. „Storytelling“ ist für Haraway mehr als Fiktion, es bedeutet: Anzukämpfen gegen die Gedankenlosigkeit, mit der wir ein Skript ausführen, das sich andere für uns ausgedacht haben:

<#t>„Es ist von Gewicht, welche Gedanken Gedanken denken. Es ist von Gewicht, welche Wissensformen Wissen wissen. Es ist von Gewicht, welche Beziehungen Beziehungen knüpfen. Es ist von Gewicht, welche Welten Welten verweltlichen. Es ist von Gewicht, welche Erzählungen Erzählungen erzählen.”<#^>6</#^>
</#t>
Fisher – Le Guin – Haraway: Drei Knotenpunkte in einem Netzwerk von Denkerinnen, drei Autorinnen, die die Ideen der anderen in ihrem Beutel (die in diesem Fall Bücher sind) weitertragen, durch die Zeit schmuggeln, sie hervorholen wie beinahe vergessene Fundstücke, teilen und verknüpfen. Die Tragetasche ist in diesem Sinn auch ein Werkzeug der schreibenden Frau, eine feministische Wissenspraxis, ein Trick der <i>underdogs</i>, Außenseiter*innen und Subalternen, um sich gegen den Verdrängungseffekt des Kanons zu behaupten. Donna Haraway betont, wie wichtig das Denken in Netzwerken ist, um gerade die Ideen weiblicher Autorinnen vor dem Vergessen zu retten: 

<#t>“Frauen haben immer verstanden, [dass es darum geht] nicht von dem mächtigen Apparat des männlichen Denkens, der männlichen Praktiken sowohl in den Institutionen als auch auf individueller Ebene zum Verschwinden gebracht zu werden – dass unser Denken schnell zum Verschwinden gebracht wird. Und ich denke, eine der feministischen Praktiken besteht darin, bewusst und sorgfältig und präzise mit der Geschichte der Ideen und der besonderen Kreativität und Originalität und Bedeutung des Denkens anderer Frauen umzugehen.“<#^>7</#^>
</#t>
Zur Etymologie des Wortes „Kanon“ findet sich im Digitalen Wörterbuch der deutschen Sprache folgender Eintrag: 
„Kanon, griechisch kanṓn (κανών), bedeutet ‘Regel, Vorschrift, Liste, Katalog, Verzeichnis der klassischen Schriftsteller’, eigentlich ‘gerade Stange, Richtscheit, Lineal’.“<#^>8</#^>

Ist es überraschend, dass wir hier wieder auf den Stock, das phallische Werkzeug, Haraways „prick tale“, stoßen?

`
                    ]
                }, {
                    title: "3. Arbeitsteilung",
                    content: [
                        `
Menschen sind nicht die einzigen Lebewesen, die Beutel benutzen. Nager stopfen sich die Backen mit Nüssen voll, um sie in ihr Winterquartier zu bringen, Seepferdchenmänner bewahren die befruchteten Eier ihrer Partnerin in ihrer Bauchtasche auf, Beuteltiere tragen ihre Jungtiere in einer Hautfalte. Und haben nicht die Pflanzen das Taschenprinzip eigentlich erfunden, indem sie für ihre Samen die genialsten Behältnisse entwickelt haben, durch welche diese von Wind, Tieren und Menschen möglichst weit getragen werden? 
Die Tragetasche, bevor der Mensch sie als Werkzeug entdeckte, ist zuallererst ein Organ der Reproduktion.

Wenn Ursula Le Guin sich 1986 Baby Oo Oo in einem Bündel auf den Rücken bindet, um beide Hände frei zu haben für das Pflücken des Hafers, und der kleinen Oom ein Körbchen in die Hand drückt zum Sammeln, dann tut sie etwas, das eine Verwandtschaft mit anderen, nicht-menschlichen, Lebewesen der Erde ausdrückt: Sie sorgt dafür, dass das Leben weitergeht, sie leistet reproduktive Arbeit, „und dann am darauffolgenden Tag mehr oder weniger dasselbe […].“<#^>9</#^>
Diese Ruhe und Genügsamkeit, mit der Le Guin (beziehungsweise ihr essayistisches Ich) da in meiner Vorstellung über das Feld geht, die Tasche trägt, ja sich selber zur Tragetasche macht: Sie löst ein tiefes Unwohlsein in mir aus. Ist das nicht das altmodische Klischee von der Frau als Mutter, Trösterin, Ernährerin – als Gefäß? Würde diese Frau nicht auch lieber auf die Jagd gehen (die sie vielleicht nur deshalb meidet, weil ihre Kinder noch klein sind und sie keine Betreuung gefunden hat)? Würde sie sich nicht auch gern zeitweise befreien von der Last, Versorgerin sein zu müssen, um etwas Zeit allein am Schreibtisch zu verbringen, in einem eigenen Zimmer, wie Virgina Woolf es gefordert hat? Und wäre es nicht nachvollziehbar, wenn sie – zumindest in einer kleinen, geheimen Nische ihres Bewusstseins – Reue darüber empfinden würde, dass sie auf die Rolle der Heldin einer Geschichte verzichtet?

Die abendländische Denktradition lehrt uns, dass Reproduktionsarbeit und Kunst sich ausschließen – dass man, um Kunst zu produzieren und wahrnehmen zu können, frei sein muss von der täglichen Plackerei der Nahrungsbeschaffung, dem Sammeln, Tragen und Pflegen, der Care-Arbeit. Voraussetzung des Ästhetischen ist „demnach die Entlastung von aufdringlichen praktischen Zwecken, ein Absehen (Ab-Sehen) von der <i>anankè</i> (Lebensnot, Notdurft).“<#^>10</#^> Wie ein Mantra durchzieht diese Auffassung die Ästhetik bis heute: Von Kants Begriff der „Interesselosigkeit“ des ästhetischen Urteils, über Schillers „freies Spiel“, das den Menschen erst zum Menschen macht: „Das letzte Wort der Ästhetik ist die menschliche Freiheit“<#^>11</#^>. Da möchte man auf keinen Fall nicht gestört werden durch profane Dinge wie Kindergeschrei. 

Zwar setzt produktive Arbeit die reproduktive voraus – jemand muss sich schließlich um die Erhaltung, Ernährung, Erziehung, den Trost kümmern. Aber das Tragen dieser Besorgungen, das „trächtig sein“, findet vorzugsweise unsichtbar und geräuschlos im Privaten statt. Hannah Arendt, die der reproduktiven Arbeit in ihrem Buch „Vita Activa“<#^>12</#^> viel Aufmerksamkeit schenkt, lässt keinen Zweifel daran, dass dieser Bereich strengstens von den höheren Sphären menschlicher Tätigkeit und insbesondere der Kunst zu trennen sei: „Auch ist die angemessene Art des Umgangs mit den Dingen, die wir Kunstwerke nennen, sicher nicht das Brauchen und Gebrauchen; […] So müssen sie auch den täglichen Bedürfnissen und Notdürften des Lebens entrückt werden, mit denen sie weniger in Berührung kommen als irgendein anderes Ding.“<#^>13</#^> 
Nicht in Berührung kommen: Als werde die Kunst dadurch kontaminiert und beschmutzt, dass sie mit reproduktiven Arbeiten wie dem Sammeln, Ernähren, Tragen in Kontakt kommt. 

Arendt war sich der sozialen Implikation dieser strengen Aufteilung durchaus bewusst: „Da die Menschen der Notdurft des Lebens unterworfen sind, können sie nur frei werden, in dem sie andere unterwerfen und sie mit Gewalt zwingen, die Notdurft des Lebens für sie zu tragen.“<#^>14</#^> Die rigide Trennung von Produktion und Reproduktion macht es nötig, auch entsprechende Kategorien für Menschen zu erfinden. „Das Altertum meinte (…), dass man Sklaven nötig habe, weil es notwendige Beschäftigungen gibt, die ihrer Natur nach ‚sklavisch‘ sind, nämlich dem Leben und seiner Notdurft versklavt.“<#^>15</#^> Die Kehrseite von Kultur ist Kolonialisierung. 

„Das Problem ist nur, dass wir alle zugelassen haben, selbst zu einem Teil der Killergeschichte zu werden, so dass deren Ende auch uns den Garaus machen könnte.”<#^>16</#^> Ich muss an all die Fahrer*innen und Lieferbot*innen denken, die für uns Kleidung, Foodpacks und anderes Zeug durch die Straßen tragen – und all die anderen Lastenträger*innen in unserem Leben, die auf so gespenstische Weise unsichtbar, unhörbar sind. 
Vielleicht bedeutet die Suche nach der anderen, der unerzählten Lebensgeschichte: Fragen, was erforderlich ist, damit diese Menschen ihre Geschichte erzählen können?
`,
                        `<#image>3_2.jpg</#image>
<h5><#c>Bild: Kat Válastur</#c></h5>`
                    ]
                }, {
                    title: "4. Heldinnen",
                    content: [
                        `
„Ich nehme das erste Messer und steche, so schnell ich kann, zwischen meine     ausgespreizten Finger der linken Hand. Jedesmal wenn ich mich schneide, wechsle ich das Messer. Wenn alle Messer gebraucht sind (alle Rhythmen), spule ich das Band zurück. Ich höre mir die Aufnahme der ersten Performance an. Ich konzentriere mich. Ich wiederhole den ersten Teil der Performance. Ich nehme die Messer in der gleichen Reihenfolge, verfolge den gleichen Rhythmus und schneide mich an der gleichen Stelle.“
<#r>Marina Abramović, über ihre Performance „Rhythm 10“<#^>17</#^></#r>

“Ich glaube wirklich, dass durch das Zurückschneiden die Wurzeln stärker wachsen.” 
<#r>Tracey Emin<#^>18</#^></#r>


Was ich mich frage: Wenn wir andere Geschichten erzählen wollen, müssen wir nicht auch lernen, andere Geschichten über die Geschichtenerzählerin zu erzählen? Von der Künstlerin, der Wissenschaftlerin, der Denkerin? 

Ökologische Themen werden in der Kunst mehr reflektiert denn je zuvor, die Texte von Autorinnen wie Haraway und Le Guin erleben geradezu einen Hype unter Kunst- und Kulturschaffenden. Gleichzeitig aber ist unsere Vorstellung vom Künstler*innen- und Intellektuellen-Dasein immer noch stark geprägt von dem heroischen Mythos, den Haraway und Le Guin kritisieren: Das einsame Genie, das sich einer Sache ganz und gar verschreibt, sein Projekt konzentriert verfolgt und ihm alles unterordnet – es ähnelt dem Jäger in Le Guins Geschichte, der das Mammut fokussiert und erlegt, dem Helden, dem Killer. Frauen, vor allem solche mit Care-Verpflichtungen, sind immer noch eher die Abweichung als die Norm des kreativen, intellektuell und künstlerisch schöpferischen Menschen. Das ist nicht nur ein Klischee, sondern manifestiert sich auch in den ökonomischen Strukturen der Kunstwelt: Förderprogramme erfordern ein hohes Maß an Flexibilität und Ungebundenheit, Aufenthaltsstipendien können größtenteils nur von Einzelpersonen wahrgenommen werden. Care-Arbeit wird als ein individuelles Problem betrachtet, für dessen Lösung (oder eben Nicht-Lösung) hauptsächlich Mütter die Verantwortung tragen. So gelten Kinder nach wie vor als „Killer der Künstlerkarriere“<#^>19</#^>, oder wie Tracey Emin es ausgedrückt hat: “Es gibt gute Künstler, die Kinder haben. Natürlich gibt es die. Man nennt sie Männer.”<#^>20</#^>

„Ich hatte die totale Freiheit erfahren“, erinnert sich Marina Abramović an ihre Performance <i>Rhythm 10</i> von 1973, das sie passenderweise „Messerspiel“ nennt, „ich hatte gespürt, dass mein Körper grenzenlos war; dass Schmerz keine Rolle spielte, dass überhaupt nichts eine Rolle spielte – und es war berauschend.“<#^>21</#^>
In <i>Rhythm 0</i>, ein Jahr später performt, macht Abramović sich sechs Stunden lang zum Objekt des Publikums. In völliger Passivität lässt sie geschehen, dass die Besucher*innen sie wie eine Puppe herumtragen, anmalen, in obszöne Posen bringen, mit Nadeln stechen und mit Messern in die Haut ritzen, bis schließlich ein Mann ihr eine geladene Pistole an den Hals legt und den Finger auf den Abzug legt. 
Abramović verkörpert die Figur der einsamen, genialen Heldin vielleicht wie keine andere Künstlerin. Doch verstrickt in die Heldengeschichte, die von Männern für Männer erfunden wurde, findet die Heldin sich auf der falschen Seite der Klinge wieder: Als Märtyrerin, Opfer, dem Tod ins Auge sehend. 

Was es bedeuten könnte, eine andere Geschichte über sich selbst zu erzählen: 
Was könnte es bedeuten, eine andere Geschichte über uns zu erzählen, in der wir keine Mauern durchbrechen müssen? Eine Geschichte, in der unsere Existenz nicht eine Reihe von Hindernissen ist, die es zu überwinden gilt? Der Körper ist nicht grenzenlos, und wir müssen lernen, mit endlichen Ressourcen auszukommen. Schmerz spielt eine Rolle, ebenso wie die Dinge, die wir mit uns herumtragen. Sie verschieben den Fokus, die Bewegung. Was würde es bedeuten, dies nicht als Last oder Defizit zu sehen, sondern als Ressource für Kunst und Denken? 
In einem Interview mit <i>Cultural Reproducers</i> sagt Faye Lim, Tänzerin und Choreographin, über die künstlerische Arbeit mit ihrem Sohn: 

<#t>“Ich erlebe den Tanz jetzt anders, nachdem ich Mutter geworden bin. Zeit, Raum und Energie, gewisse Einschränkungen kommen auf mich zu. Aber sich mit ihm [gemeint ist Lims Sohn, Anm. S.S.] zu bewegen, mit ihm zu improvisieren, hat mir geholfen (...) mit dem Gefühl zu spielen, dass Zeit und Raum sich verschließen. Ich bin viel neugieriger geworden und habe viel mehr darauf geachtet, wie sich Kinder und verschiedene Bezugspersonen bewegen und mit ihren Körpern arbeiten.“<#^>22</#^>
</#t>
Auch die britische Künstlerin Lenka Clayton beschäftigt sich in ihren Arbeiten mit Fragen von Bindung und Mutterschaft. In ihrer Videoserie „The Distance I can be from my son“<#^>23</#^> misst sie die jeweils größtmögliche Entfernung aus, die sie in unterschiedlichen Umgebungen zu ihrem Sohn erträgt. Die „objektive“ Angabe in Yards und Metern ist dabei nicht ohne Ironie – vor allem aber wird einem durch das Betrachten der Videos klar, dass diese existenzielle Erfahrungsdimension menschlicher Bindung in der Kunst bislang kaum vermessen und kartographiert worden ist. Darstellungen von Mutterschaft in der Kunst, das bedeuteten lange Zeit: Das Madonnenbild, gemalt aus männlicher Perspektive.<#^>24</#^>

Claytons Arbeit entstand während einer „Artist in Motherhood Residency“ (ARiM) – einem weiteren Kunstprojekt Claytons, das aus ihrer Unzufriedenheit mit ihrer eigenen Situation als Künstlerin und Mutter entstand:

<#t>„Ich realisierte, dass viele Strukturen, die zentral für meine Profession waren – wie zum Beispiel die Künstlerresidenz – für mich als Hauptversorgerin von zwei kleinen Kindern auf einmal nicht mehr zur Verfügung standen. Das war der Moment, in dem ich beschlossen habe, dass ich all die übliche Unterstützung einer Residenz einfach in mein Leben zu Hause integriere. Das würde es mir ermöglichen diese frühe Phase des Elternseins als produktive, kreative Zeit zu sehen, anstatt als Situation, die mich am Arbeiten hindert.“<#^>25</#^>
</#t>
Clayton schrieb ein Manifest, erstellte eine Webseite, um ihre Arbeit zu veröffentlichen, und beantragte Forschungsgelder, um für zwei Tage in der Woche eine Kinderbetreuung finanzieren zu können. Sie setzte Freund*innen als Mentor*innen ein. Gearbeitet wurde in den kurzen Zeitspannen, in denen ihre Kinder schliefen. In insgesamt drei Jahren kamen dabei 32 Arbeiten zustande. Clayton scheint mit ihrem Projekt einen Nerv getroffen zu haben: Sie bekam nun jede Menge Anfragen von Künstler*innen in Elternschaft, die sich auch für eine „Artist Residency in Motherhood“ bewerben wollten. So veröffentlichte Clayton schließlich eine Website: <#link>www.artistresidencyinmotherhood.com</#link><#^>26</#^>. Wer selbst eine solche Künstlerresidenz durchführen will, findet hier ein DYI „Residency Kit“, mit Manifest als Formular zum Ausfüllen, Planungstools und weiterführenden Informationen. Man muss sich nicht bewerben und die Teilnahme kostet nichts, aber man hat das gute Gefühl, Teil einer Gemeinschaft zu sein, die sich im Geist gegenseitig unterstützt: Eine Karte zeigt die inzwischen überwältigenden Anzahl von laufenden und vergangenen „Artist Residencies in Motherhood“ auf der ganzen Welt.

„Wir alle müssen lernen, unser Leben selbst zu erfinden, zu erdenken, zu imaginieren. Diese Fähigkeiten müssen wir beigebracht bekommen; wir brauchen Vorbilder, die uns zeigen, wie. Andernfalls wird unser Leben von anderen Menschen für uns erfunden werden.“<#^>27</#^>, schreibt Le Guin. Wie aber kann es gelingen, Autor*in des eigenen Lebens zu werden? Vielleicht liegt der Witz darin, dass wir uns nur gegenseitig befähigen können, eine andere Geschichte zu erzählen: Wie bei einem Spiel mit Fadenfiguren, das bei Donna Haraway zum Sinnbild für ein Denken und Werden in Gemeinschaft wird, „Muster vor- und zurückreichend, gebend und nehmend, Muster bildend, ein Muster in der Hand haltend, um das man nicht gebeten hat – Responsabilität.“<#^>28</#^>
Eben ein solches Muster stellt Clayton bereit für all jene, die Elternschaft und künstlerische Praxis vereinbaren wollen. Ihr „Artist’s Statement“ ist zugleich persönliches Zeugnis und vervielfältigbares Dokument, das kopiert und geteilt werden soll: 
   
<#t>„[…] Despite a legacy of public artist/parents it still seems to be a commonly held belief that being an engaged mother and serious artist are mutually exclusive endeavors. I don’t believe or want to perpetrate this. I like to imagine the two roles not as competing directions but to view them, force them gently if necessary, to inform one another. 
I will undergo this self-imposed artist residency in order to fully experience and explore the fragmented focus, nap-length studio time, limited movement and resources and general upheaval that parenthood brings and allow it to shape the direction of my work, rather than try to work ‘despite’ it.“<#^>29</#^></#t>
`
                    ]
                }, {
                    title: "5. Sammeln",
                    content: [
                        `In ihrem Film „Die Sammler und die Sammlerin“<#^>30</#^> folgt Agnès Varda mit ihrer Kamera Menschen, die am Rande des Geschehens unterwegs sind: Nach der Ernte auf dem Feld, nachts am Straßenrand, auf dem Wochenmarkt als er noch nicht geöffnet oder schon geschlossen hat. Vornübergebeugt, mit allerlei Taschen, Beuteln, Kisten ausgerüstet, halten sie Ausschau nach übriggebliebenen Dingen, die noch essbar, brauchbar oder schön sind. 

Das Sammeln von liegengebliebenen Erntefrüchten war früher eine gängige Praxis – das berühmte Gemälde „Die Ährensammlerinnen“ von Jean-François Millet<#^>31</#^>, ausgestellt im Musée d’Orsay, zeugt davon. Die Sammler*innen von heute bewegen sich in einer juristischen Grauzone: Während das Einsammeln von liegengebliebenem Gemüse und Obst noch von manchen Eigentümern toleriert wird, ist das sogenannte „Containern“ aus den Mülleimern der Supermärkte oder das Mitnehmen von Sperrmüll hierzulande verboten und wird mit einem Bußgeld bestraft.<#^>32</#^> Die „glaneurs“<#^>33</#^>: Das sind oft selbst marginalisierte Menschen – Obdachlose, Vagabund*innen, Lebenskünstler*innen, die in den Resten der Wegwerfgesellschaft wühlen. Varda dreht jedoch keine Sozialreportage. Die Protagonist*innen, deren Geschichten sie erzählt (oder besser: sie selber erzählen lässt), sammeln keineswegs nur aus Bedürftigkeit. Aus ihnen spricht auch eine trotzige Freude –  der Spaß am Sammeln, Reparieren, Recyclen: „Es gibt genug“, sagt ein Mann namens Salomon, während er der Kamera Fischfilets, Käse, Garnelen präsentiert, die weggeworfen wurden. „Brauchst du was?“<#^>34</#^> Man staunt über das Geschick, mit dem alte Kühlschränke wieder in Stand gesetzt und über die Kunstwerke, die aus den Fundstücken gemacht werden. Varda gelingt es, inmitten des Kaputten und Ausgemusterten eine eigentümliche Schönheit, ja einen Glanz zu entdecken: So begleitet sie einen Mann, der sich tagsüber aus den Gemüseresten des Marktes ernährt und Obdachlosenzeitungen verkauft, und des Nachts in einem Heim für Geflüchtete ehrenamtlich Alphabetisierungskurse gibt.

Die „glaneuse“<#^>35</#^>: Das ist die Filmemacherin selbst. Auf den Spuren der Sammler*innen liest sie mit ihrer digitalen Handkamera Bilder und Geschichten auf, fasziniert von der Ästhetik der Reste, aber zugleich mit einem Bewusstsein für ihre Responsabilität: „Ich filme gerne Verfaulendes, Überbleibsel, Schrott, Schimmel und Abfälle. Aber ich vergesse nie, dass es Menschen gibt, die sich nach dem Markt aus den Abfällen versorgen.“<#^>36</#^> 
Der Film ist damit auch eine künstlerische Selbstreflexion. Indem Varda sich als Sammlerin versteht, widersetzt sie sich dem Narrativ vom Künstler als Schöpfer und Produzent von Neuem, der die buchstäbliche Neugier des Publikums befriedigt so wie die Industrie den Hunger nach neuen, innovativen Produkten stillt. Stattdessen richtet sie den Blick auf die Abfälle eben dieser industriellen Produktion, auf ihren Überschuss – und damit auch auf die Menschen, die das System ausgespuckt und an den Rand gedrängt hat, oder die sich ihm bewusst entzogen haben. 

Wird hier so etwas wie eine ökologische Ästhetik greifbar? (Film-)Kunst gesellt sich hier zu den Tätigkeiten des Sammelns, Tragens, des Reparierens. Sie hat nichts gemeinsam mit jenem Verständnis, wonach Kunst wesentlich die Schöpfung von etwas Brandneuem ist, von Utopien. Kreativität trägt immer auch zerstörerische Züge in sich. Der geniale Künstler, der eine neue Welt nach seinen Vorstellungen entwirft, verachtet die Welt, die bereits da ist. Vardas Film hingegen führt vor Augen, dass Kunst ein Schlüssel sein kann, um zu einer schärferen Wahrnehmung und Wertschätzung der vorhandenen Welt zu gelangen. Eine Szene des Films bringt das besonders deutlich zum Ausdruck: Varda, die Kamera in der Hand, geht mit einer Tragetasche übers Feld und sammelt herzförmige Kartoffeln. Die Kunst der Sammlerin motiviert zum Umherschauen, zum Stöbern, zur Wahrnehmung dessen, was bereits da ist: Es könnte der lose Faden einer unerzählten Geschichte sein. 
`,
                        `<#image>3_3.jpg</#image>
<h5><#c>Bild: Video still from “The Gleaners and I” by Agnès Varda</#c></h5>`,
                        `<h4 style="text-align: left; font-weight: inherit; margin: 0;">ANMERKUNGEN</h4><#note><#nn> 1. </#nn><#nt>Fisher, Elizabeth: Woman's creation. Sexual evolution and the shaping of society, New York: Mc Graw-Hill, 1979.</#nt></#note>
<#note><#nn> 2. </#nn><#nt>Le Guin, Ursula: “The Carrier Bag Theory of Fiction” in dies.: Dancing at the Edge of the World. Thoughts on Words, Women, Places, New York: Grove Press 1989. Die folgenden Zitate werden der deutschen Übersetzung entnommen, vgl. Le Guin, Ursula: „Die Tragetaschentheorie des Erzählens“, in dies.: Am Anfang war der Beutel. Essays, Reden und ein Gedicht, ausgewählt, übersetzt und eingeleitet von Matthias Fersterer, Klein-Jasedow: Drachen Verlag GmbH, 2020, S. 12-21.</#nt></#note>
<#note><#nn> 3. </#nn><#nt>Le Guin, Die Tragetaschentheorie des Erzählens, a.a.O. S. 17.</#nt></#note>
<#note><#nn> 4. </#nn><#nt>Le Guin, Die Tragetaschentheorie des Erzählens, a.a.O.  S. 16 f.</#nt></#note>
<#note><#nn> 5. </#nn><#nt>Le Guin, Die Tragetaschentheorie des Erzählens, a.a.O. S. 18.</#nt></#note>
<#note><#nn> 6. </#nn><#nt>Haraway, Donna: Unruhig bleiben. Die Verwandtschaft der Arten im Chtuluzän. Frankfurt a.M./ New York: Campus Verlag, 2018, S. 53.</#nt></#note>
<#note><#nn> 7. </#nn><#nt>„Women have repeatedly understood that not being disappeared by the powerful apparatus of masculinist thinking, of masculinist practices both in institutions and at the level of individual people – that our thinking is disappeared fast. And I think one of the feminist practices is deliberately and carefully to be very precise about the history of ideas and the particular creativity and originality and importance of other womens’ thinking.” Aus Terranova, Fabrizio: Donna Haraway. Storytelling for Earthly Survival, Belgien/Brüssel: Atelier Graphoui u.a., 2016, 00:34:15-00:35:10. Übersetzung von Sibylle Schmidt.</#nt></#note>
<#note><#nn> 8. </#nn><#nt>Quelle: https://www.dwds.de/wb/Kanon (Letzter Zugriff: 27.06.2022)</#nt></#note>
<#note><#nn> 9. </#nn><#nt>Le Guin, Die Tragetaschentheorie des Erzählens, a.a.O. S. 17.</#nt></#note>
<#note><#nn> 10. </#nn><#nt>Thomä, Dieter: „Ästhetische Freiheit zwischen Kreativität und Ekstase. Überlegungen zum Spannungsverhältnis zwischen Ästhetik und Ökonomik“, in: Menke, Christoph u. Rebentisch, Juliane: Kreation und Depression. Freiheit im gegenwärtigen Kapitalismus. Berlin: Kadmos 2012, S. 149-171, hier S. 151.</#nt></#note>
<#note><#nn> 11. </#nn><#nt>Menke, Christoph: Kraft. Ein Grundbegriff der ästhetischen Anthropologie. Frankfurt a.M.: Suhrkamp 2008.</#nt></#note>
<#note><#nn> 12. </#nn><#nt>Arendt, Hannah: Vita activa oder Vom tätigen Leben. München/Zürich: Piper Verlag 2002.</#nt></#note>
<#note><#nn> 13. </#nn><#nt>Arendt: Vita activa oder Vom tätigen Leben, a.a.O. S. 201.</#nt></#note>
<#note><#nn> 14. </#nn><#nt>Arendt, Vita activa oder Vom tätigen Leben, a.a.O. S. 101.</#nt></#note>
<#note><#nn> 15. </#nn><#nt>Ebd.</#nt></#note>
<#note><#nn> 16. </#nn><#nt>Le Guin, Die Tragetaschentheorie des Erzählens, a.a.O. S. 18.</#nt></#note>
<#note><#nn> 17. </#nn><#nt>Quelle: http://www.medienkunstnetz.de/werke/rhythm-10-2/. (Letzter Zugriff: 27.06.22)</#nt></#note>
<#note><#nn> 18. </#nn><#nt>“I really do believe that by cutting back, the roots grow stronger.” Aus: Tracey Emin: My Life as a Column, in: The Independent, 27.5.2005, https://www.independent.co.uk/voices/columnists/tracey-emin/tracey-emin-my-life-as-a-column-5345508.html. (Letzter Zugriff: 27.06.22)</#nt></#note>
<#note><#nn> 19. </#nn><#nt>Monopol Magazin, Ausgabe 02/2019, S. 43.</#nt></#note>
<#note><#nn> 20. </#nn><#nt>„There are good artists that have children. Of course there are. They are called men.” Interview in „The Independent“ am 3.10.2014, Quelle: https://www.independent.co.uk/news/people/tracey-emin-there-are-good-artists-that-have-children-they-are-called-men-9771053.html. (Letzter Zugriff: 27.06.22)</#nt></#note>
<#note><#nn> 21. </#nn><#nt>Abramović, Marina (mit James Kaplan): Durch Mauern Gehen. München: Luchterhand 2016, S. 84.</#nt></#note>
<#note><#nn> 22. </#nn><#nt>Quelle: https://www.culturalreproducers.org/2022/01/interview-faye-lim.html. (Letzter Zugriff: 27.06.2022) Aus dem Englischen übersetzt von Sibylle Schmidt.</#nt></#note>
<#note><#nn> 23. </#nn><#nt>Quelle: http://www.lenkaclayton.com/work#/the-distance-i-can-be-from-my-son/. (Letzter Zugriff: 27.06.2022) </#nt></#note>
<#note><#nn> 24. </#nn><#nt>Vgl. hierzu die Ausstellung „Mutter!“ in der Kunsthalle Mannheim (1.10.21 bis 6.2.2022), deren Pressetext damit warb, es seien „Arbeiten unter anderem von Egon Schiele, Pablo Picasso, Edvard Munch, René Magritte und Otto Dix zu sehen.“ Erst an zweiter Stelle wurde auf die Werke weiblicher Künstlerinnen verwiesen.</#nt></#note>
<#note><#nn> 25. </#nn><#nt>“Mutterschaft und Kunst. Wie lässt sich das vereinbaren?“ Interview mit Natalie Wichmann, Schirn Mag, https://www.schirn.de/magazin/interviews/2022_interview/mutterschaft_und_kunst_wie_laesst_sich_das_vereinbaren. (Letzter Zugriff: 27.06.2022)</#nt></#note>
<#note><#nn> 26. </#nn><#nt>Man könnte gegen den Titel des Projekts einwenden, dass das Problem nicht nur Mütter, sondern allgemein Eltern und Menschen mit Sorgeverpflichtungen betrifft. Allerdings zeigen Studien, dass insbesondere Frauen und unter diesen vor allem Mütter im Kunstbetrieb benachteiligt werden: „Zwar sind mehr als die Hälfte der Student:innen an Kunsthochschulen Frauen. Es erhalten mehr Frauen Einzelausstellungen oder sind in Gruppenausstellungen und Galerien vertreten. Aber die Zahl erfolgreicher Männer im Kunstberuf ist deutlich höher und das Einkommen von Künstlerinnen ist nach wie vor deutlich niedriger.“ Sabine Weier, „Schieflage im Kunstbetrieb“, in taz – die tageszeitung, 12.09.2021, https://taz.de/Benachteiligung-von-Kuenstlerinnen/!5800014&SuchRahmen=Print. (Letzter Zugriff: 27.06.2022)So gab es auch viel Kritik, als 2020 der Kunstfonds Bonn das „Stipendium für bildende Künstler*innen mit Kindern unter 7 Jahren“ an mehr Männer als Frauen vergab: Die Bewerbungen von Frauen für das Stipendium hatten schließlich 60 Prozent betragen, die dann disproportional mit nur 45 Prozent positiven Bescheiden beantwortet wurden, vgl. Brigitte Werneburg: „Kunst und Care Arbeit“, taz – die Tageszeitung, 9.11.2020, https://taz.de/Fragwuerdige-Juryentscheidung/!5724081. (Letzter Zugriff: 27.06.2022). Was also dafür spricht, explizit mehr Unterstützung für Mütter in der Kunst zu fordern, ist, dass bei Frauen mit Sorgeverpflichtungen zwei Faktoren der Benachteiligung zusammenkommen: Die Belastung der Care-Arbeit und der Faktor der Mysogynie.</#nt></#note>
<#note><#nn> 27. </#nn><#nt>Ursula Le Guin, „Die Gebrauchsanweisung“, in: Am Anfang war der Beutel. Essays, Reden und ein Gedicht, hg. von Matthias Fersterer, Klein Jasedow: ThinkOya/ Drachen Verlag GmbH, 2020, S. 25.</#nt></#note>
<#note><#nn> 28. </#nn><#nt>Haraway, Unruhig bleiben, a.a.O. S.23.</#nt></#note>
<#note><#nn> 29. </#nn><#nt>„The Artist in Residency in Motherhood manifesto“ kann unter https://www.artistresidencyinmotherhood.com/kit heruntergeladen und editiert werden.  (Letzter Zugriff: 27.06.2022)</#nt></#note>
<#note><#nn> 30. </#nn><#nt>Varda, Agnès: Die Sammler und die Sammlerin, französischer Originaltitel: Les Glaneurs et la Glaneuse, OmU, Frankreich/ Paris: Ciné-Tamaris 2000. </#nt></#note>
<#note><#nn> 31. </#nn><#nt>Millet, Jean-Francois: Die Ährenleserinnen. 1857, Musée d'Orsay in Paris.</#nt></#note>
<#note><#nn> 32. </#nn><#nt>In Deutschland wird Containern bzw. der Versuch, Lebensmittel aus Containern zu fischen, als Diebstahl gemäß § 242 Abs. 1 des Strafgesetzbuchs (StGb) bzw. Hausfriedensbruch gemäß § 123 Abs.1 des StGb gewertet und mit einer Freiheitsstrafe oder mit Geldstrafe belegt. 2020 hat das Bundesverfassungsgericht die Rechtmäßigkeit dieser Bestrafung bestätigt, vgl. „Containern ist strafbar“, FAZ vom 18.08.2020, https://www.faz.net/aktuell/wirtschaft/bundesverfassungsgericht-containern-ist-strafbar-16910090.html. (Letzter Zugriff: 27.06.2022)</#nt></#note>
<#note><#nn> 33. </#nn><#nt>Vgl. der Originaltitel des Films. </#nt></#note>
<#note><#nn> 34. </#nn><#nt>Varda, 2000, 1:02:15-1:02:18.</#nt></#note>
<#note><#nn> 35. </#nn><#nt>Vgl. der Originaltitel des Films.</#nt></#note>
<#note><#nn> 36. </#nn><#nt>Varda, 2000, 1:13:20-1:13:43. </#nt></#note>
`
                    ]
                },
            ]
        },
        planetary_feminism,
        {
            title: "Staged talk. An exchange between dance practitioners in Berlin",
            lang: "de",
            id: "staged_talk",
            author: "",
            bg: {
                start_col: "white",
                col: "#ff556980",
                end_col: "white",
                type: "gradient"
            },
            content: [
                `Am 25. Januar 2022 luden wir zwölf Kolleg*innen und Tanzschaffende der Berliner Tanzszene zu einer Podiumsdiskussion ein. Ausgehend von einem Gedankenexperiment, das die Berliner Tanzszene als ein Ökosystem begreift, in dem jede kleinste Interaktion zwischen Lebewesen und ihrer Umwelt einen globalen Dominoeffekt auslöst, diskutierten die eingeladenen Choreograf*innen über zentrale Themen und Politik der Berliner Tanzszene. Vor dem Gespräch fertigten die Künstler*innen in einem ritualisierten Prozess eine Maske an, inspiriert von einem ausgewählten Tier, das sie eingeladen waren zu verkörpern. Das Gespräch wurde von Margarita Tsomou sowie Olympia Bukkakis moderiert, die Masken in Kollaboration mit der Kostümbildnerin Caroline Schogs erarbeitet.`,
                `<#c><#vim_video>722510993</#vim_video></#c>`,
                `<#c><#vim_video>722512041</#vim_video></#c>`,
                `<h4><#c>CHOREOGRAF*INNEN</#c></h4><#c>Angela Alves, Antonia Baehr, Elpida Orfanidou, Helena Waldmann, Joy Alpuerto Ritter, Jule Flierl, Kareth Schaffer, Layton Lachman, Ligia Lewis, Lee Méir, Magda Korsinsky und Yvonne Semebene</#c>
<h4><#c>MODERATION</#c></h4><#c>Margarita Tsomou and Olympia Bukkakis</#c>
<h4><#c>MASKEN UND MASKENENTWICKLUNG</#c></h4><#c>Caroline Schogs</#c>
<h4><#c>KAMERA UND SCHNITT</#c></h4><#c>Ethan Folk and Andrea Schmidt</#c>`
            ],
            subpages: []
        },
        ashes_of_anita_berber,
        {
            title: "Anita Berber: Wir sind im Krieg",
            lang: "de",
            id: "we_are_at_war",
            author: "Lea Marie Uría",
            bg: {
                start_col: "white",
                col: "#55555580",
                end_col: "white",
                type: "gradient",
                elements: [
                // {
                //     type: "perlin",
                //     text: "•••••••••••••••••••••••••••••••••••••                                                                                                                                            ",
                //     s:1,
                //     h:2,
                //     x:0,
                //     y:0
                // }
                ]
            },
            content: [
                `<#image>7_4.jpg</#image>
<h5><#c>Bild: Alexander Binder</#c></h5>`,
                `Gibt es einen Kriegstanz? Wie tanzt ein von Gewalt durchdrungener Körper? Sollte es einen Kriegstanz geben, ließe sich Leid durch ihn verhindern? Wäre er ein „wohltuendes Ventil“ für die Wut? Oder müssten sich im Gegenteil die Tänzer*innen dabei in einer Art Selbstaufopferung Schaden zufügen? Wäre er also wie ein Krieg gegen sich selbst? Wann wird aus dem Krieg gegen das Selbst ein Krieg gegen die Anderen? 

Wir befinden uns im Berlin der frühen 1920er Jahre. Anita Berber tanzt in einem Kabarett. Für sie bedeutet das in jeder Hinsicht eine <i>Tour de Force</i>. Ständig wird sie von den Zuschauenden, überwiegend Männer, beschimpft. Sie reagiert zunächst mit Worten und Tanz, bis es ihr genug wird: Sie unterbricht ihre Performance und zerschlägt eine Flasche auf dem Kopf eines Typen, der sie beschimpft. Was sich da abspielt – so stelle ich es mir zumindest vor, ich kann es nur den Beschreibungen und kurzen Ausschnitten von Berbers Solos im Internet entnehmen – ist eine Art Punkkonzert, das sich allerdings in einigen wesentlichen Punkten unterscheidet.`,
                `<#image>7_3.jpg</#image>
<h5><#c>Bild: Alexander Binder and Leon Eixenberger</#c></h5>`,
                ` Statt einer Rockband steht da eine junge Frau in ihren Zwanzigern auf der Bühne, die beleidigt wird. Die Präsenz von Männern ist hier auf das Publikum beschränkt, allerdings sind sie in der Mehrheit. Eine Frau gegen viele Männer. Plötzlich handelt es sich nicht mehr (nur) um eine Protopunkperformance, sondern um einen Inquisitionsprozess. 
Vielleicht gefällt es den Anderen, Menschen dabei zuzuschauen, wie sie sich selbst Schaden zufügen, wie sie sich offensichtlich selber bekriegen. Dann erfasst sie jedoch eine unerwartete Dynamik: Die Besucher*innen hassen sich selbst dafür, dass sie Spaß an der vermeintlichen (Selbst-) Zerstörung oder Selbstkasteiung eines Menschen haben. Oder auch nicht: Sie genießen es einfach. Ist der/ die Märtyrer*in ein Mann werden in einer solchen Situation gewöhnlich Religionen gegründet, handelt es sich um eine Frau wird sie als Hexe verbrannt. Denn sie stellen sich folgende Frage: Was, wenn diese Person (diese Frau, diese Tänzerin, Anita Berber) ihre Niederlage nur vortäuscht? Was, wenn dieses „Spielchen“ in der Lage ist, die Fundamente der Gesellschaft ins Wanken zu bringen? Da habt ihr es: Ihr wolltet euren Spaß haben und jetzt seht ihr euch mit euren Dämonen konfrontiert, genauso wie es die Künstlerin, wie es Anita, gewollt hat. 
Die Männer fühlen sich zu ihr hingezogen und gleichzeitig abgestoßen und dafür hassen sie sie. Sie wiederum genießt es, in dem Maße gehasst zu werden, wie sie selbst ihr Publikum hasst. Ohne dass sich die Angreifer dessen bewusst wären, werden sie bloßgestellt. Anita zeigt das Offensichtliche: Sie sind es, die sich letzten Endes selbst erniedrigen (oder zerstören). 

Ich bin gegen eine Genealogie der Gewalt, wenn es so etwas gibt. Ich lehne es ab, ein Ereignis in der Vergangenheit auszumachen und zu sagen, dass die finsterste, die schrecklichste und grausamste Tragödie der Geschichte tatsächlich viel früher begann, als bisher angenommen. Das würde nämlich auch bedeuten, dass sich immer das Gleiche abspielt. Mit Sicherheit gibt es in der  Geschichte Kontinuität, aber auch Umwälzungen, Einschnitte, die als solche verstanden werden sollten. Daher ist es sinnvoll, die Zusammenhänge zwischen den Umwälzungen aufzuzeigen, statt sie zu relativieren. Es gibt zwischen Deutschland und Japan eine bemerkenswerte Verbindung: Die „Revolution von oben“. Ist so etwas überhaupt möglich oder wäre es angebrachter, von einer Welle intensivierter Repression zu sprechen? 
Marx hat es nicht kommen sehen, dass die Industrialisierung von einem mittelalterlichen Imperium ausgehen würde – aus den Bäuer*innen wurden Fabrikarbeiter*innen und kurz darauf Soldat*innen: zunehmende Unterdrückung, Verdrängung, Gewalt im 
Körper. Die ungeheure Akkumulation von Kapital. Gigantische Zerstörung. Die wiederum hat er kommen sehen. Maschinen. Wie fühlt sich diese Invasion im Körper an? Wie können Körper sich von dieser Gewalt befreien? Was, wenn die von oben verordnete Lösung – der Krieg – fehlschlägt? Weil es, gelinde gesagt, tatsächlich eine sehr dumme Lösung ist, da sie zur Eskalation, zum ewigen Krieg, zum Tod führt.

Diese Fragen sind aber nicht präzise genug. Die Frage, um die es mir eigentlich geht, weil sie verdrängt wurde, ist folgende: Wie genau wirkt sich das Ganze auf die Körper von Frauen aus? Die Bäuerin wird zur Arbeiterin und vielfachen Mutter, weil das von ihr verlangt wird. Sie soll ihren eigenen Körper opfern und weitere Körper „produzieren“ – Körper, die die Unterdrückung durch das brutale System ertragen oder unterstützen. 
Doch auf einmal tanzt ein Körper. Ein Körper wagt es zu tanzen in dieser Hauptstadt Berlin, in der einige Körper einen Ausweg aus der Sackgasse suchen. Sicher findet dieser Körper Befreiung, er wird jedoch marginalisiert. Das ist zu schwer für einen Körper. Die Strafe ist zu groß. 

Anita Berber stellt ein Symbol für die Dekadenz der Weimarer Republik dar – diesem gescheiterten Versuch zwischen den Kriegen, eine Republik in Deutschland einzuführen. Man sieht in ihr eine Art expressionistische Figur aus einer Chaplin-Komödie der wilden Zwanziger. Eine Frau, die ein historisches Versagen verkörpert. Was für eine überzogene, grausame Vorstellung! Anita würde vor dieser Vereinnahmung fliehen, auch wenn er ihr ein Platz im Himmel garantiert hätte. Ihr Körper musste sich auf einen ungleichen Kampf einlassen und wurde dafür bestraft, aber nicht besiegt. Von welcher Dekadenz kann denn da die Rede sein? 

Als kleines Kind in Lomas de Zamora, einer Provinz von Buenos Aires, war ich mit einem Mädchen aus dem Viertel befreundet, einer Nachbarin, die die <i>Deutsche Schule</i> besuchte. Eines Tages lud sie mich zu ihrer Geburtstagsfeier ein. Ihre Mitschüler*innen waren auch da. Sie spielten ganz andere Spiele als ich, ein Mädchen, das die normale oder <i>konventionelle</i> – und das sage ich mit dem ganzen Sarkasmus, den ich aufbringen kann – und <i>katholische</i> Schule besuchte. Eine Jungenschule wohlgemerkt, ich selbst bin Transfrau. Aber das ist eine andere Geschichte. Oder nein. Ist es nicht.`,
                `<#image>7_2.jpg</#image>
<h5><#c>Bild: Ixchel Mendoza Hernández and Leon Eixenberger</#c></h5>`,
                `Eines dieser mir unbekannten Spiele war das Seilspringen. Sie wollten mich mitspielen lassen, ich versuchte zu springen, scheiterte allerdings gleich beim ersten Anlauf kläglich. Mein Versagen steckte ich weg, ohne mich zu schämen und übte alleine weiter und scheiterte wieder. Erst vor ein paar Monaten unternahm ich den jüngsten Versuch und meine Vermutung bestätigte sich: Ich kann immer noch nicht Seilspringen. Macht nichts, ich werde es weiter versuchen, bis ich es kann. Oder bis es mir ein weiteres Mal misslingt. 
Damals aber bereitete mir dieses neue und unvertraute Schauspiel sehr viel Freude. Es gab verschiedene Arten, Kraft auf das Seil auszuüben, während die Mitspielenden sprangen. Eine dieser Arten hieß „picante“ („scharf“), also sehr stark. Die Kinder zählten laut und, wenn ich mich richtig erinnere, zählten sie rückwärts. Der Countdown wurde andauernd wiederholt und die Drehungen des Seiles zunehmend schneller, bis das springende Kind nicht mehr konnte. Dann musste es gehen und wurde für eine Runde oder mehrere ausgeschlossen, bis es sich von den Anstrengungen erholt hatte. 
Der Ausschluss wurde sozusagen als Strafe angesehen. Wer ausgeschlossen war, musste „nach Berlin gehen“. Dies wurde explizit gesagt. Alle, die ausgeschlossen wurden, mussten sich irgendwann „nach Berlin“ begeben. Für mich, ein Mädchen, das in einem Vorort von Buenos Aires aufwuchs, war es zweifelsohne das erste Mal oder eines der ersten Male, dass ich das Wort „Berlin“ hörte. Was ich damals nicht begriff, war der Umstand, dass ich mich (auch) von Anfang an in Berlin befand. Da ich nicht am Spiel teilnehmen konnte, war ich bereits „bestraft“ beziehungsweise ausgeschlossen oder „in Berlin“.
Wie gesagt, ich bin nicht auf die <i>Deutsche Schule</i> gegangen. Ich habe Deutsch erst als Erwachsene gelernt, befand mich aber „immer“ in Berlin. Irgendwie war ich schon als kleines Kind bei der Geburtstagsfeier meiner Nachbarin nach Berlin ausgewandert, in eine Art Parallelwelt. Also schon viele Jahre bevor ich 2012 tatsächlich auswanderte. Heißt das, dass Berlin die Unangepassten bemuttert, jene, die ausgeschlossen wurden oder sich ausgeschlossen fühlen? Ich glaube fast, dass es manchmal so ist, auch wenn die Mieten in dieser Stadt immer weiter steigen. Oder ist Anita Berber die Mutter der Ausgeschlossenen? Musste ich, da sie nicht mehr in unserer Dimension weilt, als Teil eines Rituals hierherziehen, um selber diese Mutter zu werden? Aber auch das ist eine andere Geschichte, oder auch nicht. Ist die Stadt feindlicher geworden, weshalb wir mehr über Anita sprechen sollten und müssen? Oder gibt es weiterhin dieses implizite Einverständnis zwischen Berlin und uns?

Das erste Tanzstück, das ich jemals sah, war „La estrategia de la pecera“ („Die Fischglasstrategie“). Ein befreundeter Musiker, der Teil der Aufführung war, hatte mich dazu eingeladen. Das Thema lautete „Wir sind im Krieg“. Die Gruppe hieß <i>La Brizna</i> und wurde von der argentinischen Butohtänzerin Rhea Volij geleitet. Die Tänzerinnen der Gruppe (unter ihnen auch Andrea Chama, die später meine erste Butohlehrerin werden sollte) liefen durch die dunklen Gänge des IMPA, einer Aluminiumfabrik, die von ihren Arbeiter*innen vor dem Bankrott gerettet worden war und die sie zum Teil in ein Kulturzentrum umfunktioniert hatten. 
Die Tänzerinnen liefen zwischen den Aluminiumstücken umher, zwischen den Maschinen, und widersetzten sich einem Stand der Dinge, der sie zwingen wollte, die Realität wahrzunehmen als seien sie Fische in einem Aquarium. Sie befanden sich „im Krieg“ gegen dieses Dispositiv. Manchmal ist der Krieg spürbar, auch wenn angeblich Frieden herrscht, manchmal – oder fast immer – ist Tanzen die beste Form gegen den Krieg vorzugehen, oder sich dagegen zu wehren, damit es gar nicht erst zum Angriff kommt. 

Die Bilder von diesen Fischfrauen – diesen Sirenen – die von den umherlaufenden Musiker*innen inmitten dieser postindustriellen Apokalypse der 1990er Jahre in Argentinien begleitet wurden, kurz vor Beginn der großen Krise 2001 – hatten eine entscheidende Wirkung auf mich, wie mir später klar wurde. Denn die Apokalypse wurde zu unserer neuen Natur oder – insofern wir von biblischen Metaphern absehen – zu einem irreparabelen, gescheiterten und kaputten Betriebssystem.

Anita Berber liegt in einem nach ihr benannten Park in Berlin begraben. Dieser Ort war früher der St. Thomas-Friedhof, auf dem 1978 die letzte Beerdigung stattfand. Heute weist nur noch wenig auf sie hin: Ihr Grab ist unauffindbar, man weiß weder wo sie begraben liegt, noch, ob es jemals einen Grabstein gab.
Anita starb am 10. November 1928 im Alter von 29 Jahren. Sie war schwer an Tuberkulose erkrankt, Alkohol- und Drogen hatten ihren Körper stark in Mitleidenschaft gezogen. 
Ich betrete den Park und sehe, dass ein paar Menschen sich am Eingang zusammengefunden haben, um Bier zu trinken. Sie versperren die Sicht auf die Informationstafel, die erklärt wer Anita war und warum der Park nach ihr benannt wurde. Das heißt, die einzige Spur, die von ihr in dieser Stadt zu finden ist, wird von denjenigen verdeckt, die sich hier treffen. Ich grüße trotzdem nett und bahne mir den Weg zur Tafel. Als ich davor stehe und Fotos mache, nähert sich ein etwa 60-jähriger Typ mit Glatze und Ohrring, stellt sich neben mich und zwinkert mir zu. 
Ich entferne mich schnell, bleibe mit meinen Gedanken aber bei der Infotafel. In diesem Park gibt es nicht nur Menschen, die sich außerhalb des städtischen Raums zu befinden scheinen, als gehörten sie einzig und allein in diesen Park, auch 20 verschiedene Vogelarten besuchen diesen Ort. Vielleicht ist das ein weiteres Anzeichen für Anitas Präsenz in diesem Park der „komischen Vögel“, in dem auch ich mich – Zufall oder auch nicht – wie zu Hause fühle. 

Eine weitere Eigenschaft verschiedener Epochen ist, dass sie sich gegenseitig auf eine verzerrte Art und Weise reflektieren oder widerspiegeln. Marx zufolge wiederholt sich Geschichte als Tragödie oder Farce. Es gibt Epochen in unterschiedlichen Länder, die komplexe und seltsame Verbindungspunkte miteinander haben, auch wenn man sie auf den ersten Blick nicht wahrnimmt. Gerade deshalb lohnt es sich, meiner Meinung nach, sie miteinander ins Verhältnis zu setzten. Als wäre die argentinische Hyperinflation – die bis zu 800 % pro Monat erreichte und laut Raúl Alfonsín, dem ersten Präsidenten nach der letzten Diktatur des Landes, die Vorstellung einer Demokratie untergrub, die „ernähren, heilen und bilden“ sollte –ein bisschen oder gewissermaßen eine Art „Weimar“ gewesen. 
Als wäre das Bild von meiner ersten Butohlehrerin Andrea – als improvisierte Rettungskraft zwischen den Trümmern, gleich nach dem Anschlag auf die Zentrale der jüdischen Gemeinde AMNIA im Stadtteil Once in Buenos Aires Mitte der 1990er Jahre, bei dem 85 Menschen ums Leben kamen und Hunderte verletzt wurden – auch ein bisschen oder gewissermaßen Weimar. 


Im Alter von sieben Jahren wurde ich wegen einer Bauchfellentzündung operiert. Nachdem ich mehr als zwei Monate liegend im Bett verbracht hatte, bat mich ein Arzt, zu laufen. Ich tat den ersten Schritt und fiel direkt auf die Nase. Ich konnte nicht laufen, meine Muskeln hatten sich zurückgebildet und ich musste das Laufen erst wieder lernen. Diese Episode vergaß ich kurz danach wieder. Erst Ende der 1990er/Anfang der 2000er Jahre musste ich wieder an sie denken: Damals spielte ich in einer Punkband, unsere Proben fanden im Süden des Großraums Buenos Aires statt. Wir nahmen jede Probe auf, um sie uns in den Pausen anzuhören. Dabei begannen wir, fast ohne es zu merken, zu der Musik zu tanzen – dieses ungeplante und spontane Tanzen hat mir einen neuen körperlichen Raum und ein neues körperliches Bewusstsein eröffnet.
`,
                `<#image>7_1.jpg</#image>
<h5><#c>Bild: Ixchel Mendoza Hernández and Alexander Binder</#c></h5>`,
                `Auf einmal konnte ich auch wieder zurückschauen: Mir wurde klar, dass die Bauchfellentzündung für mich eine Art Bestätigung darstellte für den Schaden, den das Dispositiv in mir angerichtet hatte. Ich hatte nicht nur meinen ursprünglichen Gang verloren, sondern hatten auch angefangen, meine Weiblichkeit zu verdrängen. Ja, um weiterleben zu könne, durfte ich keine Frau sein (damals noch ein Mädchen) – zumindest für eine Weile.
Als Anita auf der Bühne stand, gab es auch Tänzerinnen, die nackt auftraten, um griechisch-römische Statuen nachzustellen. Anita jedoch befreite ihre Nacktheit von einer solchen Bedeutung und das wirkte zutiefst verstörend, „nur so“ eine Vulva zu sehen (hah, nur „einfach so?!“) an einem öffentlichen Ort. So lautete zumindest das Argument einiger empörter Zuschauer*innen. Einer unter ihnen verfasste sogar 1926 einen Brief an die Polizei, in dem er Anita anzeigte und schrieb, dass „die Tänzerin unzweifelhaft ganz erheblich die Sittlichkeit gefährde und das Schamgefühl in unerhörter Weise verletze.“ 
Dabei erregt es heutzutage kaum ein Gemüt, wenn Frauen auf der Straße oder auf ihren Handys einen Penis, „einfach so“, zu sehen bekommen. Das ist zur Selbstverständlichkeit geworden. Zum Schluss möchte ich noch die Performerin erwähnen, die in der Berliner U-Bahn Fahrgäst*innenAnstecker mit jenen „unverlangten Penisfotos“ anbietet, die sie im Laufe der Jahre bekommen hat. 
Sollten wir uns einfach daran gewöhnen und uns nicht weiter beschweren? Wird das im Ernst von uns erwartet? Gleichzeitig scheint das Zeigen der Vulva im Kontext einer künstlerischen Darbietung (immer noch?) ein Affront gegen die öffentliche Ordnung zu sein. 

Von den Proben mit der Punkband, über die Erinnerung an das Krankenhaus bis hin zu den Tanzstunden, um das, was ich verloren glaubte, wenigstens ein bisschen zu verinnerlichen (oder wiederherzustellen). In der Zeitung lese ich über „die Esquizoonautas“, das Tanzduo von Laura Veiga und Fabio Rizzo, und darüber wie die beiden durch das Tanzen ihre jeweiligen Krankheiten heilten. Ich spürte Fabio auf und er lud mich zu seinem angolanischen Capoeira-Kurs ein, den er mit Laura im Centro Cultural Rojas in Buenos Aires gab. Ich hatte keine Ahnung, was angolanische Capoeira war. 
Ich nahm dennoch teil und lernte, was Fabio (besser gesagt Meister Cayó) als „Sturzcapoeira“ bezeichnete: „Wenn ich falle, richte ich mich mit Würde wieder auf“, sagte er, warf sich auf den Boden und erhob sich, indem er ein Bein über seinen Kopf schwang, ohne sich mit den Armen zu stützen. „Denn fallen werden wir immer. Also müssen wir lernen zu fallen und uns aufzurichten“, erklärte er. 
Ein weiteres wiederkehrendes Element der Capoeira-Trainings der Ezquizoonautas war, dass wir uns mit sprachlichen Gemeinplätzen auseinandersetzten, sie inszenierten und verschiedene Vorschläge herausarbeiteten, um sie zu dekonstruieren. Bedeutet es zum Beispiel, wenn man jemanden den Rücken zuwendet, beleidigt das Weite zu suchen oder sucht man mit dieser Geste vielleicht eine kleine Massage zu ergattern? 

Es fällt mir nicht leicht, mich daran zu erinnern, was in diesen Unterrichtsstunden alles passiert ist – nicht nur weil seitdem viel Zeit vergangen ist: Fabio wurde wieder krank, aber dieses Mal nicht wieder gesund, was mich bis heute traurig macht (manchmal kommen mir beim Schreiben die Tränen), und auch, weil all das in die Zeit vor der Wirtschaftskrise in Argentinien 2001 gehört. Wieder einmal markierte das dysfunktionale Dispositiv Körper, machte sie krank und disziplinierte sie. Ein langwieriger, verlustreicher Prozess, der bis ans Äußerste geht und sich dann entlädt. Diese Körper hatten schon das Glück erfahren, was eine Befreiung vom Dispositiv bedeutet, und sei es auch nur für einen Moment. 
Als ob der „Konsistenzplan“, den der Künstler Silvio Lang vorschlägt, wenn er von der Regie eines Bühnenwerkes spricht, auf unser eigenes Leben, auf die Inszenierung unserer persönlichen Sphäre, anwendbar wäre (und so ist es tatsächlich). Uns bleibt nicht viel mehr übrig denn als Teile einer „empfindsamen Kriegsmaschine“ zu fungieren – „wie die Hexe, die sie nicht verbrennen konnten“, wie Partisan*innen in einem Bürgerkrieg zu sein oder Architekt*innen einer „antifaschistischen Agenda“. Um es mit Walter Benjamin zu sagen: „Auf die Ästhetisierung des Krieges antworten wir mit der Politisierung der Kunst.“ Oder genauer gesagt, mit der Politisierung unserer Körper. 
Es ist die ungezügelte, verzweifelte Freude von Anita, die dem männlichen Publikum, der Kriegstreiberei und der patriarchalischen Ausbeutung durch Tanz und Worte die Stirn bietet. Es ist Fabio, der sich trotz Chemotherapie wieder aufrichtet. Es ist diese unbändige Freude, die ich verspürte, als ich meine Transition anfing. Einfach so, denn wenn ich falle, richte ich mich mit Würde wieder auf. 

In Buenos Aires – war es Ende der 1990er Jahre oder schon in den 2000ern? – besuchte ich ein Stück des japanischen Butohmeisters Ko Murobushi. Das Theater lag sehr weit von meinem Stadtteil entfernt, aber ich entschloss mich trotzdem alleine nach Hause zu gehen. Ich war so aufgewühlt, dass ich mich nicht mal von sehr engen Freund*innen verabschiedete, von denen einige auch Mitschüler*innen von mir in der Butohklasse des IMPA waren. Wofür Worte? Wurde da etwas dargestellt? Oder existierte Ko auf der Bühne einfach? Und wenn er einfach existierte, dann bleibt da ein Rest oder ein Sediment, das sich nicht in Worte fassen lässt. 

Wenn ich Anita tanzen sehe, fühle ich zum Teil diese Ohnmacht, die Unmöglichkeit, davon zu sprechen, die ich auch beim Butoh verspürt habe. Was ist, wenn diese blockierten Gefühle, die sich nicht in Worte fassen lassen, nur der Hinweis auf einen zukünftigen Tanz sind? Was wäre, wenn ihr Tanz gar nicht unterbrochen wurde? Und die Energie dieses Tanzes tatsächlich eine andere Form angenommen hat, der Tanz selbst aber weitergeht? Könnte es sein, dass der Tanz in den Dingen weiterbesteht, solange bis seine Schwingung auf unsere Köper übergeht und sie wieder zum Tanzen bringt? Kann es sein, dass wir den Kriegstanz wieder tanzen müssen, um Frieden erneut zu finden? 
Ich muss an das Lied „Some Velvet Morning“ denken: <i>„Was wir kennen sind Blumen, was wir hegen sind Geheimnisse. Lerne viel von uns, schau uns an, aber berühre uns nicht“</i>.`
            ],
            subpages: []
        }, {
            title: "Dance stories",
            lang: "de",
            id: "dance_stories",
            bg: {
                start_col: "white",
                col: "#ffff6480",
                end_col: "white",
                type: "gradient",
                elements: [
                    {
                        type: "perlin",
                        text: "                                                          •                                                          ",
                        s: 1,
                        h: 2,
                        x: -0.4,
                        y: -0.4,
                        x_skew: 0.2
                    },
                    {
                        type: "perlin",
                        text: "                                                          •                                                          ",
                        s: 1,
                        h: 2,
                        x: -0.4,
                        y: -0.4,
                        x_skew: 0.2
                    },
                    {
                        type: "perlin",
                        text: "                                                          •                                                          ",
                        s: 1,
                        h: 2,
                        x: -0.4,
                        y: -0.4,
                        x_skew: 0.2
                    }
                ]
            },
            author: "",
            content: [
                `Drei Audio-Interviews, in denen unsere Interviewpartnerinnen ihre besondere Beziehung zum Tanz mit den Hörer*innen teilen. Wir erfahren, welche Rolle Tanz für die Befragten in der Vergangenheit, Gegenwart und in einer imaginierten Zukunft einnimmt.`,
                `(Sounds: Hyewon Suk)
    
    
    `
            ],
            subpages: [
                {
                    title: "Sabine",
                    content: [
                        `Nach dem Tod ihres Mannes und zu Beginn ihres Ruhestandes schloss sich Sabine einer Tanzgruppe für ältere Menschen an. In diesem Gespräch erzählt sie, wie sie diesen Raum für sich entdeckte und was er ihr bedeutet. Wir erfahren, wie wichtig Tanz für Sabine als soziale und körperliche Praxis bereits war, bevor sie sich der Gruppe anschloss, und wie wichtig er auch in ihrer jetzigen Lebensphase noch ist.`,
                        `<#c><#audio>./dance/1_dance-stories-sabine.wav</#audio>
    <h5><#c>*Audio nur auf Deutsch</#c></h5></#c>`
                    ]
                }, {
                    title: "Gisela",
                    content: [
                        `<#image>8_1.jpg</#image>
    <h5><#c>Bild: Jamin İhraç</#c></h5>`,
                        `Gisela lebt seit 1962 mit ihrem Mann in der gleichen Wohnung in Berlin-Neukölln, in der auch der gemeinsame Sohn aufwuchs. Früher ging das Paar an den Wochenenden im Ballhaus Rixdorf auf dem Kottbusser Damm tanzen, damals „Rixi“ genannt. In unserem Gespräch reflektiert Gisela, warum das Tanzen in vielen sozialen Kontexten in ihrem Umfeld heute eine geringere Rolle spielt als früher.
    
<#t>Weiterführender Link zur Geschichte des Ballhaus Rixdorf: <a href="https://www.kreuzberger-chronik.de/">Kreuzberger Chronik: Das Ballhaus Rixdorf - Sie lesen das Original! aus Berlin-Kreuzberg (kreuzberger-chronik.de)</a> (letzter Zugriff: 02.04.22)
</#t>`,
                        `<#c><#audio>./dance/2_dance-stories-gisela.wav</#audio>
    <h5><#c>*Audio nur auf Deutsch</#c></h5></#c>`
                    ]
                }, {
                    title: "Tabea Antonacci, Julie Peters, Kata Kwiatkowska and Nikola Allaire",
                    content: [
                        `Tabea, Julie, Kata und Nikola sind vier junge Frauen in ihren Zwanzigern. In diesem Gespräch erzählen sie, wie sie mit Tanz in Berührung kamen. Im Vordergrund steht das Dance Intensive Programm der Tanzfabrik Berlin, das sie alle absolviert haben. Sie sprechen darüber, was Tanz für sie bedeutet, welche Entwicklungen die kollektive Auseinandersetzung mit Tanz bei ihnen auslöste sowie über ihre Gedanken, Tanz zum Beruf zu machen. Ein Einblick für alle, die bisher wenig oder gar nicht mit der zeitgenössischen Tanzszene in Berührung kamen.`,
                        `<#c><#audio>./dance/3_dance-stories-tabea-julie-kata-nikola.mp3</#audio>
    <h5><#c>*Audio nur auf Englisch</#c></h5></#c>`
                    ]
                },
            ]
        },
        clothes_manifesto,
        berlin_istanbul,
        performance_art_gal,
        {
            title: "Public Encounters",
            lang: "de",
            id: "public_encounters",
            author: "",
            bg: {
                start_col: "white",
                col: "#a0c34b80",
                end_col: "white",
                type: "gradient",
                elements: [
                    {
                        type: "perlin",
                        text: "                      •                      ",
                        s: .75,
                        h: 2,
                        y: -0.45,
                        x: -0.45,
                        x_skew: 1.5
                    }
                ]
            },
            content: [
                `Um Einblicke, Gedanken, Meinungen und Eindrücke über die zeitgenössische Berliner Tanzszene zu sammeln, haben wir Tanzpublikum nach Aufführungen interviewt, sind auf die Straße gegangen, und haben dort Passant*innen befragt, die eventuell nichts oder nur wenig mit professionellem Tanz zu tun haben.

`,
                `<#c><#audio>./encounters/1 - Alessandro Maggioni (Media Designer, 44, male).mp3</#audio>                <#audio>./encounters/2 - Petra Röder (Teacher, 52, no gender data).mp3</#audio></#c>
<#c><#audio>./encounters/3 - Tomaz Aquino Gonzalves_ Designer, 36, male.mp3</#audio>                <#audio>./encounters/4 - Amit Baz_ Developer, 27, male.mp3</#audio></#c>
<#c><#audio>./encounters/5 - Hendrik Hebben, Dancer, male, 27 + Julian Bender Employee Musical, male, 25.mp3</#audio>                <#audio>./encounters/6 - Julia Studentin.mp3</#audio></#c>
<#c><#audio>./encounters/7 - Moritz_ Photographer, 35, male (ONLY FIRST NAME).mp3</#audio>                <#audio>./encounters/8 - Janine_ Product Designer, 36, female (ONLY FIRST NAME).mp3</#audio></#c>
<#c><#audio>./encounters/9 - Bastian_ Designer, 38, male (ONLY FIRST NAME).mp3</#audio>                <#audio>./encounters/10 - Mira_ Art Scientist, 30, female (ONLY FIRST NAME).mp3</#audio></#c>`,
                `<#image>12_1.jpg</#image>
<h5><#c>Bild: Kat Válastur and Ixchel Mendoza Hernández</#c></h5>`,
                `<#c><#audio>./encounters/11 - Claudia_ Rentnerin, 65, female (ONLY FIRST NAME).mp3</#audio>                <#audio>./encounters/12 - ANONYMOUS_ Pensioner, 65, female (Brigitte).mp3</#audio></#c>
<#c><#audio>./encounters/13 - ANONYMOUS_ German Teacher, 41, female (Ana).mp3</#audio>                <#audio>./encounters/14 - ANONYMOUS + NO INFO (Florentine).mp3</#audio></#c>
<#c><#audio>./encounters/15 - ANONYMOUS_ Choreographer, 30, female (Kiana).mp3</#audio>                <#audio>./encounters/16 - ANONYMOUS_ PROGRAMMER 51, male (Georg).mp3</#audio></#c>
<#c><#audio>./encounters/17 - ANONYMOUS_ CURATOR, 53, female (Daniela).mp3</#audio>                <#audio>./encounters/18 - ANONYMOUS_ Physiotherapist 53, f (Roseli).mp3</#audio></#c>
<#c><#audio>./encounters/19 - ANONYMOUS_2Students,40,f(OngeongKim+SanghwaNam).mp3</#audio>                <#audio>./encounters/20 - ANONYMOUS_Actress,55,female+Archivist,59,female(Anke+Kerstin).mp3</#audio></#c>
<#image>12_2.jpg</#image>
<h5><#c>Bild: Ixchel Mendoza Hernández and Kat Válastur</#c></h5>`
            ],
            subpages: []
        }, {
            title: "CREDITS",
            lang: "de",
            id: "credits",
            bg: {
                col: "var(--blu)",
                type: "solid",
                text_col: "white",
                heading_col: "white"
            },
            content: [
                `<h4><#c>KÜNSTLERISCHES TEAM</#c></h4> <#c>Ixchel Mendoza Hernández, Jasmin İhraç, Sina Kießling, Lena Klink, Ana Laura Lozza (bis Herbst 2021), Camila Malenchini, Kat Válastur</#c>
<h4><#c>WEBDESIGN</#c></h4><#c>Dora Đurkesac</#c>
<h4><#c>WEBENTWICKLUNG</#c></h4><#c><a style='color: inherit;' href='https://rudytak.github.io/portfolio/'>Ondřej Sedláček</a></#c>
<h4><#c>LEKTORAT ENGLISH</#c></h4><#c>Louise Trueheart</#c>
<h4><#c>LEKTORAT GERMAN</#c></h4><#c>Lara Spielmann</#c>
<h4><#c>ÜBERSETZUNGEN</#c></h4><#c>Gegensatz Translation Collective Berlin</#c>
<h4><#c>KOORDINATION LEKTORAT&ÜBERSETZUNGEN</#c></h4><#c>Jasmin İhraç</#c>
<h4><#c>AUTOR*INNEN</#c></h4><#c>Lena Klink, Sybille Schmidt, Louise Trueheart, Margarita Tsomou, Lea Marie Uría, Kat Válastur</#c>
<h4><#c>TONBEARBEITUNG PUBLIC ENCOUNTERS</#c></h4><#c>Vagelis Tsatsis</#c>
<h4><#c>TONBEARBEITUNG DANCE STORIES</#c></h4><#c>Ixchel Mendoza Hernández</#c>
<h4><#c>MUSIK</#c></h4><#c>Hyewon Suk</#c>
<h4><#c>PRODUKTIONS-ASSISTENZ</#c></h4><#c>Camila Malenchini, Lena Klink</#c>
<h4><#c>PRODUKTION</#c></h4><#c>Sina Kießling</#c>
<h4><#c>COVERVIDEO</#c></h4><#c>Ausschnitt aus dem Kurzfilm "Ultra Sensored" von Kat Válastur</#c>
`
            ],
            subpages: []
        },
        contact,
        imprint
    ],
    // "es":[]
};
