<script lang="ts">
    import p5 from "./p5.min.js"

    export let text = "SPREAD";
    export let s = 1;
    export let h = 2;
    export let x = 0;
    export let y = 0;
    export let x_skew = 1;
    export let y_skew = 1;

    const uuid = Math.round(Math.random()*1e6).toString();

    const sketch = (p5) => {
        const string = text; //words to be displayed
        const fontFile = "./resources/fonts/FontsFree-Net-Proxima-Nova-Xbold.otf";
        const showText = true; //whether or not to have an overlay of the original text (in the background color)
        const textAlpha = 0.1; //the alpha of the text if displayed (low value will make it slowly fade in)
        const backgroundColor = 255; //kinda self-explanatory
        const strokeAlpha = 20; //the alpha of the lines (lower numbers are more transparent)
        const strokeColor = [70,0,255]; //the line color
                    

        const fontSampleFactor = 0.5; //How many points there are: the higher the number, the closer together they are (more detail)

        const noiseZoom = 0.011; //how zoomed in the perlin noise is
        const noiseOctaves = 4; //The number of octaves for the noise
        const noiseFalloff = 0.9; //The falloff for the noise layers

        const zOffsetChange = 0; //How much the noise field changes in the z direction each frame
        const individualZOffset = 0; //how far away the points/lines are from each other in the z noise axies (the bigger the number, the more chaotic)

        const lineSpeed = .4; //the maximum amount each point can move each frame

        const newPointsCount = 9; //the number of new points added when the mouse is dragged


        var font;
        var points = [];
        var startingPoints;
        var textW;
        var size;

        p5.preload = () => {
            font = p5.loadFont(fontFile);
            //setInterval(p5.setup, 120000)
        }

        p5.setup = () => {
            var c = p5.createCanvas(window.innerWidth, h*window.innerHeight);
            c.parent("p5-"+uuid);
            c.elt.classList.add("perlin");
            //console.log(font);
            //c.elt.style.marginTop = `calc(${y}*(100vh - ${h * window.innerHeight}px))`
            //c.elt.style.transform = `translateX(calc(${x-0.5}*(100vw - ${w * window.innerWidth}px)))`

            //p5.background(backgroundColor);
            p5.textFont(font);
            p5.textSize(300);
            p5.fill(backgroundColor, textAlpha);
            p5.stroke(70,0,255, strokeAlpha);
            p5.noiseDetail(noiseOctaves, noiseFalloff);

            textW = p5.textWidth(string);

            size = 0.75 * p5.width * 300/textW;
            p5.textSize(size);
            textW = p5.textWidth(string);

            var vmin = Math.min(window.innerWidth, window.innerHeight)/100
            p5.strokeWeight(0.05*vmin);
            startingPoints = font.textToPoints(string, .5*(p5.width - textW), .5*(window.innerHeight), size, {"sampleFactor": fontSampleFactor});

            points = [];
            for (let p = 0; p < startingPoints.length; p++) {
                points[p] = startingPoints[p];
                points[p].zOffset = p5.random();
            }
        }

        p5.draw = () => {
            if(showText){
                p5.noStroke();
                p5.text(string, p5.width / 2 - textW / 2, p5.height);
                p5.stroke(...strokeColor, strokeAlpha);
            }
            for (let pt = 0; pt < points.length; pt++) {
                let p = points[pt];
                let noiseX = p.x * noiseZoom;
                let noiseY = p.y * noiseZoom;
                let noiseZ = p5.frameCount * zOffsetChange + p.zOffset*individualZOffset;
                let newPX = p.x + x_skew * p5.map(p5.noise(noiseX, noiseY, noiseZ), 0, 1, -lineSpeed, lineSpeed);
                let newPY = p.y + y_skew * p5.map(p5.noise(noiseX, noiseY, noiseZ + 214), 0, 1, -lineSpeed, lineSpeed);
                p5.line(
                    (x + 0.5 - s/2)*window.innerWidth + p.x*s, 
                    (y + 0.5 - s/2)*window.innerHeight + p.y*s, 
                    (x + 0.5 - s/2)*window.innerWidth + newPX*s, 
                    (y + 0.5 - s/2)*window.innerHeight + newPY*s);
                p5.stroke(70,0,255);
                p.x = newPX;
                p.y = newPY;
            }
        }

        p5.keyPressed = () => {
            if (p5.key == 's') {
                p5.save();
            }
        }

        p5.windowResized = () =>{
            p5.setup();
        }
	};
    setTimeout(()=>{
        const p5Intance = new p5(sketch);
    },10)
</script>

<div id="p5-{uuid}">
</div>