export function scrlTo(id) {
    document.getElementById(id).scrollIntoView({
        behavior: 'smooth'
    });
}
export function isMobile() {
    return innerWidth < 800; //innerWidth < innerHeight
}
export var social_links = {
    "envelope": "mailto:contact@spread-magazine.com",
    "facebook": "https://www.facebook.com/Spread-online-magazine-110470971680626",
    "instagram": "https://www.instagram.com/spreadonlinemagazineberlin",
    "vimeo": "https://vimeo.com/user176340135",
    "soundcloud": "https://soundcloud.com/user-59722073"
};
