import HomeSvelte from "./pages/Home.svelte";
import E404Svelte from "./E404.svelte";
function isAccesible(min_subdirectory_count) {
    return (window.location.pathname.split("/").length > min_subdirectory_count);
}
const routes = [
    {
        name: '/',
        component: HomeSvelte,
    },
    {
        name: '404',
        path: '404',
        component: E404Svelte,
    }
];
export { routes };
