<script lang="ts">
    window.addEventListener("neek", ()=>{
        notneek = notneek;
    })
    $: notneek = (window as any).neek;   
</script>

<main class="content_wrap bg" name="ALLOW_LANG_CHANGE_EDITORIAL" style="background: var(--blu);">
    {#if notneek.lang == "en"}
        <h1 id="editorial" class="jumpable">
            EDITORIAL
        </h1>
        <h2>
            “Many animals shed their outer 
            layer. Molting is a way of ensuring the body grows and sets the creature up for the next stage of life.”
            <!-- &nbsp;<sup><small>1</small></sup> -->
        </h2>
        
        <p>    
        </p><p>    To spread. To extend, open up, to disperse, like water spilled from the springs of the earth, like the sensual expansion of the octopus‘ tentacles in the depth of the ocean, or the vibrations of love in a room full of people dancing, this magazine aspires towards a feminist modality of expansion. SPREAD is a digital platform that contains disparate elements and could contain even more. Taking Ursula K. Le Guin’s 1986 essay “The Carrier Bag Theory of Fiction'' as inspiration, SPREAD attempts to gather heterogeneous materials that will unfold and tell particular “stories.” These “stories” connect through the need to reflect on feminist ideas, life, and working conditions as (women) artists, as well as the visibility and accessibility of contemporary dance in Berlin vis-a-vis other communities. SPREAD could be contagious; we hope it will unfurl in future exchange with others, weaving a tapestry of feminist thinking between Berlin and other cities, countries, and communities.
        </p><p>    March 2020, marked by the economic and social disruption triggered by the corona virus pandemic, established new conditions for how life unfolds. Intense isolation was experienced in the first months. We were separated from loved ones and connection on a physical level was barely possible. Sensorial experiences with others through touch, embrace, and proximity – part of the physical expressions of life – turned into danger zones of contamination. Inside this post-apocalyptic scenario-turned-reality, sadness, despair, and fear were experienced within a precarious stillness that offered a chance to rethink and question. 
        </p><p>    In the summer of 2020, Kat Válastur initiated an online exchange between Ixchel Mendoza Hernández, Jasmin İhraç, Lena Klink, Ana Laura Lozza (until autumn 2021), Camila Malenchini, and Sina Kießling – all active in different ways in Berlin’s dance field. The lack of connection felt through the pandemic triggered basic questions about life, as well as questions regarding our artistic lives. Inside stillness and uncanniness, we reflected on our dance community and realized that the sense of separation and lack of debate we were feeling had been present before the pandemic as well. 
        </p><p>    The desire to rethink needs, concerns, and perspectives lead us to give shape to a space of exchange, establish a network of conversations, and thus contribute to the already existing efforts at communal resilience within the dance community. Welcome, SPREAD.
        </p><p>    We reflected on feminism and questioned how accessible contemporary dance is for other communities, over the course of a process that lasted almost two years. We invited writers and Berlin-based choreographers to talk about their lives and the working conditions in Berlin, as well as needs and desires for the future. We interviewed people in the theaters and in the streets, as well as our neighbors, family, and students about dance and how it feels to experience dance. 
        </p><p>    This space, which slowly took the shape of a collaboratively curated magazine, introduces new ways of experiencing connectivity. We would like to create a dynamic exchange among feminist artists and choreographers in order to discover ways of being together with our differences. SPREAD is a collection of stories, sci-fi imaginaries, theoretical reflections, personal experiences, and interviews, in the form of audio and video recordings and texts. You, visitors and readers, are invited to take a walk through this digital space, finding your own trajectory, and draw connections across the subjects and media published here. 
        </p><p>    SPREAD contains theoretical analyses and reflections on feminism by Sibylle Schmidt and Margarita Tsomou. In “On Bearing and Carrying, or Five Attempts at Unlearning the Killer Story” Sibylle Schmidt explores the notion of carrying and caring for others, of bearing knowledge and history, and asks how relationality could be seen as a resource for art and thinking.Margarita Tsomou’s essay, entitled “Attempt for a Planetary Feminism: Metabolism of the Social in Ecological Ruins,” sets forth ways in which a planetary feminism – as opposed to Western liberal feminism – could address the epistemological and planetary crisis in which we find ourselves today.
            <br>Two texts reference the figure of Anita Berber, the cabaret dancer and radical figure of 1920s Berlin. In Kat Válastur’s text “The Ashes of Anita Berber and the Confession of the Dust,” the figure serves as the thematic anchor for a sci-fictional story about interconnectivity of species and particles through time and space. Whereas in Lea Marie Uría’s text “Anita Berber: We Are at War,” Berber becomes a fictional character in an autobiographical framework, in which the author weaves a parallel between this iconic performer’s life and her own experiences and memories from growing up in Buenos Aires.
        </p><p>    To broaden our exchange and open up a dialogue on different subjects that concern our working and living conditions in Berlin as artists, we invited twelve colleagues from the dance field to take part in a moderated conversation about problems, wishes, and desires. The conversation was framed by a fictional element – the imaginary of an ecosystem – to which each guest was invited to contribute. The filmed recording of “Staged Talk: An exchange with dance practitioners in Berlin” has been added to this magazine as well.
        </p><p>    Also referring to the dance scene of Berlin during the pandemic is the written documentation of “The Club for Performance Art Gallery,” curated by Camila Malenchini. In “Excavating the Forever Interred – a dig into Season 1 of the Club for Performance Art Gallery,” Louise Trueheart stitches together the memories that artists who participated in the Club shared with her. These stories are about making art despite restricted conditions and closed theaters; it took place during the lockdown in February 2021.
        </p><p>    Reflecting on her journey from Istanbul to Berlin and back, dancer, choreographer, and teacher Leyla Postalcıoğlu talks about her living and working conditions in Germany and Turkey, as well as her emotional, political, and artistic attachment to both places in an interview entitled “Berlin-Istanbul: Interview with Leyla Postalcıoğlu.”
        </p><p>    “Dance Stories” and “Public Encounters” take a more investigative approach. Given our desire to broaden our perspectives in such a way as to include our immediate environments, we interviewed neighbors, students, and family, asking them about their relation to dance. These testimonials are captured in the three “Dance Stories.” For “Public Encounters,” we interviewed dance audiences in front of theaters and passers-by in the street about their opinions, feelings, and thoughts on dance. 
        </p><p>    Last but not least, SPREAD presents a short poem, manifesto, and score on the possibilities of how to be in, choose, and acknowledge the various fabrics around you. At the same time, “Manifesto of Clothes” by Lena Klink is a reminder to your consciousness about what you carry with you each day.
            <br>Through this magazine we aspire to strengthen the connections that run through our Berlin dance community. It is a first attempt towards a hope that its underlying ideas will contaminate and inspire others. We aspire to create an open space where political issues can be shared, addressed, and given force through the voice of feminist practitioners. 
            <br>“Don‘t agonize, organize!” 
        </p><p>    Ixchel, Kat, Camila, Jasmin, Lena 
                                 
        </p>

        <br>
        <br>
        <br>
        
        <h4 style="text-align: left; font-weight: inherit; margin: 0;">NOTES</h4>
        <br>
        <h5 style="text-align: left; font-weight: inherit; display: inline-flex; width: 100%; margin: 0;">
            <!-- <h5 style="margin: 0; margin-right: 1rem; font: inherit;"> 1. </h5>  -->
            <h5 style="margin: 0; font: inherit;">A-Z Animals <a href="https://a-z-animals.com/blog/10-animals-that-shed-their-skin/">https://a-z-animals.com/blog/10-animals-that-shed-their-skin/</a>, last accessed: 20/6/2022.</h5>
        </h5>
        <br>
        <br>
        <h5 style="text-align: left; font-weight: inherit; display: inline-flex; width: 100%; margin: 0;">
            <!-- <h5 style="margin: 0; margin-right: 1rem; font: inherit;"> 2. </h5>  -->
            <h5 style="margin: 0; font: inherit;">Kennedy, Flo (1971); The Verbal Karate of Florynce R. Kennedy, Esq., Ms. Magazine, <a href="https://msmagazine.com/2011/08/19/the-verbal-karate-of-florynce-r-kennedy-esq/">https://msmagazine.com/2011/08/19/the-verbal-karate-of-florynce-r-kennedy-esq/</a>, last accessed: 20/6/2022.</h5>
        </h5>
        {:else if notneek.lang == "de"}
        <h1 id="editorial" class="jumpable">
            EDITORIAL
        </h1>
        <h2>
            “Many animals shed their outer 
            layer. Molting is a way of ensuring the body grows and sets the creature up for the next stage of life.”
        </h2>
        <p>
            Spread. Ausdehnen, sich öffnen, verteilen, wie Wasser, das aus den Quellen der Erde sprudelt, wie die Entfaltung der Tentakel eines Tintenfisches in den Tiefen des Ozeans oder das Vibrieren in einem Raum voller tanzender Menschen – dieses Magazin strebt nach feministischen Formen der Ausbreitung. 
        </p><p>    In Anlehnung an Ursula K. Le Guins Essay „The Carrier Bag Theory of Fiction“ von 1986 unternimmt SPREAD – gedacht als digitale Plattform – den Versuch, unterschiedliche Materialien und mediale Formate zusammenzuführen, die sich im Zusammenspiel entfalten und Geschichten erzählen. Jene verbindet das Bedürfnis, Feminismus zu reflektieren, unsere Lebens- und Arbeitsbedingungen als Künstler*innen zu diskutieren sowie Sichtbarkeit und Zugänglichkeit von zeitgenössischem Tanz in Berlin zu hinterfragen. SPREAD könnte ansteckend sein: Wir hoffen, dass sich das Magazin im Austausch mit anderen stetig weiterentwickelt und sich ein Faden aus feministischem Denken zwischen Berlin und anderen Städten, Ländern und Communities entspinnt.
        </p><p>    März 2020 markierte mit dem Beginn der Corona Pandemie einen Bruch im wirtschaftlichen und sozialen Gefüge und schuf gleichzeitig neue Rahmenbedingungen für unsere Leben.
        </p><p>    Die ersten Monate waren von tiefgreifender Isolation geprägt. Getrennt von geliebten Menschen, war körperliche Nähe kaum möglich. Sinnliche Erfahrungen durch Berührung, Umarmung und Nähe mit anderen – Ausdrucksformen des Lebens – verwandelten sich in Gefahrenzonen der Ansteckung. In diesem zur Realität gewordenen postapokalyptischen Szenario erlebten wir auch Traurigkeit, Verzweiflung und Angst. Jene unsichere, unheimliche Stille bot aber gleichzeitig eine Gelegenheit zum Innehalten, Nachdenken und Hinterfragen.
        </p><p>    Im Sommer 2020 initiierte Kat Válastur einen Online-Austausch zwischen Ixchel Mendoza Hernández, Jasmin İhraç, Lena Klink, Ana Laura Lozza (bis Herbst 2021), Camila Malenchini und Sina Kießling, die im Tanzbereich tätig sind. Der durch die Pandemie empfundene Mangel an Verbundenheit warf grundlegende Fragen über unser Leben und unsere künstlerische Arbeit auf.
        </p><p>    Aus dem Wunsch heraus über Problematiken nachzudenken – wie das Gefühl fehlenden Zusammenhaltes, aber auch das Ausbleiben bestimmter Debatten innerhalb der Tanzcommunity –und zudem Bedürfnisse und Perspektiven zu reflektieren, entstand ein Netzwerk, ein Raum des Austauschs, aus dem sich wiederum die Möglichkeit ergab, zu bereits bestehenden Diskussionen und Bemühungen, um gemeinschaftliche Resilienz innerhalb der Tanz-Community, beizutragen. Willkommen, SPREAD.
        </p><p>    Im Laufe eines fast zweijährigen Prozesses reflektierten wir verschiedene Fragestellungen zu Feminismus und zur Zugänglichkeit von zeitgenössischem Tanz für andere gesellschaftliche Gruppen. Wir luden Autor*innen und in Berlin lebende Choreograf*innen ein, um über ihre Lebens- und Arbeitsbedingungen in Berlin zu sprechen, über Bedürfnisse und Wünsche für die Zukunft. Wir befragten Personen in Theatern und auf der Straße sowie unsere Nachbar*innen, Familienangehörigen und Studierenden zu ihren Erfahrungen mit (zeitgenössischem) Tanz.
        </p><p>    Dieser Raum, der allmählich die Form eines kollaborativ kuratierten Magazins annahm, soll dazu einladen einen dynamischen Austausch zwischen feministischen Künstler*innen und Choreograf*innen zu etablieren, um neue Wege des Zusammenseins zu erkunden.
        </p><p>    SPREAD ist eine Sammlung von Erzählungen, Science-Fiction-Geschichten, theoretischen Überlegungen, persönlichen Erfahrungen und Interviews in Form von Audio, Video und Text. Die Besucher*innen sind eingeladen, Spaziergänge durch diesen virtuellen Raum zu unternehmen – eigene Wege zu finden und Bezüge zwischen Themen und Medien herzustellen.
        </p><p>    SPREAD enthält theoretische Analysen und Überlegungen zu Feminismus von Sibylle Schmidt und Margarita Tsomou. In „Vom Tragen, oder: Fünf Versuche, die Killergeschichte zu verlernen" untersucht Sibylle Schmidt den Begriff des Tragens als Ausdruck der Fürsorge für andere, des Tradierens von Wissen und Geschichte, und fragt, wie Beziehungsgeflechte als Ressource für Kunst und Denken gesehen werden können. Der Text von Margarita Tsomou mit dem Titel „Attempt for a Planetary Feminism: Metabolism of the Social in Ecological Ruins“ zeigt Wege auf, wie ein planetarischer Feminismus – im Gegensatz zum westlichen liberalen Feminismus – die epistemologische und ökologische Krise, in der wir uns befinden, angehen könnte.
            
        </p><p>    Zwei Texte beziehen sich auf Anita Berber, einer radikalen Persönlichkeit im Berlin der 1920er Jahre. In Kat Válasturs Text „The Ashes of Anita Berber and the Confession of the Dust“ dient die Figur als thematischer Anker für eine Science-Fiction-Geschichte über die Verflechtung von Spezies und Partikeln durch Raum und Zeit. In Lea Marie Urías Text „Anita Berber: Wir sind im Krieg“ hingegen wird Berber zu einer fiktiven Figur in einem autobiografischen Rahmen, in dem die Autorin eine Parallele zwischen dem Leben dieser Ausnahmeperformerin und ihren eigenen Erfahrungen und Erinnerungen an ihr Aufwachsen in Buenos Aires zieht.
        </p><p>    Mit dem Ziel einen Dialog über verschiedene Themen zu eröffnen, die unsere Arbeits- und Lebensbedingungen als Künstler*innen in Berlin betreffen, luden wir zwölf Kolleg*innen aus der Tanzszene zu einem Gespräch über Problematiken, Wünsche und Perspektiven ein. Gerahmt wurde das Gespräch durch ein fiktives Element – dem Vorstellungsbild eines Ökosystems – zu dem jede*r der Eingeladenen einen Beitrag leisten konnte. Die filmische Aufzeichnung von „Staged Talk. An exchange between dance practitioners in Berlin“ ist ebenfalls in diesem Online-Magazin zu finden.
        </p><p>    Auch die schriftliche Dokumentation der von Camila Malenchini kuratierten Reihe „The Club for Performance Art Gallery“ bezieht sich auf die Berliner Tanzszene während der Pandemie. In „Excavating the Forever Interred – a dig into Season 1 of the Club for Performance Art Gallery“ fügt Louise Trueheart die Erinnerungen zusammen, die partizipierenden Künstler*innen mit ihr teilten. Diese Geschichten erzählen, wie es möglich ist, trotz Einschränkungen und geschlossenen Theatern – das Projekt fand während des Lockdowns im Februar 2021 statt – Kunst zu machen.
        </p><p>    Ausgehend von ihrem Weg von Istanbul nach Berlin und wieder zurück spricht die Tänzerin, Choreografin und Lehrerin Leyla Postalcıoğlu im Interview „Berlin-Istanbul: Interview mit Leyla Postalcıoğlu“ über ihre Lebens- und Arbeitsbedingungen in Deutschland und der Türkei sowie von ihrer emotionalen, politischen und künstlerischen Verbundenheit mit beiden Orten.
            
        </p><p>    Unseren Blick auch auf unser unmittelbares Umfeld richtend, interviewten wir Nachbar*innen, Studierende und Familienangehörige hinsichtlich ihres Verhältnisses zu Tanz. Diese Erfahrungsberichte sind in drei „Tanzgeschichten“ festgehalten. Für „Public Encounters“ befragten wir Tanzpublikum vor Theatern und Passant*innen auf der Straße zu ihren Ansichten, Gefühlen und Gedanken zum Tanz.
            
        </p><p>    Zu guter Letzt: ein kurzes Gedicht, Manifest und ein Score. Das „Manifesto of Clothes” von Lena Klink ist eine Erinnerung daran, was wir jeden Tag bei, mit und an uns tragen.
            
        </p><p>    SPREAD ist ein erster Versuch. Wir hoffen, dass die zugrunde liegenden Ideen andere anstecken und inspirieren werden und ein Raum entsteht, in dem politische Themen geteilt, angesprochen und durch feministische Stimmen gestärkt werden können.
        <br>    „Don‘t agonise, organise!”
            
        </p><p>    Ixchel, Kat, Camila, Jasmin, Lena 
            
        </p>

        <!-- <br>
        <br>
        <br>
        
        <h4 style="text-align: left; font-weight: inherit; margin: 0;">NOTES</h4>
        <br>
        <h5 style="text-align: left; font-weight: inherit; display: inline-flex; width: 100%; margin: 0;">
            <h5 style="margin: 0; margin-right: 1rem; font: inherit;"> 1. </h5> 
            <h5 style="margin: 0; font: inherit;">A-Z Animals <a href="https://a-z-animals.com/blog/10-animals-that-shed-their-skin/">https://a-z-animals.com/blog/10-animals-that-shed-their-skin/</a>, last accessed: 20/6/2022.</h5>
        </h5>
        <br>
        <br>
        <h5 style="text-align: left; font-weight: inherit; display: inline-flex; width: 100%; margin: 0;">
            <h5 style="margin: 0; margin-right: 1rem; font: inherit;"> 2. </h5> 
            <h5 style="margin: 0; font: inherit;">Flo Kennedy, The Verbal Karate of Florynce R. Kennedy, Esq., Ms. Magazine, 1971, <a href="https://msmagazine.com/2011/08/19/the-verbal-karate-of-florynce-r-kennedy-esq/">https://msmagazine.com/2011/08/19/the-verbal-karate-of-florynce-r-kennedy-esq/</a>, last accessed: 20/6/2022.</h5>
        </h5> -->
        {:else}
            <h1 id="editorial" class="jumpable">
                Something went wrong
            </h1>
        {/if}    
</main>

<style>
    main{
        color: white;
    }

    h1{
        font-weight: 100;
        font-family: CanelaReg;
        line-height: 125%;
        font-size: calc(6rem * var(--sc));
        padding: calc(10rem * var(--sc)) 0;
        margin: 0;
    }

    h2{
        font-family: 'Open Sans';
        font-size: calc(3.5rem * var(--sc));
        padding: 0 0 calc(3.5rem * var(--sc)) 0;
        margin: 0;
        line-height: 120%;

        -webkit-text-stroke: 1px white;
        color: var(--blu);
    }

    p{
        font-family: CanelaReg;
        font-size: calc(1.5rem * var(--sc));
        padding: calc(1rem * var(--sc)) 0;
        margin: 0;
        line-height: 130%;
        text-align: left;
    }

    a{
        color: inherit;
    }
</style>