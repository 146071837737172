<script lang="ts">
    import {Router} from "svelte-router-spa"
    import { routes } from "./routes";
</script>

<svelte:head>
    <title>Spread Magazine</title>

    <style lang="scss">
        :root{
            --blu: rgb(70,0,255);
            --pad: 20vw;
            --bar-w: 20vw;
            --sc: 1;
        }

        div.parsed.tab{
            margin-left: 8rem;
        }
        div.parsed.tab_s{
            margin-left: 2rem;
        }
        div.parsed.center{
            text-align: center;
        }
        div.parsed.right{
            text-align: right;
        }
        .page_cont h4, .content_wrap h4{
            font-family: 'Open Sans' !important;
            font-weight: bold !important;
            font-size: inherit;
            padding: 0;
            margin: 0;
            font-size: calc(1rem * var(--sc)) !important;
        }
        .page_cont h5, .content_wrap h5{
            font-family: 'Open Sans' !important;
            font-weight: bold !important;
            font-size: calc(.85rem * var(--sc)) !important;
            padding: 0;
            margin: 0;
        }
        img.parsed{
            margin-left: calc(-1*var(--pad) - 1rem);
            width: 102vw;
        }

        body{
            padding: 0px !important;
            overflow-x: hidden;
        }

        .content{
            width: 100vw;
            display: flex;
            flex-direction: column;
        }

        .content_wrap{
            min-height: 100vh;
            flex-grow: 2;
            flex-direction: column;

            text-align: center;
            
            padding: 35vh var(--pad);
        
            &::after{
                content: "";
                height: 20vh;
                display: flex;
            }
        }

        .bar{
            height: 100vh;
            width: var(--bar-w);

            position: fixed;
            position: -webkit-sticky;
            top: 0;
            bottom:0;
            align-self: flex-start;

            display: flex;
            flex-grow: 1;
            flex-direction: column;

            justify-content: space-evenly;
            z-index: 2;

            &.left{
                margin-right: 1rem;
                margin-left: 1rem;
                align-items:start;
                & *{
                    cursor: default;
                    pointer-events: all;
                }
            }

            &.right{
                margin-left: 1rem;
                margin-right: 1rem;
                left: calc(100vw - var(--bar-w) - 2rem);
                align-items:end;
                -webkit-align-items: flex-end;
                & *{
                    cursor: default;
                    pointer-events: all;
                }
            }

            & a, & p {
                
            }
        }

        .perlin_cont_bg{
            display: block;
            position: absolute;
            height: 100%;
            // width: calc(100% + 2*var(--pad));
            width: 0;

            transform: translateX(calc(-1*var(--pad)));

            & div{
                position: sticky;
                position: -webkit-sticky;
                top: 0;
            }

            &.abs{
                position: inherit !important; 
                z-index: 1; 
                transform: none; 
                width: 100%;
            }
        }

        a{
            color:inherit;
            &:visited{
                color:inherit !important;
            }
        }

        .perlin_grid{
            display: grid;
        }

        .perlin_grid_el{
            grid-area: 1/1;
            position: relative;
        }

        .perlin{
            z-index: 2;
            position: relative;
        }

        @font-face {
            font-family: CanelaMed1;
            src: url("./resources/fonts/Canela-Text-Medium.otf");
        }

        @font-face {
            font-family: "CanelaMed";
            src: url("//db.onlinewebfonts.com/t/e0ad94902a160013fafd2403e5e638cb.eot");
            src: url("//db.onlinewebfonts.com/t/e0ad94902a160013fafd2403e5e638cb.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/e0ad94902a160013fafd2403e5e638cb.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/e0ad94902a160013fafd2403e5e638cb.woff") format("woff"), url("//db.onlinewebfonts.com/t/e0ad94902a160013fafd2403e5e638cb.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/e0ad94902a160013fafd2403e5e638cb.svg#Canela") format("svg");
        } 

        @font-face {
            font-family: CanelaReg;
            src: url("./resources/fonts/Canela-Regular.ttf");
        }

        @font-face {
            font-family: MuktaReg;
            src: url(./resources/fonts/Mukta-Regular.woff);
        }

        @font-face {
            font-family: AvenirDemi;
            src: url(./resources/fonts/AvenirNextLTPro-Demi.otf);
        }

        @font-face {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 300;
            src: local('Open Sans Light'), local('OpenSans-Light'), url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTXhCUOGz7vYGh680lGh-uXM.woff) format('woff');
        }
            @font-face {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            src: local('Open Sans'), local('OpenSans'), url(https://fonts.gstatic.com/s/opensans/v13/cJZKeOuBrn4kERxqtaUH3T8E0i7KZn-EPnyo3HZu7kw.woff) format('woff');
        }
            @font-face {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSnhCUOGz7vYGh680lGh-uXM.woff) format('woff');
        }

        @media only screen and (max-width: 800px) {
            :root{
                --pad: 4rem;
                --bar-w: 50vw !important;
                --sc : .5;
            }

            img.parsed{
                margin-left: calc(-1*var(--pad) - 1rem + 4px);
                width: 102vw;
            }

            .bar{
                top: 0vh !important;
            }

            div.parsed.tab{
                margin-left: 3rem;
            }
        }

        // @media only screen and only screen and (max-device-height: 700px){
        //     :root{
        //         --sc : .5;
        //     }
        // }
    </style>
</svelte:head>

<Router {routes}/>